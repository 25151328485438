import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import clientQueries from "./GraphQLRequest/clientQueries";
import clientsMutations from "./GraphQLRequest/clientMutation";
import { methods } from "../utils";

const { GET } = methods;


export default {
  clientsWithOrder: (values) => authenticatedQuery(clientQueries.clientsWithOrder, {}, values),
  clients: (values) => authenticatedQuery(clientQueries.clients, {}, values),
  client: (id) => authenticatedQuery(clientQueries.client, {}, { id }),
  duplicateAndArchive: (data) => 
    authenticatedMutation(clientsMutations.duplicateAndArchive(), {}, data),
  updateClient: (data) =>
    authenticatedMutation(clientsMutations.updateClient(), {}, data),
    updateManyClient: (data) =>
      authenticatedMutation(clientsMutations.updateManyClient(), {}, data),
  insertClient: (data) =>
    authenticatedMutation(clientsMutations.createClient(), {}, data),
  deleteClient: (ids) =>
    authenticatedMutation(clientsMutations.deleteClient(), {}, {ids}),
};
