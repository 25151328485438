import "./dataTable.css";

import { Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export const ACTIONS = {
  PUBLISH: "PUBLISH",
  HIDE: "HIDE",
  DELETE: "DELETE",
  RESTORE: "RESTORE",
};

export default ({
  items,
  reset,
  loading,
  actionClick,
  itemClick,
  columns,
  actionsRender,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
    filterHeight: 100
  });
  const [key, setKey] = useState(0);
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
    current: 1,
  });

  const data = items || [];

  if (data.length !== key) {
    setKey(data.length);
    setSelectedRowKeys([]);
  }

  useEffect(() => {
    setPagination({ ...pagination, current: 1 });
  }, [reset]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    fixed: true,
    onChange: onSelectChange,
  };

  const handleAction = (actionType) => {
    if (actionClick) {
      actionClick(actionType, selectedRowKeys);
      setSelectedRowKeys([]);
    }
  };

  const handleOnRowClick = (rest, index) => {
    if (itemClick) itemClick(rest);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const sizeColumns = useMemo(() => {
    return columns?.reduce((acc, current) => acc + (current?.width || 0), 0)
  }, [columns])

  const handleResize = () => {
    const filterHeight = document.getElementById('filters-div')?.clientHeight;
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
      filterHeight
    });
  };

  useEffect(() => {
    const filterHeight = document.getElementById('filters-div')?.clientHeight;
    setDimensions((prev) => ({...prev, filterHeight}))
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {selectedRowKeys.length > 0 && actionsRender()}
      <Table
        size={"small"}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={columns}
        scroll={{x: sizeColumns, y: dimensions.height - (dimensions.filterHeight + 205)}}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        loading={loading}
        pagination={pagination}
        onRow={(rest, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              handleOnRowClick(rest, rowIndex);
            },
          };
        }}
        onChange={(page) => {
          setPagination({ ...pagination, current: page.current });
        }}
      />
    </div>
  );
};
