import LostPackagins from "./LostPackagings";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { LostPacksActions } from "../../../redux/lostPacks";

const mapStateToProps = ({ locale, lostPacks }) => ({
  list: lostPacks.lostPacks || [],
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  lostPacksRequest: (query) => dispatch(LostPacksActions.lostPacksRequest(query)),
  lostPacksDeleteRequest: (ids) => dispatch(LostPacksActions.lostPacksDeleteRequest(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostPackagins);
