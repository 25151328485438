import { gql } from "@apollo/client";

export default {
  countries: () => gql`
    query getCountries {
      countries {
        id
        country_code
        country_name
      }
    }
  `,
};
