import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import partnersQueries from "./GraphQLRequest/partnersQueries";
import partnersMutations from "./GraphQLRequest/partnersMutations";
import { methods } from "../utils";

const { POST, GET } = methods;
const routes = {
  upload: window.env.REACT_APP_STORAGE_URL + "/codex",
  uploadImage: window.env.REACT_APP_STORAGE_URL + "/codex",
  getSignedUrl: window.env.REACT_APP_STORAGE_URL + "/codex",
  renameImage: window.env.REACT_APP_STORAGE_URL + "/codex/rename",
};
export default {
  partners: () => authenticatedQuery(partnersQueries.partners()),
  partner: (id) => authenticatedQuery(partnersQueries.partner(), {}, { id }),
  types: () => authenticatedQuery(partnersQueries.types()),
  createPartner: (data) =>
    authenticatedMutation(partnersMutations.createPartner(), {}, data),
  updatePartner: (data) =>
    authenticatedMutation(partnersMutations.updatePartner(), {}, data),
  deletePartner: (ids) =>
    authenticatedMutation(partnersMutations.deletePartner(), {}, { ids }),
  createImage: (file) => authenticatedService(POST, routes.upload, file),
  createImageOvh: (file) =>
    authenticatedService(POST, routes.uploadImage, file),
  renameImageOvh: (newName) =>
    authenticatedService(POST, routes.renameImage, newName),
  getSignedUrl: (location) =>
    authenticatedService(GET, routes.getSignedUrl, {}, { location }),
};
