import Partners from "./Partners";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { PartnersActions } from "../../../redux/partners";
import { CountriesActions } from "../../../redux/countries";

const mapStateToProps = ({ locale, partners }) => ({
  list: partners.partners || [],
  countries: partners.countries,
  cities: partners.cities,
  types: partners.types,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  partnersRequest: () => dispatch(PartnersActions.partnersRequest()),
  partnersDeleteRequest: (ids) =>
    dispatch(PartnersActions.partnersDeleteRequest(ids)),
  typesRequest: () => dispatch(PartnersActions.typesRequest()),
  countriesRequest: () => dispatch(CountriesActions.countriesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
