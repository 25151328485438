import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton } from "../../components/button";
import { Divider, Typography } from "antd";
import ModifyButton from "../../components/button/ModifyButton";
import { DataTable } from "../../components";

import { sortColumn, types } from "../../../utils";
import "./lostPackagings.css";

import "antd/lib/style/css";
import "antd/lib/input/style/css";
import ListPageFilters from "../../components/listPageFilters";
import { t } from "assets";

const { Text } = Typography;

export default ({
  lostPacksRequest,
  lostPacksDeleteRequest,
  navigateTo,
  list,
}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ code: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
      sorter: (a, b) => sortColumn(a.id, b.id, types.INT),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Code",
      dataIndex: "code",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.code, b.code, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Famille",
      dataIndex: "family",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.family, b.family, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.type, b.type, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Commentaire",
      dataIndex: "comment",
      align: "center",
      width: 400,
      sorter: (a, b) => sortColumn(a.comment, b.comment, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (item) => {
        return (
          <span>
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (lostPacksRequest) lostPacksRequest({code: ""});
  }, []);

  useEffect(() => {
    setValueList(list);
  }, [list]);

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const onCreate = () => {
    navigateTo("/lostpacks/add");
  };

  const onModify = (center) => {
    navigateTo(`/lostpacks/${center.id}`);
  };

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      lostPacksDeleteRequest(user);
    } else {
      lostPacksDeleteRequest([user.id]);
    }
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const onFilterValueChange = (value) => {
    setFilters({code: value})
  }

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t(
            "partners_count"
          )}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <AuthLayout current={"5"} bread={"Emballage Perdus:/lostpacks"}>
      <ListPageFilters
          filterList={list}
          onFilter={onFilter}
          onFilterValueChange={onFilterValueChange}
          filterKeys={["code"]}
          filterPlaceholder={t("search")}
          onCreateClick={onCreate}
          buttonId="add_lostpack"
          createButtonPlaceholder={t("add_lost_packs")}
      />
      <DataTable
        items={valueList}
        reset={reset}
        itemClick={onModify}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
