import React from "react";
import { Switch } from "antd";
import "./style.css";

export default ({ label, value, onChange, disabled, styleLabel = {} }) => {
  const handleChange = (v) => {
    onChange(v);
  };

  return (
    <div className={"flex-center-start"}>
      <span style={{ alignSelf: "start", ...styleLabel }}>{label}</span>
      <Switch
        disabled={disabled}
        checked={value}
        onChange={handleChange}
        size="small"
        style={{
          marginLeft: "5px",
        }}
      />
    </div>
  );
};
