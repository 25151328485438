import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Router, store } from "./core";
import { GlobalLoader } from "./ui/components";
import "./assets/style.css";

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <Router />
      <GlobalLoader />
    </Fragment>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
