import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { RemittersService } from "../../services";
import { RemittersActions } from ".";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { push } from "connected-react-router";
import { omit } from "lodash";

function* remittersRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(RemittersService.remitters);
  if (error) showError("remitters.errors.remitters_failed");
  else if (response) yield put(RemittersActions.remittersSuccess(response));
  yield put(LoaderActions.loaded());
}

function* remitterRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(RemittersService.remitter, id);
  if (error) showError("remitters.errors.insert");
  else if (response) yield put(RemittersActions.remitterSuccess(response));
  yield put(LoaderActions.loaded());
}

function* remitterUpdateRequest({ data }) {
  yield put(LoaderActions.loading());
  data.id_country = data.country.id;
  // MAJ contacts + update remitter
  const keys = [
    {
      key: "accounts",
      id: "id_accounts",
    },
    {
      key: "sales",
      id: "id_sales",
    },
    {
      key: "management",
      id: "id_management",
    },
  ];
  for (const item of keys) {
    // Si on avait déjà un contact existant => MAJ
    if (data[item.id]) {
      const contact = {
        id: data[item.id],
        email: data[item.key].email,
        name: data[item.key].name,
        phone_number: data[item.key].phone_number,
      };
      const [error, response] = yield call(
        RemittersService.remitterContactUpdate,
        contact
      );
    } else {
      // Sinon besoin d'INSERT ? => Alors on insert
      const needInsert = Object.keys(data[item.key]).some(
        (objectKey) => data[item.key][objectKey] !== undefined
      );
      if (needInsert) {
        //On insert un nouveau contact et on met à jour le remitter
        const contact = { data: data[item.key] };
        const [error, response] = yield call(
          RemittersService.remitterContactCreate,
          contact
        );
        if (response) {
          data[item.id] = response.insert_contacts_one.id;
        }
      }
    }
  }

  const [error, response] = yield call(RemittersService.remitterUpdate, data);
  if (error) showError("remitters.errors.update");
  else if (response) {
    showSuccess("remitters.success.update");
    yield put(push("/remitters"));
  }
  yield put(LoaderActions.loaded());
}

function* remitterContactUpdateRequest({ data }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(
    RemittersService.remitterContactUpdate,
    data
  );
  if (error) showError("remitters.errors.update");
  else if (response) yield put(push("/remitters"));
  yield put(LoaderActions.loaded());
}

function* remitterCreateContactRequest({ data }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(
    RemittersService.remitterContactCreate,
    data
  );
  if (error) showError("remitters.errors.create");
  yield put(LoaderActions.loaded());
}

function* remitterCreateRequest({ data }) {
  yield put(LoaderActions.loading());

  const remitterPayload = omit(data, [
    "country",
    "management",
    "sales",
    "accounts",
  ]);
  remitterPayload.id_country = data.country.id;
  ["management", "sales", "accounts"].forEach((str) => {
    const needInsert = Object.keys(data[str]).some(
      (key) => data[str][key] !== undefined
    );
    if (needInsert) {
      remitterPayload[str] = { data: data[str] };
    }
  });
  const roleCodes = ["ADMIN", "USER", "USERWRITE"];
  const [dbRolesError, dbRolesResponse] = yield call(
    RemittersService.getRoles,
    { codes: roleCodes }
  );
  if (dbRolesError) {
    showError("remitters.errors.linkToRole");
  } else {
    const { user_roles } = dbRolesResponse;
    const roles = user_roles.map((el) => ({
      id_role: el.id,
    }));
    remitterPayload.role_sealogis_remitters = { data: roles };
    const [error, response] = yield call(RemittersService.remitterCreate, {
      remitterPayload,
    });
    if (error) showError("remitters.errors.create");
    else if (response) {
      showSuccess("remitters.success.insert");
      yield put(push("/remitters"));
    }
  }
  yield put(LoaderActions.loaded());
}

function* remitterDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(RemittersService.remitterDelete, ids);
  if (error) {
    if (error[0].extensions.code === "constraint-violation")
      showError("remitters.errors.deleteContraintFailed", "", 10);
    else showError("remitters.errors.delete");
  } else if (response) {
    showSuccess("remitters.success.delete");
    yield put(RemittersActions.remitterDeleteSuccess(ids));
  }
  yield put(LoaderActions.loaded());
}

function* remittersWithRoles() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(RemittersService.remittersWithRoles);
  if (error) {
    showError("client_failed");
  } else if (response) {
    yield put(
      RemittersActions.remittersWithRoleSuccess(
        response.sealogis_remitters,
        response.bcu
      )
    );
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.REMITTERS_REQUEST, remittersRequest),
  takeLatest(types.REMITTER_REQUEST, remitterRequest),
  takeLatest(types.REMITTERS_UPDATE_REQUEST, remitterUpdateRequest),
  takeEvery(
    types.REMITTERS_CONTACT_UPDATE_REQUEST,
    remitterContactUpdateRequest
  ),
  takeEvery(types.REMITTERS_CREATE_REQUEST, remitterCreateRequest),
  takeLatest(types.REMITTERS_DELETE_REQUEST, remitterDeleteRequest),
  takeLatest(
    types.REMITTERS_CONTACT_CREATE_REQUEST,
    remitterCreateContactRequest
  ),
  takeLatest(types.REMITTERS_WITH_ROLES_REQUEST, remittersWithRoles),
];
