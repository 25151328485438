import Clients from "./Clients";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ClientsActions } from "../../../redux/clients";
import { CountriesActions } from "../../../redux/countries";

const mapStateToProps = ({ locale, clients }) => ({
  list: clients.clients || [],
  remitters: clients.remitters || []
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  clientsRequest: () => dispatch(ClientsActions.clientsRequest()),
  countriesRequest: () => dispatch(CountriesActions.countriesRequest()),
  clientsDeleteRequest: (ids) => dispatch(ClientsActions.clientDeleteRequest(ids)),
  clientImportXlsRequest: (clients)=> dispatch(ClientsActions.clientImportXlsRequest(clients))
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
