import ClientCreate from "./ClientCreate";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ClientsActions } from "../../../redux/clients";
import { resolveId } from "../../../utils";
import { CountriesActions } from "../../../redux/countries";
import { ArticlesActions } from "../../../redux/articles";

const mapStateToProps = ({ router, locale, clients }) => ({
  id: resolveId(router),
  client: clients.current,
  remitters: clients.remitters || []
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  clientRequest: (id) => dispatch(ClientsActions.clientRequest(id)),
  clientArticleUpsertRequest: (data, options) =>
    dispatch(ArticlesActions.clientArticleCreateRequest(data, options)),
  clientArticleDeleteRequest: (ids) =>
    dispatch(ArticlesActions.clientArticleDeleteRequest(ids)),
  clientCreateRequest: (data) =>
    dispatch(ClientsActions.clientCreateRequest(data)),
  clientsUpdateRequest: (data) =>
    dispatch(ClientsActions.clientUpdateRequest(data)),
  cleanCurrent: () => dispatch(ClientsActions.cleanCurrent()),
  loadCountries: () => dispatch(CountriesActions.countriesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCreate);
