import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import React from "react";

import ConfirmButton from "./ConfirmButton";

const DesactivateButton = ({
  title,
  loading,
  shape,
  desactivate,
  onCancel,
  disabled,
  ghost,
  tooltipTitle,
  desactivated,
}) => {
  return (
    <ConfirmButton
      id="desactivate-button"
      disabled={disabled}
      confirm
      loading={loading}
      shape={"circle"}
      icon={desactivated ? <EyeFilled /> : <EyeInvisibleFilled />}
      onClick={desactivate}
      onCancel={onCancel}
      ghost={ghost}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default DesactivateButton;
