import { gql } from "@apollo/client";

export default {
  historyFlux: gql`
    query getHistoryFluxs($name: String!, $client: String!) {
      flux(
        where: { client: { _eq: $client }, name: { _eq: $name } }
        order_by: { id: desc }
      ) {
        name
        client
        created_at
        id
        is_final
        steps_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  fluxs: () => gql`
    query getFluxs($where: flux_bool_exp) {
      sealogis_remitters {
        code
        name
        id
      }

      flux(where: $where) {
        id
        name
        client_code
        free_notes
        created_at
        flux_steps_aggregate {
          aggregate {
            count
          }
        }
        original_flux_id
      }
    }
  `,
  clients: () => gql`
    query getClient {
      sealogis_remitters {
        code
        name
        id
      }
    }
  `,
  testFlux: () => gql`
    query getFlux($id: Int) {
      sealogis_remitters {
        code
        name
      }

      flux(where: { id: { _eq: $id } }) {
        id
        name
        client_code
        free_notes
        original_flux_id
        created_at
        sealogis_remitter {
          code
          name
        }
        created_at
        flux_steps(order_by: { position: asc }) {
          id
          required
          position
          flux_step_partners(order_by: { order: asc }) {
            delay
            open_days
            required
            partner {
              id
              name
              partner_type {
                name
                code
              }
            }
          }
        }
      }
    }
  `,
  flux: () => gql``,
  getLastFluxForArchived: () => gql`
    query getFluxForArchived($ids: [Int!]!) {
      flux(
        where: { original_flux_id: { _in: $ids }, archived: { _eq: false } }
      ) {
        id
        client_code
        name
        sealogis_remitter {
          id
          name
        }
        original_flux_id
      }
    }
  `,
};
