import React, { useEffect, useMemo, useState } from "react";
import { Badge, Form, Input, InputNumber, Space, Table } from "antd";
import "antd/lib/table/style/css";
import "./style.css";
import { sortColumn, types } from "../../../utils";
import { BasicComponents, BUTTON_TYPES } from "../../components/basic";
import {
  validatorEAN13,
  validatorInteger,
} from "../../../utils/validator-helper";
import FilesModal from "./modals/FilesModal";
import { allLostPackCodes } from "../../../redux/lostPacks/selector";
import moment from "moment";
import {
  allConversionUnits,
  allPackagingType,
  allPaletisation,
} from "../../../redux/articles/selector";
import { ArchivedModal } from "./modals/ArchivedModal";
import { t } from "assets";

const VALIDATORS = {
  INTEGER: ({ key }) => ({
    validator: validatorInteger,
    message: `${t(key)}: ${t("form.errors.integer_only")}`,
  }),
  LENGTH: ({ key }) => ({
    validator: validatorEAN13,
    message: `${t(key)}: ${t("form.errors.length_ean")}`,
  }),
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  validators,
  required,
  selector,
  schema,
  id,
  ...restProps
}) => {
  let inputNode;
  let customChildren = null;
  switch (inputType) {
    case "number":
      inputNode = <InputNumber type={"number"} controls={false} />;
      if (editing) {
        restProps.style = {
          ...restProps.style,
          padding: 0,
          textAlign: "center",
        };
      }
      break;
    case "boolean":
      if (![undefined, null].includes(record[dataIndex])) {
        customChildren = record[dataIndex] ? t("yes") : t("no");
      }
      inputNode = <BasicComponents.Checkbox />;
      break;
    case "date":
      if (record[dataIndex]) {
        customChildren = record[dataIndex].format("DD/MM/YYYY");
      }
      inputNode = <BasicComponents.DatePicker />;
      break;
    case "select":
      inputNode = (
        <BasicComponents.Select id={id} selector={selector} schema={schema} />
      );
      if (editing) {
        restProps.style = {
          ...restProps.style,
          padding: 0,
          textAlign: "center",
        };
      }
      break;
    default:
      inputNode = <Input />;
      if (editing) {
        restProps.style = {
          ...restProps.style,
          padding: 0,
          textAlign: "center",
        };
      }
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: required,
              message: `${title}: ${t("required_field")}`,
            },
            () => ({
              validator: (rule, value) => {
                if (validators) {
                  const errors = validators
                    .filter(({ validator }) => validator(value))
                    .map(({ message }) => message);
                  if (errors.length) return Promise.reject(errors.join(", "));
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        customChildren || children
      )}
    </td>
  );
};

export default ({ articles = [], onCreate, onUpdate, onDelete }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState(null);
  const [archived, setArchived] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
    filterHeight: 100,
  });
  let data = articles.concat([{ id: -1 }]);

  const handleResize = () => {
    const filterHeight = document.getElementById("filters-div")?.clientHeight;
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
      filterHeight,
    });
  };

  useEffect(() => {
    const filterHeight = document.getElementById("filters-div")?.clientHeight;
    setDimensions((prev) => ({ ...prev, filterHeight }));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    form.resetFields();
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const index = data.findIndex((item) => key === item.id && key !== -1);
      const callbacks = {
        onSuccess: () => {
          cancel();
        },
      };
      if (index > -1) {
        onUpdate({ ...row, id: key }, callbacks);
      } else {
        onCreate(row, callbacks);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (key) => {
    onDelete(key);
  };

  const columns = [
    {
      title: t("remitter_article.internal_code"),
      dataIndex: "internal_code",
      width: 100,
      editable: true,
      required: true,
      sorter: (a, b) =>
        sortColumn(a.internal_code, b.internal_code, types.STRING),
    },
    {
      title: t("remitter_article.sealogis_created_at"),
      dataIndex: "sealogis_created_at",
      width: 150,
      inputType: "date",
      editable: true,
      required: true,
      sorter: (a, b) =>
        sortColumn(a.sealogis_created_at, b.sealogis_created_at),
    },
    {
      title: t("remitter_article.code"),
      dataIndex: "code",
      width: 100,
      required: true,
      editable: true,
      sorter: (a, b) => sortColumn(a.code, b.code),
    },
    {
      title: t("remitter_article.label"),
      dataIndex: "label",
      width: 100,
      editable: true,
      required: true,
      sorter: (a, b) => sortColumn(a.label, b.label, types.STRING),
    },
    {
      title: t("remitter_article.ean13"),
      validators: [
        VALIDATORS.INTEGER({ key: "remitter_article.ean13" }),
        /*VALIDATORS.LENGTH({ key: "remitter_article.ean13", t }),*/
      ],
      inputType: "number",
      width: 100,
      dataIndex: "ean13",
      editable: true,
      sorter: (a, b) => sortColumn(a.ean13, b.ean13, types.INT),
    },
    {
      title: t("remitter_article.itf14"),
      validators: [VALIDATORS.INTEGER({ key: "remitter_article.itf14" })],
      inputType: "number",
      width: 100,
      dataIndex: "itf14",
      editable: true,
      sorter: (a, b) => sortColumn(a.itf14, b.itf14, types.INT),
    },
    {
      title: t("manufacturer_reference"),
      dataIndex: "manufacturer_reference",
      width: 110,
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.manufacturer_label"),
      dataIndex: "manufacturer_label",
      width: 110,
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.manufacturer_label, b.manufacturer_label, types.STRING),
    },
    {
      title: t("remitter_article.range"),
      dataIndex: "range_label",
      width: 100,
      editable: true,
      selector: allLostPackCodes,
      id: "remitter_article_range_select",
    },
    {
      title: t("remitter_article.collection"),
      dataIndex: "collection",
      width: 120,
      editable: true,
      selector: allLostPackCodes,
      id: "remitter_article_collection_select",
    },
    {
      title: t("remitter_article.main_supplier"),
      dataIndex: "main_supplier",
      width: 120,
      editable: true,
      id: "remitter_article_main_supplier_select",
    },
    {
      title: t("remitter_article.uvc_cylinder_diameter"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_cylinder_diameter",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.uvc_cylinder_diameter, b.uvc_cylinder_diameter, types.INT),
    },
    {
      title: t("remitter_article.uvc_length"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_length",
      editable: true,
      sorter: (a, b) => sortColumn(a.uvc_length, b.uvc_length, types.INT),
    },
    {
      title: t("remitter_article.uvc_width"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_width",
      editable: true,
      sorter: (a, b) => sortColumn(a.uvc_width, b.uvc_width, types.INT),
    },
    {
      title: t("remitter_article.uvc_height"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_height",
      editable: true,
      sorter: (a, b) => sortColumn(a.uvc_height, b.uvc_height, types.INT),
    },
    {
      title: t("remitter_article.uvc_m3"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_m3",
      editable: true,
      sorter: (a, b) => sortColumn(a.uvc_m3, b.uvc_m3, types.INT),
    },
    {
      title: t("remitter_article.uvc_weight_net"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_weight_net",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.uvc_weight_net, b.uvc_weight_net, types.INT),
    },
    {
      title: t("remitter_article.uvc_weight_raw"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_weight_raw",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.uvc_weight_raw, b.uvc_weight_raw, types.INT),
    },
    {
      title: t("remitter_article.uvc_packaging_weight_raw"),
      inputType: "number",
      width: 110,
      dataIndex: "uvc_packaging_weight_raw",
      editable: true,
      sorter: (a, b) =>
        sortColumn(
          a.uvc_packaging_weight_raw,
          b.uvc_packaging_weight_raw,
          types.INT
        ),
    },
    {
      title: t("remitter_article.pcb"),
      inputType: "number",
      width: 100,
      dataIndex: "pcb",
      required: true,
      editable: true,
      sorter: (a, b) => sortColumn(a.pcb, b.pcb, types.INT),
    },
    {
      title: t("remitter_article.packaging"),
      dataIndex: "packaging_type_type",
      editable: true,
      inputType: "select",
      width: 110,
      selector: allPackagingType,
      selectSchema: { value: "type", name: "type" },
      id: "remitter_article_packaging_select",
    },
    {
      title: t("remitter_article.barrel_diameter"),
      inputType: "number",
      width: 100,
      dataIndex: "barrel_diameter",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.barrel_diameter, b.barrel_diameter, types.INT),
    },
    {
      title: t("remitter_article.master_carton_length"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_length",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_length, b.master_carton_length, types.INT),
    },
    {
      title: t("remitter_article.master_carton_width"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_width",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_width, b.master_carton_width, types.INT),
    },
    {
      title: t("remitter_article.master_carton_height"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_height",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_height, b.master_carton_height, types.INT),
    },
    {
      title: t("remitter_article.master_carton_m3"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_m3",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_m3, b.master_carton_m3, types.INT),
    },
    {
      title: t("remitter_article.master_carton_weight_net"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_weight_net",
      editable: true,
      sorter: (a, b) =>
        sortColumn(
          a.master_carton_weight_net,
          b.master_carton_weight_net,
          types.INT
        ),
    },
    {
      title: t("remitter_article.master_carton_weight_raw"),
      inputType: "number",
      width: 100,
      dataIndex: "master_carton_weight_raw",
      editable: true,
      sorter: (a, b) =>
        sortColumn(
          a.master_carton_weight_raw,
          b.master_carton_weight_raw,
          types.INT
        ),
    },
    /*{
      title: t("remitter_article.master_carton_weight"),
      inputType: "number",
      dataIndex: "master_carton_weight",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_weight, b.master_carton_weight, types.INT),
    },
    {
      title: t("remitter_article.master_carton_type"),
      dataIndex: "master_carton_type",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.master_carton_type, b.master_carton_type, types.INT),
    },
    {
      title: t("remitter_article.total_no_palet_height"),
      inputType: "number",
      dataIndex: "total_no_palet_height",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.total_no_palet_height, b.total_no_palet_height, types.INT),
    },*/
    {
      title: t("remitter_article.master_carton_packaging_weight_raw"),
      inputType: "number",
      width: 110,
      dataIndex: "master_carton_packaging_weight_raw",
      editable: true,
      sorter: (a, b) =>
        sortColumn(
          a.master_carton_packaging_weight_raw,
          b.master_carton_packaging_weight_raw,
          types.INT
        ),
    },
    {
      title: t("remitter_article.desired_paletisation"),
      inputType: "number",
      width: 120,
      dataIndex: "desired_paletisation",
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.uvc_by_palet"),
      inputType: "number",
      width: 100,
      dataIndex: "uvc_by_palet",
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.nb_cartons_by_palet"),
      inputType: "number",
      width: 100,
      dataIndex: "nb_cartons_by_palet",
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.nb_package_by_layer"),
      inputType: "number",
      width: 100,
      dataIndex: "nb_package_by_layer",
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.nb_layer_by_palet"),
      inputType: "number",
      width: 100,
      dataIndex: "nb_layer_by_palet",
      editable: true,
      sorter: () => {},
    },
    {
      title: t("remitter_article.layer_height"),
      inputType: "number",
      width: 100,
      dataIndex: "layer_height",
      editable: true,
      sorter: (a, b) => sortColumn(a.layer_height, b.layer_height, types.INT),
    },
    {
      title: t("remitter_article.total_no_palet_height"),
      inputType: "number",
      width: 100,
      dataIndex: "total_no_palet_height",
      editable: true,
      sorter: (a, b) =>
        sortColumn(a.total_no_palet_height, b.total_no_palet_height, types.INT),
    },
    {
      title: t("remitter_article.lost_pack"),
      dataIndex: "lost_packaging_code",
      editable: true,
      inputType: "select",
      width: 110,
      selector: allLostPackCodes,
      selectSchema: { value: "code", name: "code" },
      id: "remitter_article_lost_pack_select",
    },
    {
      title: t("remitter_article.status_pallet"),
      dataIndex: "paletisation_label",
      editable: true,
      inputType: "select",
      width: 120,
      selector: allPaletisation,
      selectSchema: { value: "label", name: "label" },
      id: "remitter_article_paletisation_select",
    },
    {
      id: "remitter_article_stackability_checkbox",
      title: t("remitter_article.stackability"),
      inputType: "boolean",
      width: 120,
      dataIndex: "stackability",
      editable: true,
      required: false,
    },
    {
      id: "remitter_article_is_pictogram_checkbox",
      title: t("remitter_article.is_pictogram"),
      inputType: "boolean",
      width: 130,
      dataIndex: "is_pictogram",
      editable: true,
      required: false,
    },
    {
      title: t("remitter_article.conversion_unit"),
      dataIndex: "conversion_unit_label",
      editable: true,
      inputType: "select",
      width: 110,
      selector: allConversionUnits,
      selectSchema: { value: "label", name: "label" },
      id: "remitter_article_conversion_units_select",
    },
    {
      title: t("remitter_article.conversion_handling_unit_um"),
      inputType: "number",
      width: 120,
      dataIndex: "conversion_handling_unit_um",
      editable: true,
    },
    {
      title: t("remitter_article.temperature"),
      inputType: "number",
      width: 100,
      dataIndex: "temperature",
      editable: true,
    },
    {
      title: t("remitter_article.adr"),
      inputType: "number",
      width: 100,
      dataIndex: "adr",
      editable: true,
    },
    {
      title: t("remitter_article.storage_class"),
      inputType: "number",
      width: 100,
      dataIndex: "storage_class",
      editable: true,
    },
    {
      title: t("remitter_article.component"),
      inputType: "number",
      width: 100,
      dataIndex: "component",
      editable: true,
    },
    {
      title: t("remitter_article.nomenclature"),
      dataIndex: "nomenclature",
      editable: true,
      width: 130,
      sorter: (a, b) =>
        sortColumn(a.nomenclature, b.nomenclature, types.STRING),
    },
    {
      title: t("remitter_article.compound_nomencal_code"),
      dataIndex: "compound_nomencal_code",
      editable: true,
      width: 150,
      sorter: (a, b) =>
        sortColumn(
          a.compound_nomencal_code,
          b.compound_nomencal_code,
          types.STRING
        ),
    },
    {
      title: t("remitter_article.date_maj_pcb_cdt"),
      dataIndex: "date_maj_pcb_cdt",
      width: 200,
      inputType: "date",
      editable: true,
      required: true,
      sorter: (a, b) => sortColumn(a.date_maj_pcb_cdt, b.date_maj_pcb_cdt),
    },
    {
      title: "Actions",
      fixed: "right",
      width: 180,
      align: "center",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable)
          return (
            <Space>
              <BasicComponents.Button
                id={`cancel-button-${record.id}`}
                buttonType={BUTTON_TYPES.CANCEL}
                shouldConfirm
                danger
                onClick={cancel}
              />
              <BasicComponents.Button
                id={`save-button-${record.id}`}
                tooltipTitle={t("submit")}
                buttonType={BUTTON_TYPES.SAVE}
                onClick={() => save(record.id)}
              />
            </Space>
          );
        else if (record.id == -1)
          return (
            <BasicComponents.Button
              id={`modify-button-${record.id}`}
              disabled={editingKey !== ""}
              tooltipTitle={editingKey !== "" ? t("can_not_create") : ""}
              buttonType={BUTTON_TYPES.MODIFY}
              onClick={() => edit(record)}
            />
          );
        else
          return (
            <Space>
              <BasicComponents.Button
                id={`archived-button-${record.id}`}
                disabled={record?.archived?.length === 0}
                tooltipTitle={
                  record?.archived?.length === 0
                    ? t("remitter_article.no_archive")
                    : ""
                }
                buttonType={BUTTON_TYPES.FOLDER}
                onClick={() => setArchived(record.id)}
              />
              <Badge
                count={record?.files?.length}
                offset={[-2, 1]}
                size={"small"}
              >
                <BasicComponents.Button
                  id={`edit-button-${record.id}`}
                  buttonType={BUTTON_TYPES.DOCUMENT}
                  onClick={() => setFiles(record.id)}
                />
              </Badge>
              <BasicComponents.Button
                id={`edit-button-${record.id}`}
                tooltipTitle={editingKey !== "" ? t("can_not_edit") : ""}
                disabled={editingKey !== ""}
                buttonType={BUTTON_TYPES.MODIFY}
                onClick={() => edit(record)}
              />
              <BasicComponents.Button
                id={`delete-button-${record.id}`}
                buttonType={BUTTON_TYPES.DELETE}
                shouldConfirm
                danger
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType ? col.inputType : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: col.required !== undefined ? col.required : false,
        selector: col.selector,
        schema: col.selectSchema,
        id: col.id,
        validators: col.validators || [],
      }),
    };
  });

  const selected = useMemo(
    () => articles?.find((a) => a.id === files),
    [articles, files]
  );

  const selectedArchived = useMemo(
    () => articles?.find((a) => a.id === archived),
    [archived]
  );

  return (
    <Form
      form={form}
      component={false}
      initialValues={{ sealogis_created_at: moment() }}
    >
      <FilesModal
        id={selected?.id}
        files={selected?.files}
        onClose={() => setFiles(null)}
      />
      <ArchivedModal
        article={selectedArchived}
        onClose={() => setArchived(null)}
      />
      <Table
        components={{
          body: {
            cell: (props) => <EditableCell {...props} />,
          },
        }}
        sticky
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{
          x: columns?.reduce((acc, column) => acc + column.width, 0),
          y: dimensions.height - (dimensions.filterHeight + 280),
        }}
        style={{ marginBottom: "30px" }}
      />
    </Form>
  );
};
