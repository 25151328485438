import { Input } from "antd";
import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";

const { Search } = Input;

const Filter = ({ list, onFilter, disabled, keys, placeHolder, width = 350 }) => {
  const options = {
    shouldSort: true,
    tokenize: true,
    includeMatches: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: keys,
  };

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (list && value) onSearch(value);
  }, [list]);

  const newList = (list || []).map((l, i) => {
    let ret = { ...l, i };
    keys.forEach((item) => {
      if (typeof ret[item] === "string") {
        ret[item] = ret[item]
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      }
    });
    return ret;
  });

  let fuse = new Fuse(newList, options);

  const onSearch = (searchValue) => {
    const newValue = searchValue
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (!searchValue || searchValue === "") {
      onFilter(list);
      return;
    }
    setValue(searchValue);
    let result = fuse.search(newValue);
    onFilter(result.map(({ item }) => list[item.i]));
    return searchValue;
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) onSearch(value);
    setValue(value);
  };

  return (
      <Search
        id={"search"}
        style={{width: width}}
        placeholder={placeHolder}
        value={value}
        onChange={handleSearchChange}
        allowClear={true}
        onSearch={onSearch}
        enterButton
        disabled={disabled}
      />
  );
};

export default Filter;
