export const ROUTES = {
    LOGIN: "/",
    CLIENTS: "/clients",
    ADD_CLIENT: "/clients/add",
    ONE_CLIENT: "/clients/:id",
    PARTNERS: "/partners",
    ADD_PARTNER: "/partners/add",
    ONE_PARTNER: "/partners/:id",
    FLUX: "/flux",
    ADD_FLUX: "/flux/add",
    ONE_FLUX: "/flux/:id",
    USERS: "/users",
    ADD_USER: "/users/add",
    ONE_USER: "/users/:id",
    ADMIN: "/admin",
    REMITTERS: "/remitters",
    ADD_REMITTER: "/remitters/add",
    ONE_REMITTER: "/remitters/:id",
    REMITTER_ARTICLES: "/remitters/:id/articles",
    LOST_PACKS: "/lostpacks",
    ADD_LOST_PACK: "/lostpacks/add",
    ONE_LOST_PACK: "/lostpacks/:id",

}