import { gql } from "@apollo/client";

export default {
  clients: () => gql`
    query getClient {
      flux {
        client
      }
    }
  `,
  flux: () => gql`
    query getFlux($id: Int) {
      flux(where: { id: { _eq: $id } }) {
        name
        client
        free_notes
        created_at
        id
        is_final
        steps(order_by: { order: asc }) {
          delay
          open_days
          required
          partner {
            id
            name
            partner_type {
              name
            }
            city
          }
        }
      }
    }
  `,
};
