import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { ClientsService } from "../../services";
import { ClientsActions } from ".";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { push } from "connected-react-router";
import { omit } from "lodash";

function* clientsRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(ClientsService.clients, {name: "%%"});
  if (error) showError("clients.errors.clients_failed");
  else if (response) yield put(ClientsActions.clientsSuccess(response));
  yield put(LoaderActions.loaded());
}

function* clientRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(ClientsService.client, id);
  if (error) showError("clients.errors.insert");
  else if (response) yield put(ClientsActions.clientSuccess(response));
  yield put(LoaderActions.loaded());
}

function* clientUpdateRequest({ data }) {
  yield put(LoaderActions.loading());

  let toArchive = !(data.client_orders == null || data.client_orders.length == 0)
  let isAddrNull = Object.values(data.address).every((el) => el === undefined);

  let clientPayload = {
    code: data.code,
    party_function_code_qualifier: data.party_function_code_qualifier,
    phone_number: data.phone_number,
    name: data.name,
    remitter_code: data.remitter_code,
    email: data.email,
    mobile_phone_number: data.mobile_phone_number,
    fax: data.fax,
    contact: data.contact,
    director_note: data.director_note,
    instructions: data.instructions,
    tradbrand: data.tradbrand,
    iata_code: data.iata_code,
    pudo_code: data.pudo_code,
    region: data.region,
    km: data.km,
    num_tax_add: data.num_tax_add,
    grouping_cod: data.grouping_cod,
    contract_num: data.contract_num,
    alert: data.alert,
    tva_num: data.tva_num,
    client_dst_type_code: data.client_dst_type_code,
    tour_code: data.tour_code,
    latitude: data.latitude,
    longitude: data.longitude,
    langue: data.langue,
    delivery_mode: data.delivery_mode,
    Comments: data.Comments,
    eori_num: data.eori_num
  };

  if (isAddrNull === false) {
    clientPayload.address = {
      data: {
        id: data.address_id ?? undefined,
        city: data.address.city ?? null,
        country_code: data.address.country ?? null,
        addr1: data.address.addr1 ?? null,
        addr2: data.address.addr2 ?? null,
        addr3: data.address.addr3 ?? null,
        zip_code: data.address.zip_code ?? null,
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
    Object.keys(clientPayload.address).forEach((key) => {
      if (clientPayload.address[key] == null) {
        delete clientPayload.address[key];
      }
    });
  }

  if (!toArchive) {
    clientPayload.id = data.id
  }

  const [error, response] = toArchive ?
  yield call(ClientsService.duplicateAndArchive, {clientIDtoArchive: data.id, clientPayload: clientPayload})
  :
  yield call(ClientsService.updateClient, {id: data.id, clientPayload: clientPayload});

  if (error) showError("clients.errors.update");
  else if (response) {
    showSuccess("clients.success.update");
    yield put(push("/clients"));
  }
  yield put(LoaderActions.loaded());
}


function* clientCreateRequest({ data }) {
  yield put(LoaderActions.loading());

  let isAddrNull = Object.values(data.address).every(
    (el) => el === undefined
  );

  let clientPayload = {
    code: data.code,
    party_function_code_qualifier: data.party_function_code_qualifier,
    phone_number: data.phone_number,
    name: data.name,
    remitter_code: data.remitter_code,
    email: data.email,
    mobile_phone_number: data.mobile_phone_number,
    fax: data.fax,
    contact: data.contact,
    director_note: data.director_note,
    instructions: data.instructions,
    tradbrand: data.tradbrand,
    iata_code: data.iata_code,
    pudo_code: data.pudo_code,
    region: data.region,
    km: data.km,
    num_tax_add: data.num_tax_add,
    grouping_cod: data.grouping_cod,
    contract_num: data.contract_num,
    alert: data.alert,
    tva_num: data.tva_num,
    client_dst_type_code: data.client_dst_type_code,
    tour_code: data.tour_code,
    latitude: data.latitude,
    longitude: data.longitude,
    langue: data.langue,
    delivery_mode: data.delivery_mode,
    Comments: data.Comments,
    eori_num: data.eori_num
  };


  if (isAddrNull === false) {
    clientPayload.address = {
      data: {
        city: data.address.city,
        country_code: data.address.country ?? "",
        addr1: data.address.addr1 ?? "",
        addr2: data.address.addr2 ?? "",
        addr3: data.address.addr3 ?? "",
        zip_code: data.address.zip_code ?? "",
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
  }

  const [error, response] = yield call(ClientsService.insertClient, {
    clientPayload,
  });
  if (error) showError("clients.errors.create");
  else if (response) {
    showSuccess("clients.success.insert");
    yield put(push("/clients"));
  }
  yield put(LoaderActions.loaded());
}

function* clientDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(ClientsService.deleteClient, ids);
  if (error) {
    if (error[0].extensions.code === "constraint-violation")
      showError("clients.errors.deleteContraintFailed", "", 10);
    else showError("clients.errors.delete");
  } else if (response) {
    showSuccess("clients.success.delete");
    yield put(ClientsActions.clientDeleteSuccess(ids));
  }
  yield put(LoaderActions.loaded());
}
function* clientImportXLSRequest({ data }) {
  yield put(LoaderActions.loading());

  yield put(LoaderActions.loading());


  // Get order associate with client code
  const codes = data.map(e => e["Code"])
  const [errorToArchive, reponseToArchive] = yield call(ClientsService.clientsWithOrder, {codes: codes});

  let clientsPayload = []
  let idToArchive =  []
  for(var i = 0; i < data.length; i++) {
    let client = reponseToArchive.clients.find(e => e.code == data[i]["Code"])
    let toArchive = !(client == null || client.client_orders == null || client.client_orders.length == 0)
     // !(data.client_orders == null || data.client_orders.length == 0)
    let isAddrNull = data[i]["Adresse 1"] == null

    let clientPayload = {
      code: data[i]["Code"],
      phone_number: data[i]["Téléphone"]?.toString(),
      name: data[i]["Nom"],
      remitter_code: data[i]["Code Remettant"],
      email: data[i]["Email"],
      mobile_phone_number: data[i]["Téléphone 2"]?.toString(),
      contact: data[i]["Contact"],
      director_note: data[i]["MotDirecteur"],
      instructions: data[i]["Instructions"],
      tradbrand: data[i]["Marque commerciale"],
      iata_code: data[i]["Code IATA"],
      pudo_code: data[i]["Code pudo"],
      region: data[i]["Région"],
      km: data[i]["Kilomètres"]?.toString(),
      num_tax_add: data[i]["N° taxe additionnelle"]?.toString(),
      grouping_cod: data[i]["Code regroupement"],
      contract_num: data[i]["Numéro de contrat"],
      alert: data[i]["Alerte"],
      tva_num: data[i]["Numéro de TVA"],
      client_dst_type_code: data[i]["Type destinataire"],
      tour_code: data[i]["Code tournée"],
      latitude: data[i]["Latitude"],
      longitude: data[i]["Longitude"],
      langue: data[i]["langue"],
      delivery_mode: data[i]["Mode de livraison"],
      Comments: data[i]["Commentaires"],
      eori_num: data[i]["Numéro EORI"]
    };

    if (isAddrNull === false) {
      clientPayload.address = {
        data: {
          id: data[i].address_id ?? undefined,
          city: data[i]["Ville"],
          country_code: data[i]["Pays"] ?? null,
          addr1: data[i]["Adresse 1"] ?? null,
          addr2: data[i]["Adresse 2"] ?? null,
          addr3: data[i]["Adresse 3"] ?? null,
          zip_code: data[i]["Code postal"]?.toString() ?? null,
        },
        on_conflict: {
          constraint: "addresses_pkey",
          update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
        },
      };
      Object.keys(clientPayload.address).forEach((key) => {
        if (clientPayload.address[key] == null) {
          delete clientPayload.address[key];
        }
      });
    }

    if (!toArchive) {
      if (client != null) {
        clientPayload.id = client.id
      }
      clientsPayload.push(clientPayload)
    } else {
      idToArchive.push(client.id)
    }
  }

  const [error, response] = yield call(ClientsService.updateManyClient, {clientsPayload: clientsPayload, clientIDtoArchive: idToArchive});

  if (error) showError("clients.errors.update");
  else if (response) {
    showSuccess("clients.success.update");
    yield put(push("/clients"));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.CLIENTS_REQUEST, clientsRequest),
  takeLatest(types.CLIENT_REQUEST, clientRequest),
  takeLatest(types.CLIENTS_UPDATE_REQUEST, clientUpdateRequest),
  takeEvery(types.CLIENTS_CREATE_REQUEST, clientCreateRequest),
  takeLatest(types.CLIENTS_DELETE_REQUEST, clientDeleteRequest),
  takeLatest(types.CLIENTS_IMPORT_XLS_REQUEST, clientImportXLSRequest),
];
