import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { AuthenticationService, PartnersService } from "../../services";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { PartnersActions } from "./index";
import { push } from "connected-react-router";
import { CommonActions } from "../common";

function* partnersRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(PartnersService.partners);
  if (error) showError("partners_failed");
  else if (response) yield put(PartnersActions.partnersSuccess(response));
  yield put(LoaderActions.loaded());
}

function* partnerRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(PartnersService.partner, id);
  if (error) showError("partner_failed");
  else if (response) {
    const partnerResponse = Object.assign({}, response);
    if (response.partners[0].image) {
      const [error, response] = yield call(
        PartnersService.getSignedUrl,
        partnerResponse.partners[0].image
      );
      if (response) {
        partnerResponse.partners[0].image = response.data;
      }
    }
    yield put(PartnersActions.partnerSuccess(partnerResponse));
  }

  yield put(LoaderActions.loaded());
}

function* partnerCreateRequest({ values }) {
  yield put(LoaderActions.loading());
  const partners = yield select((state) => state.partners);
  const img = yield select((state) => state.partners.imgUrl);

  let isAddrNull = Object.values(values.address).every(
    (el) => el === undefined
  );
  let isContactNull = Object.values(values.contact).every(
    (el) => el === undefined
  );
  let isInvoiceAddrNull = Object.values(values.invoice_address).every(
    (el) => el === undefined
  );
  let isContactTwoNull = Object.values(values.contact_two).every(
    (el) => el === undefined
  );

  // TODO : Revoir insertion de l'image

  let payload = {
    type_code: values.type_code,
    sealogis_code: values.sealogis_code,
    edi_code: values.edi_code,
    name: values.name,
    teaches: values.teaches,
    siren: values.siren,
    invoice_siren: values.invoice_siren,
    specificity1: values.specificity1,
    specificity2: values.specificity2,
    specificity3: values.specificity3,
    specificity4: values.specificity4,
    specificity5: values.specificity5,
    day_open_site: values.day_open_site,
    day_open_office: values.day_open_office,
    hourly_open_site: values.hourly_open_site,
    hourly_open_office: values.hourly_open_office,
    company_invoiced: values.company_invoiced,
    image: img,
  };

  if (isAddrNull === false) {
    payload.addressRef = {
      data: {
        city: values.address.city,
        country_code: values.address.country_code ?? "",
        addr1: values.address.addr1 ?? "",
        addr2: values.address.addr2 ?? "",
        addr3: values.address.addr3 ?? "",
        zip_code: values.address.zip_code ?? "",
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
  }

  if (isContactNull === false) {
    payload.contactRef = {
      data: {
        name: values.contact.name,
        email: values.contact.email,
        tel: values.contact.tel,
        phone_number: values.contact.phone_number,
      },
      on_conflict: {
        constraint: "contacts_pkey",
        update_columns: ["tel", "function", "email", "phone_number", "name"],
      },
    };
  }

  if (isContactTwoNull === false) {
    payload.contactTwoRef = {
      data: {
        name: values.contact_two.name,
        email: values.contact_two.email,
        tel: values.contact_two.tel,
        phone_number: values.contact_two.phone_number,
      },
      on_conflict: {
        constraint: "contacts_pkey",
        update_columns: ["tel", "function", "email", "phone_number", "name"],
      },
    };
  }

  if (isInvoiceAddrNull === false) {
    payload.invoiceAddressRef = {
      data: {
        city: values.invoice_address.city,
        country_code: values.invoice_address.country_code,
        addr1: values.invoice_address.addr1,
        addr2: values.invoice_address.addr2,
        addr3: values.invoice_address.addr3,
        zip_code: values.invoice_address.zip_code,
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
  }

  const [error, response] = yield call(PartnersService.createPartner, payload);
  if (error) showError("partner_create_failed");
  else if (response) {
    if (img) {
      const array = img.split(".");
      const oldName = img;
      const extension = `.${array[array.length - 1]}`;
      const id = response.insert_partners_one.id;
      const newName = `logo_partner_${id}${extension}`;
      const payload = {
        image: newName,
      };
      yield all([
        call(PartnersService.renameImageOvh, { newName, oldName }),
        call(PartnersService.updatePartner, {
          id,
          ...payload,
        }),
      ]);
    }
    showSuccess("partners_create_success");
    yield put(push("/partners"));
  }
  yield put(LoaderActions.loaded());
}

function* partnerUpdateRequest({ data }) {
  yield put(LoaderActions.loading());
  const { id, ...partnerPayload } = data;

  const img = yield select((state) => state.partners.imgUrl);

  let isAddrNull = Object.values(data.address).every((el) => el === undefined);
  let isContactNull = Object.values(data.contact).every(
    (el) => el === undefined
  );
  let isInvoiceAddrNull = Object.values(data.invoice_address).every(
    (el) => el === undefined
  );
  let isContactTwoNull = Object.values(data.contact_two).every(
    (el) => el === undefined
  );

  let payload = {
    type_code: data.type_code,
    sealogis_code: data.sealogis_code,
    edi_code: data.edi_code,
    name: data.name,
    teaches: data.teaches,
    siren: data.siren,
    invoice_siren: data.invoice_siren,
    specificity1: data.specificity1,
    specificity2: data.specificity2,
    specificity3: data.specificity3,
    specificity4: data.specificity4,
    specificity5: data.specificity5,
    day_open_site: data.day_open_site,
    day_open_office: data.day_open_office,
    hourly_open_site: data.hourly_open_site,
    hourly_open_office: data.hourly_open_office,
    company_invoiced: data.company_invoiced,
    image: img,
  };

  if (isAddrNull === false) {
    payload.addressRef = {
      data: {
        id: data.address_id ?? undefined,
        city: data.address.city ?? null,
        country_code: data.address.country_code ?? null,
        addr1: data.address.addr1 ?? null,
        addr2: data.address.addr2 ?? null,
        addr3: data.address.addr3 ?? null,
        zip_code: data.address.zip_code ?? null,
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
    Object.keys(payload.addressRef).forEach((key) => {
      if (payload.addressRef[key] == null) {
        delete payload.addressRef[key];
      }
    });
  }

  if (isContactNull == false) {
    payload.contactRef = {
      data: {
        id: data.contact_id ?? undefined,
        name: data.contact.name,
        email: data.contact.email,
        tel: data.contact.tel,
        phone_number: data.contact.phone_number,
      },
      on_conflict: {
        constraint: "contacts_pkey",
        update_columns: ["tel", "function", "email", "phone_number", "name"],
      },
    };
    Object.keys(payload.contactRef).forEach((key) => {
      if (payload.contactRef[key] == null) {
        delete payload.contactRef[key];
      }
    });
  }

  if (isContactTwoNull == false) {
    payload.contactTwoRef = {
      data: {
        id: data.contact_two_id ?? undefined,
        name: data.contact_two.name,
        email: data.contact_two.email,
        tel: data.contact_two.tel,
        phone_number: data.contact_two.phone_number,
      },
      on_conflict: {
        constraint: "contacts_pkey",
        update_columns: ["tel", "function", "email", "phone_number", "name"],
      },
    };
    Object.keys(payload.contactTwoRef).forEach((key) => {
      if (payload.contactTwoRef[key] == null) {
        delete payload.contactTwoRef[key];
      }
    });
  }

  if (isInvoiceAddrNull == false) {
    payload.invoiceAddressRef = {
      data: {
        id: data.invoice_address_id ?? undefined,
        city: data.invoice_address.city,
        country_code: data.invoice_address.country_code,
        addr1: data.invoice_address.addr1,
        addr2: data.invoice_address.addr2,
        addr3: data.invoice_address.addr3,
        zip_code: data.invoice_address.zip_code,
      },
      on_conflict: {
        constraint: "addresses_pkey",
        update_columns: ["addr1", "addr2", "addr3", "zip_code", "country_code"],
      },
    };
    Object.keys(payload.invoiceAddressRef).forEach((key) => {
      if (payload.invoiceAddressRef[key] == null) {
        delete payload.invoiceAddressRef[key];
      }
    });
  }

  const [error, response] = yield call(PartnersService.updatePartner, {
    id,
    ...payload,
  });
  if (error) showError("partner_update_failed");
  else if (response) {
    showSuccess("partners_update_success");
    yield put(push("/partners"));
  }
  yield put(LoaderActions.loaded());
}

function* partnerDeleteRequest({ partnerIds }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(
    PartnersService.deletePartner,
    partnerIds
  );
  if (error) showError("partner_delete_failed");
  else if (response) {
    showSuccess("partners_delete_success");
    yield put(
      PartnersActions.partnersDeleteSuccess(response.delete_partners.returning)
    );
    yield put(push("/partners"));
  }
  yield put(LoaderActions.loaded());
}

function* typesRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(PartnersService.types);
  if (error) showError("types_failed");
  else if (response)
    yield put(PartnersActions.typesSuccess(response.partner_type));
  yield put(LoaderActions.loaded());
}

function* imageCreate({ file, fileName }) {
  const form = new FormData();
  form.append("file", file);
  form.append("fileName", fileName);

  const [error, response] = yield call(PartnersService.createImage, form);
  if (error) showError("image_failed");
  else if (response) {
    const filename = response.data.location.split("/").pop();
    if (fileName.includes("cgu")) {
      //yield put(CommonActions.setCGUReduc(filename));
      const [error, response2] = yield call(AuthenticationService.addCGU, { cgu: filename });
      if(!error && response2) {
        console.log(response)
        yield put(PartnersActions.imageOvhSuccess(response.data))
        showSuccess("add_cgu_success");
      }

    } else if (fileName.includes("privacy_policy")) {
      //yield put(CommonActions.setPrivacyPolicyReduc(filename));
      const [error, response2] = yield call(AuthenticationService.addPrivacyPolicy, {
        privacy_policy: filename,
      });
      if(!error && response2) {
        console.log(response2)
        yield put(PartnersActions.imageOvhSuccess(response.data))
        showSuccess("add_privacy_policy_success");
      }
    } else yield put(PartnersActions.imageSuccess(filename));
  }
}

function* imageCreateOnOvh({ file, fileName }) {
  const form = new FormData();
  const partnerId = yield select((state) => state.partners.current?.id);
  const array = file.name.split(".");
  const extension = `.${array[array.length - 1]}`;
  form.append("file", file);
  form.append(
    "fileName",
    `logo_partner_${partnerId ? partnerId : "temporary"}${extension}`
  );

  const [error, response] = yield call(PartnersService.createImageOvh, form);
  if (error) showError("image_failed");
  else if (response) {
    const filename = response.data;
    yield put(PartnersActions.imageOvhSuccess(filename));
  }
}

export default [
  takeLatest(types.PARTNERS_REQUEST, partnersRequest),
  takeLatest(types.PARTNER_REQUEST, partnerRequest),
  takeLatest(types.PARTNERS_CREATE_REQUEST, partnerCreateRequest),
  takeLatest(types.PARTNERS_UPDATE_REQUEST, partnerUpdateRequest),
  takeLatest(types.PARTNER_DELETE, partnerDeleteRequest),
  takeLatest(types.TYPES_REQUEST, typesRequest),
  takeLatest(types.IMAGE_CREATE, imageCreate),
  takeLatest(types.IMAGE_CREATE_OVH, imageCreateOnOvh),
];
