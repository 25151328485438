import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default ({ array, value, onChange, placeholder, codeKey, valueKey, id }) => {

  return (
    <Select
      allowClear
      value={value && typeof value === "object" ? value[valueKey] : value}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={onChange}
      placeholder={placeholder}
      showSearch
      filterOption={(input, option) =>
        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
      }
      id={id}
    >
      {array.map((item, index) => (
        <Option value={codeKey ? item[codeKey] : item.code} key={`${codeKey ? item[codeKey] : item.code}-${index}`}>
          {valueKey ? item[valueKey]: item.name}
        </Option>
      ))}
    </Select>
  );
};
