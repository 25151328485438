import { authenticatedMutation, authenticatedQuery } from "./middleware";
import lostPacksQueries from "./GraphQLRequest/lostPacksQueries";
import lostPacksMutations from "./GraphQLRequest/lostPacksMutations";
import { methods } from "../utils";

const { POST, GET } = methods;
const routes = {
  upload: window.env.REACT_APP_STORAGE_URL + "/upload",
  uploadImage: window.env.REACT_APP_STORAGE_URL + "/codex",
  getSignedUrl: window.env.REACT_APP_STORAGE_URL + "/codex",
  renameImage: window.env.REACT_APP_STORAGE_URL + "/codex/rename",
};
export default {
  lostPacks: (query) =>
    authenticatedQuery(lostPacksQueries.lostPacks(), {}, { code: query.code }),
  lostPackById: (id) =>
    authenticatedQuery(lostPacksQueries.lostPackById(), {}, { id }),
  upsertLostPacks: (data) =>
    authenticatedMutation(
      lostPacksMutations.createLostPacks(),
      {},
      { object: data }
    ),
  deleteLostPacks: (ids) =>
    authenticatedMutation(lostPacksMutations.deleteLostPacks(), {}, { ids }),
  families: () => authenticatedQuery(lostPacksQueries.families()),
};
