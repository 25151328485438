import { gql } from "@apollo/client";

const moment = require("moment");

export default {
  createUser: () => gql`
    mutation MyMutation($user: users_insert_input!) {
      insert_users_one(object: $user) {
        id
      }
    }
  `,
  createUserFlux: () => gql`
    mutation MyMutation($idFlux: Int!, $idUser: Int!) {
      insert_user_fluxs(objects: { id_flux: $idFlux, id_user: $idUser }) {
        returning {
          id_flux
        }
      }
    }
  `,
  updateUser: () => gql`
    mutation MyMutation($id: Int!, $userPayload: users_set_input) {
      update_users(where: { id: { _eq: $id } }, _set: $userPayload) {
        returning {
          id
        }
      }
    }
  `,
  insertUserFlux: () => gql`
    mutation InsertUserFlux($toInsertFlux: [user_fluxs_insert_input!]!) {
      insert_user_fluxs(objects: $toInsertFlux) {
        returning {
          id_flux
        }
      }
    }
  `,
  deleteUserFlux: () => gql`
    mutation DeleteUserFlux($toDeleteFlux: [Int!]!) {
      delete_user_fluxs(where: { id: { _in: $toDeleteFlux } }) {
        returning {
          id_user
        }
      }
    }
  `,
  deleteUser: () => gql`
    mutation UsersDelete($ids: [Int!]!) {
      delete_user_fluxs(where: { id_user: { _in: $ids } }) {
        returning {
          id
        }
      }
      delete_role_sealogis_remitters_users(where: { id_user: { _in: $ids } }) {
        returning {
          id
        }
      }
      delete_users(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
  updateUserDetail: () => gql`
    mutation MyMutation($id: Int!, $userPayload: users_set_input!) {
      update_users(where: { id: { _eq: $id } }, _set: $userPayload) {
        returning {
          id
        }
      }
    }
  `,
  changeExpirationToken: () => gql`
  mutation MyMutation(
    $id: Int!
  ) {
    update_codes(where: {user_id: { _eq: $id }}, _set: {expiration: "${moment()
      .subtract(2, "hours")
      .format("YYYY/MM/DD HH:mm:ss")}"}) {
      returning {
        user_id
      }
    }
    update_access_tokens(where: {user_id: { _eq: $id }}, _set: {expiration: "${moment()
      .subtract(2, "hours")
      .format("YYYY/MM/DD HH:mm:ss")}", refresh_token_expiration: "${moment()
    .subtract(2, "hours")
    .format("YYYY/MM/DD HH:mm:ss")}"}, ) {
      returning {
        user_id
      }
    }
  }
  `,
  deleteUserToken: () => gql`
    mutation MyMutation($id: Int!) {
      delete_codes(where: { user_id: { _eq: $id } }) {
        returning {
          user_id
        }
      }
    }
  `,

  fullUpdateUser: gql`
    mutation fullUpdateUser(
      $id: Int!
      $userPayload: users_set_input
      $toUpdateRoles: [role_sealogis_remitters_users_updates!]!
      $toInsertRoles: [role_sealogis_remitters_users_insert_input!]!
      $toDeleteRoles: role_sealogis_remitters_users_bool_exp!
      $toInsertFlux: [user_fluxs_insert_input!]!
      $toDeleteFlux: [Int!]!
    ) {
      update_role_sealogis_remitters_users_many(updates: $toUpdateRoles) {
        returning {
          id
        }
      }

      insert_role_sealogis_remitters_users(objects: $toInsertRoles) {
        returning {
          id
        }
      }

      delete_role_sealogis_remitters_users(where: $toDeleteRoles) {
        returning {
          id
        }
      }

      insert_user_fluxs(objects: $toInsertFlux) {
        returning {
          id_flux
        }
      }

      delete_user_fluxs(where: { id: { _in: $toDeleteFlux } }) {
        returning {
          id_user
        }
      }

      update_users(where: { id: { _eq: $id } }, _set: $userPayload) {
        returning {
          id
        }
      }
    }
  `,
};
