import { gql } from "@apollo/client";

export default {
    client: gql`
    query getClient($id: Int!){
      sealogis_remitters {
        id
        code
        name
      }

      clients(where: { id: { _eq: $id } }) {
        remitter_code
        party_function_code_qualifier
        id
        code
        name
        email
        mobile_phone_number
        phone_number
        fax
        contact
        director_note
        instructions
        tradbrand
        iata_code
        pudo_code
        region
        km
        num_tax_add
        grouping_cod
        contract_num
        alert
        tva_num
        client_dst_type_code
        tour_code
        latitude
        longitude
        langue
        delivery_mode
        Comments
        eori_num
        sealogis_remitter {
          code
          name
        }
        client_orders {
            id
            client_code
        }
        address {
          id
          addr1
          addr2
          addr3
          zip_code
          country {
            country_code
            country_name
          }
          city
        }
      }
    }
    `,
  clients: gql`
  query getClients($name: String!){
    sealogis_remitters {
      id
      code
      name
    }

    clients(where: { name: {_ilike: $name}, archived: {_eq: false}}, order_by: {id: desc}) {
      id
      code
      name
      contact
      email
      mobile_phone_number
      phone_number
      sealogis_remitter {
        code
        name
      }
      address {
        id
        addr1
        addr2
        addr3
        zip_code
        country {
          country_code
          country_name
        }
        city
      }
    }
  }
  `,
  clientsWithOrder: gql`
  query ($codes: [String!]!){
      clients(where: {code: {_in: $codes}}) {
        code
        id
        client_orders {
          id
          client_id
          client_code
        }
      }
    }
  `
};


