import { Switch } from "./switch/Switch";
import { Button, BUTTON_TYPES } from "./buttons";
import { Typography } from "./typography/Typography";
import { Checkbox } from "./checkbox/Checkbox";
import { DatePicker } from "./datePicker/DatePicker";
import { Select } from "./select/Select";

export const BasicComponents = {
  Switch,
  Button,
  Typography,
  Checkbox,
  DatePicker,
  Select,
};

export { BUTTON_TYPES };
