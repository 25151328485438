import React from "react";
import { Droppable } from "react-beautiful-dnd";

export default ({ children, id, className, name, type = "droppableItem" }) => (
  <div className={className} key={`div-${id}`}>
    <div className={`header-container ${name ? "" : "hide"}`}>
      <h2 className={"column_header"}>{name}</h2>
    </div>
    <div className="droppable-container">
      <Droppable droppableId={id} key={`droppable-${id}`} type={type}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={"draggable_container"}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  </div>
);
