import React from "react";
import { Col, Form, Row } from "antd";
import { requiredRules } from "../../../utils/form-helper";

import "antd/lib/style/css";
import "antd/lib/layout/style/css";
import "antd/lib/form/style/css";
import { t } from "assets";

export default ({ row, checkFluxAvailable, values, components }) => {
  return (
    <Row justify={"space-between"}>
      {row.map((subItem) => (
        <Col span={22 / row.length}>
          <div id={`${subItem.key}-form`}>
            <Form.Item
              validateTrigger={subItem.validateTrigger || "onChange"}
              noStyle={subItem.noStyle}
              name={subItem.key}
              label={t(subItem.key)}
              rules={[
                requiredRules(subItem),
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      subItem.validator &&
                      !subItem.validator(value, getFieldValue)
                    )
                      return Promise.reject(
                        subItem.error || `${t(subItem.key)} incorrect`
                      );
                    if (
                      subItem?.errorCheck &&
                      value &&
                      value !== values?.name &&
                      value !== values?.client &&
                      !checkFluxAvailable
                    )
                      return Promise.reject(subItem.errorCheck);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {components[subItem.component || "input"]({
                key: subItem.key,
                args: subItem.args,
              })}
            </Form.Item>
          </div>
        </Col>
      ))}
    </Row>
  );
};
