import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { ArticlesService, PartnersService } from "../../services";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { ArticlesActions } from "./index";
import { v4 as uuid } from "uuid";

function* remitterArticlesRequest({ remitterCode }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    ArticlesService.remitterArticles,
    remitterCode
  );
  if (error) showError("remitters.errors.remitters_articles_failed");
  else if (response) {
    yield put(ArticlesActions.remitterArticleSuccess(response));
  }
  yield put(LoaderActions.loaded());
}

function* remitterArticleDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);

  const [error, response] = yield call(
    ArticlesService.remitterArticleDelete,
    ids
  );
  if (error) {
    showError("remitters.errors.delete_article");
  } else if (response) {
    showSuccess("remitters.success.delete_article");
    yield put(ArticlesActions.remitterArticleDeleteSuccess(ids));
  }
  yield put(LoaderActions.loaded());
}

function* archivedArticlesRequest({ article }) {
  const [error, response] = yield call(
    ArticlesService.getArchivedArticles,
    article.archived.map((archive) => archive.id)
  );
  if (error) {
    showError("remitters.errors.fetch_archived_articles");
  } else if (response) {
    yield put(
      ArticlesActions.getArchivedArticlesSuccess(article, response.articles_new)
    );
  }
}

function* remitterArticleCreateRequest({ values, options, remitterCode }) {
  yield put(LoaderActions.loading());

  let payload = values;

  /*const [error, response] = yield call(
    ArticlesService.remitterArticleCreate,
    payload
  );*/
  const [error, response] = yield call(
    ArticlesService.remitterArticleUpsertRequest,
    payload
  );
  const { onSuccess, onError } = options;
  if (error) {
    showError(
      values.id
        ? "remitters.errors.update_article"
        : "remitters.errors.create_article"
    );
    if (onError) onError();
  } else if (response) {
    showSuccess(
      values.id
        ? "remitters.success.update_article"
        : "remitters.success.create_article"
    );
    yield call(remitterArticlesRequest, {
      remitterCode,
    });
    if (onSuccess) onSuccess();
  }
  yield put(LoaderActions.loaded());
}

function* createArticleFile({ file, articleId, callback }) {
  const form = new FormData();
  const fileName = file.name;
  const uFileName = fileName.replace(/(\.[\w\d_-]+)$/i, (e) => `${uuid()}${e}`);
  let res = [];
  form.append("file", file);
  form.append("fileName", uFileName);
  const [error, response] = yield call(PartnersService.createImageOvh, form);
  if (!error) {
    res = yield call(ArticlesService.createArticleFile, {
      article_id: articleId,
      u_name: response?.data?.location,
      name: fileName,
    });
  }
  if (error || res[0]) showError("create_article_file_error");
  else if (res[1]) {
    yield put(
      ArticlesActions.createArticleFileSuccess(
        res[1].insert_article_files?.returning[0],
        articleId
      )
    );
    showSuccess("create_article_file_success");
  }
  callback();
}

function* deleteArticleFile({ fileId, articleId, callback }) {
  const [error] = yield call(ArticlesService.deleteArticleFile, fileId);
  if (error) showError("delete_article_error");
  else {
    yield put(ArticlesActions.deleteArticleFileSuccess(fileId, articleId));
    showSuccess("delete_article_success");
  }
  callback();
}

function* getSignedUrl({ name, callback }) {
  const [error, response] = yield call(PartnersService.getSignedUrl, name);
  if (response?.data) window.open(response.data, "_blank", "fullscreen=yes");
  else showError("get_signed_url_error");
  callback();
}

function* getAllRelatedArticleSelects() {
  const ret = yield all([
    call(ArticlesService.palletizingRequest),
    call(ArticlesService.rangeRequest),
    call(ArticlesService.conversionUnitsRequest),
    call(ArticlesService.packagingTypeRequest),
  ]);
  let data = {};
  let hasSuccess = false;
  ret.forEach((r) => {
    const [error, response] = r;
    if (error) {
    } else {
      data = { ...data, ...response };
      hasSuccess = true;
    }
  });
  if (hasSuccess)
    yield put(ArticlesActions.getAllRelatedArticleSelectsSuccess(data));
}

function* uploadExcel({ file, code, callback }) {
  const [remitter] = yield select((state) => [state.remitters.current]);
  const form = new FormData();
  const fileName = file.name;
  form.append("file", file);
  form.append("fileName", fileName);

  const [error, response] = yield call(
    ArticlesService.uploadExcel,
    form,
    remitter.id
  );
  if (error) showError("upload_excel_error");
  else {
    const [errorArticles, responseArticles] = yield call(
      ArticlesService.remitterArticles,
      code
    );
    if (errorArticles) showError("remitters.errors.remitters_articles_failed");
    else if (responseArticles) {
      yield put(ArticlesActions.remitterArticleSuccess(responseArticles));
    }

    const insertErrors = response.data.errors;
    if (insertErrors?.length) {
      yield put(ArticlesActions.setImportReport(insertErrors));
    }
    showSuccess("upload_excel_success");
  }
  callback && callback();
}

export default [
  takeLatest(types.DELETE_ARTICLE_FILE_REQUEST, deleteArticleFile),
  takeLatest(types.CREATE_ARTICLE_FILE_REQUEST, createArticleFile),
  takeLatest(types.GET_SIGNED_URL, getSignedUrl),
  takeEvery(types.REMITTER_ARTICLE_REQUEST, remitterArticlesRequest),
  takeEvery(
    types.REMITTER_ARTICLE_CREATE_REQUEST,
    remitterArticleCreateRequest
  ),
  takeLatest(
    types.REMITTER_ARTICLE_DELETE_REQUEST,
    remitterArticleDeleteRequest
  ),
  takeLatest(types.GET_ARCHIVED_ARTICLES_REQUEST, archivedArticlesRequest),

  takeLatest(
    types.GET_ALL_RELATED_ARTICLE_SELECTS,
    getAllRelatedArticleSelects
  ),
  takeLatest(types.UPLOAD_EXCEL_REQUEST, uploadExcel),
];
