import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  clients: [],
  remitters: [],
  current: null,
};

const ClientsSuccess = (state = initialState, action) => {
  const { list } = action;
  const clients = list.clients;
  const remitters = list.sealogis_remitters
  return { ...state, clients, remitters };
};

const ClientDeleteSuccess = (state = initialState, action) => {
  const { clients } = state;
  const clients_ids = action.ids;
  const tmp = clients?.filter((item) => !clients_ids.includes(item.id));
  return { ...state, clients: tmp };
};

const ClientSuccess = (state = initialState, action) => {
  console.log({action})
  const client = action.client.clients[0];
  const remitters = action.client.sealogis_remitters
  const current = { ...client };
  return { ...state, current, remitters };
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

export default createReducer(initialState, {
  [types.CLIENTS_SUCCESS]: ClientsSuccess,
  [types.CLIENT_SUCCESS]: ClientSuccess,
  [types.CLEAN_CURRENT]: cleanCurrent,
  [types.CLIENTS_DELETE_SUCCESS]: ClientDeleteSuccess,
});
