import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  lostPacks: null,
  current: null,
  families: [],
};

const lostPacksSuccess = (state = initialState, action) => {
  const { list } = action;
  const families = list.lost_packaging_families;
  const lostPacks = list.lost_packagings.map((item) => ({
    ...item,
  }));

  return { ...state, lostPacks, families };
};

const lostPacksDeleteSuccess = (state = initialState, action) => {
  const { lostPacks } = state;
  const ids = action.ids.map((item) => item.id);
  const tmp = lostPacks?.filter((item) => !ids.includes(item.id));
  return { ...state, lostPacks: tmp };
};

const lostPackByIdSuccess = (state = initialState, action) => {
  const families = action.data.lost_packaging_families;
  const current = action.data.lost_packagings[0];
  return { ...state, current, families };
};

const lostPacksFamiliesSuccess = (state = initialState, action) => {
  return { ...state, families: action.families };
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

export default createReducer(initialState, {
  [types.LOST_PACKS_SUCCESS]: lostPacksSuccess,
  [types.LOST_PACKS_BY_ID_SUCCESS]: lostPackByIdSuccess,
  [types.LOST_PACKS_DELETE_SUCCESS]: lostPacksDeleteSuccess,
  [types.LOST_PACKS_FAMILIES_SUCCESS]: lostPacksFamiliesSuccess,
  [types.CLEAN_CURRENT]: cleanCurrent,
});
