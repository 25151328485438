import { connectRouter, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import appReducers, { rootSaga } from "../redux";
import { loadState, saveState } from "../utils/storage";
import { history } from "./history";
import { createLogger } from "redux-logger";

const logger = createLogger({
  // ...options
});

const sagaMiddleware = createSagaMiddleware();

// Retrieves state from local storage
const persistedState = loadState();

// Configure store middleware
const middleware = [
  // Info: Apply sagaMiddleware in first !
  /*applyMiddleware(logger),*/
  applyMiddleware(sagaMiddleware),
  applyMiddleware(routerMiddleware(history)),
].concat(
    window.env.NODE_ENV === "development"
    ? [
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose,
    ]
    : []
);

// Create store
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    ...appReducers,
  }),
  persistedState,
  compose(...middleware)
);

// Each sagas are isolated
// This ensure that the sagas will not be canceled if one failed
// (Be aware that should be a source of performance leak)
rootSaga.forEach((saga) =>
  sagaMiddleware.run(function* () {
    yield saga;
  })
);

// Save state when changes occurred
store.subscribe(() => {
  const { auth, user } = store.getState();
  saveState({ auth, user });
});

export default store;
