import { Draggable } from "react-beautiful-dnd";
import React from "react";
import DndStepCard from "./DndStepCard";

export default ({
  id,
  index,
  item,
  removeFromFlux,
  children,
  onClickExpand,
  onRequiredChange,
}) => {
  return (
    <Draggable
      key={`drag-${id}-${index}`}
      draggableId={`drag-${id}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <DndStepCard
          provided={provided}
          snapshot={snapshot}
          item={item}
          index={index}
          removeFromFlux={removeFromFlux}
          onClickExpand={onClickExpand}
          onRequiredChange={onRequiredChange}
        >
          {children}
        </DndStepCard>
      )}
    </Draggable>
  );
};
