import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";

export const CustomTreeSelect = ({
  options = [],
  multiple,
  onChange = () => {},
  defaultValue = null,
}) => {
  const [value, setValue] = useState([]);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([]);

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let toRemove = [];
    let newValues = [...value];
    value.forEach((el, index) => {
      const [remitterId] = el.split("_");
      const indexOpt = options.findIndex(
        (opt) => opt.id === parseInt(remitterId)
      );
      if (indexOpt === -1) {
        toRemove.push(index);
        newValues.splice(index, 1);
      }
    });
    if (newValues.length !== value.length) {
      setValue(newValues);
      onChange(newValues);
    }
  }, [options.length]);

  const handleOnChange = (newValues, label, extra) => {
    const newValue = newValues.find((el) => !value.includes(el));
    const option = options.find((el) => {
      if (typeof newValue === "number") return el.value === newValue;
      const [remitterId] = newValue.split("_");
      return el.value === parseInt(remitterId);
    });
    let existIndex = -1;
    let wantExpand = typeof newValue === "number";
    if (option.nbChildren === 2 && !wantExpand) {
      const length = newValue ? newValue.split("_").length : 0;
      wantExpand = newValue.includes(option.idBcuExpand) && length < 3;
    }

    if (
      newValues.length >= value.length &&
      newValue &&
      typeof newValue !== "number" &&
      newValue.includes("_") &&
      !wantExpand
    ) {
      const [newValueMainId] = newValue.split("_");
      existIndex = value.findIndex((el) => el.includes(newValueMainId));
    } else if (wantExpand) {
      let toSetExpanded = [...treeExpandedKeys];
      if (!treeExpandedKeys.includes(newValue)) {
        toSetExpanded.push(newValue);
      } else {
        const index = treeExpandedKeys.indexOf(newValue);
        toSetExpanded.splice(index, 1);
      }
      setTreeExpandedKeys(toSetExpanded);
      return;
    }
    let toSetValues = [];
    if (existIndex > -1) {
      toSetValues = [...value];
      toSetValues[existIndex] = newValue;
    } else {
      toSetValues = newValues;
    }
    setValue(toSetValues);
    onChange(toSetValues);
  };

  const onTreeExpand = (keys) => {
    setTreeExpandedKeys(keys);
  };

  return (
    <TreeSelect
      placement={"topLeft"}
      treeData={options}
      multiple={multiple}
      treeLine={true}
      onChange={handleOnChange}
      onTreeExpand={onTreeExpand}
      value={value}
      treeExpandedKeys={treeExpandedKeys}
      treeNodeLabelProp={"titleInSelect"}
    />
  );
};
