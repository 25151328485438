import {resettableReducer} from "reduxsauce";
import {CommonTypes} from "./common";
import {AuthReducers, AuthSagas} from "./auth";
import {LoaderReducers} from "./loader";
import {PartnersReducers, PartnersSagas} from "./partners";
import {RemittersReducers, RemittersSagas} from "./remitters";
import {FluxReducers, FluxSagas} from "./flux";
import {UsersReducers, UsersSagas} from "./users";
import {CountriesReducers, CountriesSagas} from "./countries";
import {LostPacksReducers, LostPacksSagas} from "./lostPacks";
import {ArticlesReducers, ArticlesSagas} from "./articles";
import {ClientsReducers, ClientsSagas} from "./clients";
import {FileReducers, FileSagas} from "./files";

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  partners: resettable(PartnersReducers),
  loader: resettable(LoaderReducers),
  flux: resettable(FluxReducers),
  users: resettable(UsersReducers),
  remitters: resettable(RemittersReducers),
  countries: resettable(CountriesReducers),
  lostPacks: resettable(LostPacksReducers),
  files: resettable(FileReducers),
  articles: resettable(ArticlesReducers),
  clients: resettable(ClientsReducers),
};

export const rootSaga = [
  ...AuthSagas,
  ...PartnersSagas,
  ...FluxSagas,
  ...UsersSagas,
  ...RemittersSagas,
  ...CountriesSagas,
  ...LostPacksSagas,
  ...FileSagas,
  ...ArticlesSagas,
  ...ClientsSagas,
];


export default reducers;
