import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { UsersActions } from "./index";
import { UsersService, FluxService } from "../../services";
import { push } from "connected-react-router";
import { omit } from "lodash";
import { encode } from "base-64";

const handleUserRoles = (current_roles, roles_by_remitters, userId) => {
  let bcuCodeRet = null;
  const formattedExistingRoles = current_roles.map((el) => {
    return {
      remitterId: el.role_sealogis_remitter.id_sealogis_remitter,
      roleId: el.role_sealogis_remitter.id,
    };
  });

  const formattedNewRoles = roles_by_remitters.map((el) => {
    const [remitterId, roleId, bcuCode] = el.split("_");
    if (bcuCode) {
      bcuCodeRet = bcuCode;
    }
    return { remitterId: parseInt(remitterId), roleId: parseInt(roleId) };
  });
  const [toInsertRoles, toUpdateRoles] = formattedNewRoles.reduce(
    (res, current) => {
      const { remitterId, roleId } = current;
      const existing = formattedExistingRoles.find(
        (el) => el.remitterId === remitterId
      );
      if (existing && existing.roleId !== roleId) {
        res[1].push({
          where: {
            id_user: { _eq: userId },
            id_role_sealogis_remitter: { _eq: existing.roleId },
          },
          _set: {
            id_role_sealogis_remitter: roleId,
          },
        });
      } else if (!existing) {
        res[0].push({
          id_user: userId,
          id_role_sealogis_remitter: roleId,
        });
      }
      return res;
    },
    [[], []]
  );
  const toDeleteRolesWheres = formattedExistingRoles
    .filter((role) => {
      const index = formattedNewRoles.findIndex(
        (el) => el.remitterId === role.remitterId
      );
      return index === -1;
    })
    .map((role) => ({
      id_user: { _eq: userId },
      id_role_sealogis_remitter: { _eq: role.roleId },
    }));
  const toDeleteRoles = { _or: toDeleteRolesWheres };
  return { toInsertRoles, toUpdateRoles, toDeleteRoles, bcuCode: bcuCodeRet };
};

function* usersRequest() {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(UsersService.users);
  if (error) showError("users_failed");
  else if (response) yield put(UsersActions.usersSuccess(response.users));
  yield put(LoaderActions.loaded());
}

function* userRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(UsersService.user, id);
  if (error) showError("user_failed");
  else if (response) {
    const user = response.users[0];
    const toGetIds = user.user_fluxs
      .filter((el) => el.flux.archived)
      .map((el) => el.id_flux);
    const [newFluxError, newFluxSuccess] = yield call(
      FluxService.getLastFluxForArchived,
      toGetIds
    );
    if (newFluxError) {
      showError("user_failed");
    } else {
      if (newFluxSuccess.flux?.length) {
        user.added_flux = newFluxSuccess.flux;
      }
      yield put(UsersActions.userSuccess(user));
    }
  }
  yield put(LoaderActions.loaded());
}

function* usersUpdateRequest({ user }) {
  yield put(LoaderActions.loading());
  const [current, fluxState] = yield select((state) => [
    state.users.current,
    state.flux.fluxs,
  ]);
  const {
    id,
    flux,
    fluxPayload,
    roles_by_remitters,
    password,
    ...userPayload
  } = user;
  const renewPwd = password?.length > 0;
  const existingFlux = current.user_fluxs || [];
  const existingFluxIds = existingFlux.map(({ id_flux }) => id_flux);
  const toInsertFlux = flux
    .filter((id_flux) => !existingFluxIds.includes(id_flux))
    .map((id_flux) => {
      const test = fluxState.find(
        (el) => el.id === id_flux && el.original_flux_id > 0
      );

      return {
        id_user: current.id,
        id_flux: test ? test.original_flux_id : id_flux,
      };
    });
  const toDeleteFlux = existingFlux
    .filter(({ id_flux }) => !flux.includes(id_flux))
    .map(({ id }) => id);

  const { toInsertRoles, toUpdateRoles, toDeleteRoles, bcuCode } =
    handleUserRoles(
      current.role_sealogis_remitters_users,
      roles_by_remitters,
      current.id
    );

  if (bcuCode?.length && current.bcu_code !== bcuCode) {
    userPayload.bcu_code = bcuCode;
  } else if (!bcuCode?.length && current.bcu_code?.length) {
    userPayload.bcu_code = null;
  }

  if (renewPwd) {
    yield call(UsersService.updateUserPassword, {
      id,
      pwd: encode(password),
    });
    userPayload.renew_pwd = true;
  }

  const [error, response] = yield call(UsersService.fullUpdateUser, {
    id,
    userPayload,
    toInsertRoles,
    toUpdateRoles,
    toDeleteRoles,
    toInsertFlux,
    toDeleteFlux,
  });
  if (error) showError("user_update_failed");
  else if (response) {
    showSuccess("user_update_success");
    yield put(push("/users"));
  }

  yield put(LoaderActions.loaded());
}

function* userDetailUpdateRequest({ user }) {
  yield put(LoaderActions.loading());
  const userClean = omit(user, ["key", "__typename"]);
  const { id, ...userPayload } = userClean;

  const [error, response] = yield call(UsersService.updateUserDetail, {
    id,
    userPayload,
  });

  let error2;

  if (error) showError("user_update_failed");
  if (!userClean.activated) {
    const [error2, response2] = yield call(UsersService.changeExpirationToken, {
      id,
    });
    if (error2) {
      showError("user_code_update_failed");
      yield put(LoaderActions.loaded());
      return;
    }
  }

  if (response && !error && !error2) {
    showSuccess("user_update_success");
    yield put(UsersActions.userDetailSuccess(userClean));
    yield put(push("/users"));
  }
  yield put(LoaderActions.loaded());
}

function* usersCreateRequest({ user }) {
  yield put(LoaderActions.loading());
  const [fluxState] = yield select((state) => [state.flux.fluxs]);

  // Remaniement de données
  if (!user.activated) {
    user.activated = false;
  }
  user.renew_pwd = true;
  user = omit(user, "clients");
  user.password = encode(user.password);

  if (user.flux?.length) {
    const realFlux = user.flux.map((el) => {
      const find = fluxState.find(
        (toFind) => toFind.id === el && toFind.original_flux_id > 0
      );
      if (find) return find.original_flux_id;
      return el;
    });
    user.flux = realFlux;
  }

  if (user.roles_by_remitters?.length) {
    user.roles_by_remitters.forEach((el) => {
      const split = el.split("_");
      if (split.length === 3) {
        user.bcu_code = split[2];
      }
    });
  }

  const [error, response] = yield call(UsersService.createUser, {
    user,
  });
  if (response) {
    const [error, response] = yield call(
      UsersService.emailCreateAccountRequest,
      {
        firstname: user.first_name,
        lastname: user.last_name,
        email: user.email,
        pwd: user.password,
      }
    );
    showSuccess("user_create_success");
    if (response?.data === 1) {
      showSuccess("user_send_success");
      yield put(push("/users"));
    } else {
      showError("user_send_failed");
    }
  } else if (error) {
    showError("user_create_failed");
    showError("user_send_failed");
  }
  yield put(LoaderActions.loaded());
}

function* userDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(UsersService.deleteUser, ids);
  if (error) showError("user_delete_failed");
  else if (response) {
    showSuccess("user_delete_success");
    yield put(UsersActions.usersDeleteSuccess(response.delete_users.returning));
    yield put(push("/users"));
  }
  yield put(LoaderActions.loaded());
}

function* emailRequest({ email }) {
  yield put(UsersActions.emailLoading(true));
  const [error, response] = yield call(UsersService.emailRequest, email);
  if (response) {
    yield put(UsersActions.emailLoading(false));
    yield put(UsersActions.emailSuccess(response.data));
  }
}

function* resendRequest({ user }) {
  yield put(LoaderActions.loading());
  const id = user.id;

  const [error, response] = yield call(UsersService.resend, id);
  if (response) {
    yield call(UsersService.emailCreateAccountRequest, {
      firstname: user.first_name,
      lastname: user.last_name,
      email: user.email,
      pwd: response.data,
    });
    showSuccess("user_send_success");
  } else if (error) {
    showError("user_send_failed");
  }
  yield put(LoaderActions.loaded());
}

function* roleRequest() {
  const [error, response] = yield call(UsersService.roleRequest);
  if (response) {
    yield put(UsersActions.roleSuccess(response.user_roles));
  } else if (error) {
    showError("user_send_failed");
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.USER_REQUEST, userRequest),
  takeLatest(types.USERS_REQUEST, usersRequest),
  takeLatest(types.USERS_UPDATE_REQUEST, usersUpdateRequest),
  takeLatest(types.USERS_CREATE_REQUEST, usersCreateRequest),
  takeLatest(types.USERS_DELETE_REQUEST, userDeleteRequest),
  takeLatest(types.RESEND_REQUEST, resendRequest),
  takeLatest(types.USER_DETAIL_UPDATE_REQUEST, userDetailUpdateRequest),
  debounce(500, types.EMAIL_REQUEST, emailRequest),
  takeLatest(types.ROLE_REQUEST, roleRequest),
];
