import React, { useState } from "react";
import { Button, Modal, Popconfirm, Space, Table, Upload } from "antd";
import { BasicComponents, BUTTON_TYPES } from "../../../components/basic";
import { ArticlesActions } from "../../../../redux/articles";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { showError } from "../../../../utils/notifications-helper";
import { t } from "assets";

export default ({ files, id, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const [{ loadingId, loadingEvent }, setIsLoading] = useState({});

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("create_date"),
      dataIndex: "created_at",
    },
    {
      title: t("action"),
      fixed: "right",
      width: "100px",
      align: "center",
      render: (record) => (
        <Space>
          <BasicComponents.Button
            id={`download-button-${record.id}`}
            buttonType={BUTTON_TYPES.DOWNLOAD}
            onClick={() => handleFile(record)}
            disabled={
              loadingEvent &&
              !(loadingId === record.id && loadingEvent === "download")
            }
            loading={loadingId === record.id && loadingEvent === "download"}
          />
          <Popconfirm
            title={t("confirm_action")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={() => handleDelete(record.id)}
          >
            <BasicComponents.Button
              id={`delete-button-${record.id}`}
              buttonType={BUTTON_TYPES.DELETE}
              shouldConfirm
              danger
              disabled={
                loadingEvent &&
                !(loadingId === record.id && loadingEvent === "delete")
              }
              loading={loadingId === record.id && loadingEvent === "delete"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const beforeUpload = (file) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > 4) {
      showError("file_size_error");
      return;
    }
    setIsLoading({ loadingEvent: "upload" });
    dispatch(
      ArticlesActions.createArticleFileRequest(file, id, () => setIsLoading({}))
    );
  };

  const handleDelete = (fileId) => {
    setIsLoading({ loadingId: fileId, loadingEvent: "delete" });
    dispatch(
      ArticlesActions.deleteArticleFileRequest(fileId, id, () =>
        setIsLoading({})
      )
    );
  };

  const handleFile = (file) => {
    const { u_name, id } = file || {};
    if (u_name) {
      setIsLoading({ loadingId: id, loadingEvent: "download" });
      dispatch(ArticlesActions.getSignedUrl(u_name, () => setIsLoading({})));
    }
  };

  return (
    <Modal
      open={!!files}
      closable
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Upload
        id={`upload-component`}
        beforeUpload={beforeUpload}
        maxCount={1}
        showUploadList={false}
        customRequest={() => 1}
        onPreview={() => {}}
      >
        <Button
          id={`upload-button`}
          type="primary"
          style={{ margin: "20px 0px 20px 0px", position: "sticky" }}
          icon={<UploadOutlined />}
          disabled={loadingEvent}
          loading={loadingEvent === "upload"}
        >
          {t("upload")}
        </Button>
      </Upload>
      <div style={{ overflowY: "auto", maxHeight: 500 }}>
        <Table
          sticky
          bordered
          scr
          dataSource={files || []}
          columns={columns}
          pagination={false}
        />
      </div>
    </Modal>
  );
};
