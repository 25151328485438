import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  fluxs: null,
  clients: [],
  current: null,
  available: true,
  loading: false,
};
const FluxsSuccess = (state = initialState, action) => {
  const { values } = action;
  const clients = values.sealogis_remitters;

  const fluxs = values.flux.map((item) => {
    let { flux_steps_aggregate, ...rest } = item;
    rest = { ...rest, count: flux_steps_aggregate.aggregate.count };
    //if (clients.indexOf(item.client) === -1) clients.push(item.client);
    return rest;
  });

  return { ...state, fluxs, clients, available: true };
};

const fluxsDeleteSuccess = (state = initialState, action) => {
  const { fluxs } = state;
  const flux_ids = action.fluxIds.map((item) => item.id);
  const tmp = fluxs?.filter((item) => !flux_ids.includes(item.id));
  return { ...state, fluxs: tmp };
};

const addFluxSuccess = (state = initialState, action) => {
  const { fluxs } = state;
  return { ...state, fluxs: [...fluxs, action.values] };
};

const fluxRequestSuccess = (state = initialState, action) => {
  const { flux } = action;
  const { flux_steps, ...rest } = flux.flux[0];
  const current = {
    ...rest,
    steps: flux_steps.map( ({ flux_step_partners, id, ...step }) => {
      return {
        id,
        ...step,
        steps_partners: flux_step_partners.map((v) => ({
          ...v,
          ...v.partner,
          type: v.partner.partner_type.name,
        })),
      };
    }),
  };
  return { ...state, current };
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

const clientsSuccess = (state = initialState, action) => {
  const { values } = action;

  const clients = values;

  return { ...state, clients };
};

const checkFluxSuccess = (state = initialState, action) => {
  const { available } = action;
  return { ...state, available };
};

const checkFluxLoading = (state = initialState, action) => {
  const { loading } = action;
  return { ...state, loading };
};

const resetFluxAvailable = (state = initialState) => {
  return { ...state, available: true };
};

export default createReducer(initialState, {
  [types.FLUXS_SUCCESS]: FluxsSuccess,
  [types.FLUX_DELETE_SUCCESS]: fluxsDeleteSuccess,
  [types.FLUX_SUCCESS]: fluxRequestSuccess,
  [types.CLEAN_CURRENT]: cleanCurrent,
  [types.CLIENT_SUCCESS]: clientsSuccess,
  [types.CHECK_FLUX_SUCCESS]: checkFluxSuccess,
  [types.CHECK_FLUX_LOADING]: checkFluxLoading,
  [types.ADD_FLUX_SUCCESS]: addFluxSuccess,
  [types.RESET_FLUX_AVAILABLE]: resetFluxAvailable
});
