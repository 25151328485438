import { call, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { LostPacksService } from "../../services";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { LostPacksActions } from "./index";
import { push } from "connected-react-router";

function* lostPacksRequest({ query }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(LostPacksService.lostPacks, query);
  if (error) showError("lost_packs_failed");
  else if (response) yield put(LostPacksActions.lostPacksSuccess(response));
  yield put(LoaderActions.loaded());
}

function* lostPacksDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(LostPacksService.deleteLostPacks, ids);
  if (error) showError("lostpacks_delete_failed");
  else if (response) {
    showSuccess("lostpacks_delete_success");
    yield put(
      LostPacksActions.lostPacksDeleteSuccess(
        response.delete_lost_packagings.returning
      )
    );
    yield put(push("/lostpacks"));
  }
  yield put(LoaderActions.loaded());
}

function* lostPacksByIdRequest({ id }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(LostPacksService.lostPackById, id);
  if (error) showError("lost_pack_failed");
  else {
    yield put(LostPacksActions.lostPacksByIdSuccess(response));
  }

  yield put(LoaderActions.loaded());
}

function* lostPacksCreateRequest({ values }) {
  yield put(LoaderActions.loading());
  const partners = yield select((state) => state.partners);
  const img = yield select((state) => state.partners.imgUrl);

  let payload = {
    code: values.code,
    comment: values.comment,
    consigne: values.consigne,
    family: values.family,
    gerbability: values.gerbability,
    height: values.height,
    length: values.length,
    m2: values.m2,
    m3: values.m3,
    m3_container_height: values.m3_container_height,
    m3_container_length: values.m3_container_length,
    m3_container_total: values.m3_container_total,
    m3_container_width: values.m3_container_width,
    m3_total: values.m3_total,
    treatment: values.treatment,
    type: values.type,
    value_80_per_120: values.value_80_per_120,
    weight: values.weight,
    width: values.width,
  };

  if (values.id) {
    payload.id = values.id;
  }

  const [error, response] = yield call(
    LostPacksService.upsertLostPacks,
    payload
  );
  if (error)
    showError(
      values.id ? "lost_packs_update_failed" : "lost_packs_create_failed"
    );
  else if (response) {
    showSuccess(
      values.id ? "lost_packs_update_success" : "lost_packs_create_success"
    );
    yield put(push("/lostpacks"));
  }
  yield put(LoaderActions.loaded());
}

function* lostPacksFamilies() {
  const [error, response] = yield call(LostPacksService.families);
  if (!error && response) {
    yield put(
      LostPacksActions.lostPacksFamiliesSuccess(
        response.lost_packaging_families
      )
    );
  }
}

export default [
  takeLatest(types.LOST_PACKS_REQUEST, lostPacksRequest),
  takeLatest(types.LOST_PACKS_BY_ID_REQUEST, lostPacksByIdRequest),
  takeLatest(types.LOST_PACKS_DELETE, lostPacksDeleteRequest),
  takeLatest(types.LOST_PACKS_CREATE_REQUEST, lostPacksCreateRequest),
  takeLatest(types.LOST_PACKS_FAMILIES_REQUEST, lostPacksFamilies),
];
