import RemitterCreate from "./RemitterCreate";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { RemittersActions } from "../../../redux/remitters";
import { resolveId } from "../../../utils";
import { CountriesActions } from "../../../redux/countries";
import { ArticlesActions } from "../../../redux/articles";

const mapStateToProps = ({ router, locale, remitters }) => ({
  id: resolveId(router),
  remitter: remitters.current,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  remitterRequest: (id) => dispatch(RemittersActions.remitterRequest(id)),
  remitterArticleUpsertRequest: (data, options) =>
    dispatch(ArticlesActions.remitterArticleCreateRequest(data, options)),
  remitterArticleDeleteRequest: (ids) =>
    dispatch(ArticlesActions.remitterArticleDeleteRequest(ids)),
  remitterCreateRequest: (data) =>
    dispatch(RemittersActions.remitterCreateRequest(data)),
  remittersUpdateRequest: (data) =>
    dispatch(RemittersActions.remitterUpdateRequest(data)),
  cleanCurrent: () => dispatch(RemittersActions.cleanCurrent()),
  loadCountries: () => dispatch(CountriesActions.countriesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemitterCreate);
