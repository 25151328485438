import React from "react";
import { Select } from "antd";
import { t } from "assets";

const { Option } = Select;

export default ({ value, onChange, id }) => {
  const onDayChange = (newDays) => {
    if (onChange) {
      onChange(newDays);
    }
  };

  return (
    <Select
      id={id}
      value={value || []}
      onChange={onDayChange}
      mode="multiple"
      allowClear
      getPopupContainer={(trigger) => trigger.parentNode}
      style={{ width: "100%" }}
      placeholder="Please select"
    >
      <Option key={"monday"}>{t("monday")}</Option>
      <Option key={"tuesday"}>{t("tuesday")}</Option>
      <Option key={"wednesday"}>{t("wednesday")}</Option>
      <Option key={"thursday"}>{t("thursday")}</Option>
      <Option key={"friday"}>{t("friday")}</Option>
      <Option key={"saturday"}>{t("saturday")}</Option>
      <Option key={"sunday"}>{t("sunday")}</Option>
    </Select>
  );
};
