import { createSelector } from "reselect";

export const selectCGU = createSelector(
  ({ files }) => files.cgu,
  (cgu) => cgu
);

export const selectPrivacyPolicy = createSelector(
  ({ files }) => files.privacy_policy,
  (privacy_policy) => privacy_policy
);
