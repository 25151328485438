import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { t } from "assets";

export default ({ children, onDragEnd, isError, errors = [] }) => {
  const handleOnDragEnd = (result) => {
    if (onDragEnd) onDragEnd(result);
  };

  return (
    <>
      <span
        className="required-fields"
        style={{
          visibility: isError ? "visible" : "hidden",
        }}
      >
        {errors.length > 0
          ? errors.map((value) => (
              <p className={"required-fields-error"}>{value}</p>
            ))
          : t("required_field")}
      </span>

      <div className="context-container">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {children}
        </DragDropContext>
      </div>
    </>
  );
};
