/* Service utils */
/* Service utils */
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import axios from "axios";

/** GRAPHQL */
const httpLink = new HttpLink({ uri: window.env.REACT_APP_HASURA_URL });
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

/** GRAPHQL - MUTATION */
export const mutation = ({ queryString, headers = {}, variables }) =>
  client
    .mutate({
      mutation: queryString,
      variables,
      context: {
        headers: headers,
      },
    })
    .then((result) => {
      // eslint-disable-next-line no-console
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** GRAPHQL - QUERY */
export const query = ({ queryString, headers = {}, variables }) =>
  client
    .query({
      query: queryString,
      variables,
      context: {
        headers: headers,
      },
      // ... other options
    })
    .then((result) => {
      return Promise.all([result.errors, result.data]);
    })
    .catch((error) => {
      return Promise.resolve([error]);
    });

/** Method types */
export const methods = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const fetch = ({
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType,
  uploadCallBack = (e) => {},
}) => {
  let instance = axios.create();
  /*  instance.interceptors.request.use(AxiosLogger.requestLogger);

      instance.interceptors.response.use(AxiosLogger.responseLogger, (err) =>{
        // write down your error intercept.
        return AxiosLogger.errorLogger(err);
    });*/

  instance.defaults.headers.common = {};
  const { cancelToken } = params;
  delete params.cancelToken;

  return instance(
    {
      baseURL: window.env.REACT_APP_BASE_API_URL,
      method: method,
      url: url,
      data: data,
      params: params,
      cancelToken: cancelToken,
      responseType,
      headers: {
        ...headers,
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      },
      onUploadProgress: (progressEvent) => uploadCallBack(progressEvent),
    },
    { crossdomain: true }
  )
    .then((response) => {
      return Promise.all([null, response]);
    })
    .catch((error) => {
      // Fix proposed by TNU when error.response = undefined (for NetworkError)
      if (error.response) {
        return Promise.resolve([error]);
      }
      return Promise.resolve([{ response: { status: 500 } }]);
    });
};
