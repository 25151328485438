import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import ClientsForm from "./ClientForm";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { selectClient } from "../../../redux/clients/selector";

import "antd/es/drawer/style/css";
import "antd/lib/drawer/style/css";
import { t } from "assets";

export default ({
  id,
  cleanCurrent,
  navigateTo,
  clientRequest,
  clientCreateRequest,
  clientsUpdateRequest,
  loadCountries,
  remitters,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(selectClient);
  const client = useSelector(selectClient);

  useEffect(() => {
    if (id && clientRequest) {
      clientRequest(id);
    }
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (values) => {
    if (client.id) {

      values.id = client.id;
      clientsUpdateRequest({...values, address_id: client.address?.id, client_orders: client.client_orders});
    } else {
      clientCreateRequest(values);
    }
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
        onClick={() => navigateTo("/clients")}
      >
        {t("cancel")}
      </Button>
      <Button
        id="client-create-button"
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <>
      <AuthLayout
        current={"7"}
        bread={`Clients:/clients;${t(
          "clients.form.title"
        )}:/clients/add`}
      >
        <ClientsForm
          extra={extra}
          form={form}
          values={values}
          isModify={id !== null}
          onValidate={onValidate}
          loadCountries={loadCountries}
          remitters={remitters}
        />
      </AuthLayout>
    </>
  );
};
