import {createAction} from "../../utils";

// Types
export const types = {
  CLEAN_CURRENT: "CLEAN_CURRENT",
  LOST_PACKS_REQUEST: "LOST_PACKS_REQUEST",
  LOST_PACKS_SUCCESS: "LOST_PACKS_SUCCESS",
  LOST_PACKS_BY_ID_REQUEST: "LOST_PACKS_BY_ID_REQUEST",
  LOST_PACKS_BY_ID_SUCCESS: "LOST_PACKS_BY_ID_SUCCESS",
  LOST_PACKS_DELETE: "LOST_PACKS_DELETE",
  LOST_PACKS_DELETE_SUCCESS: "LOST_PACKS_DELETE_SUCCESS",
  LOST_PACKS_CREATE_REQUEST: "LOST_PACKS_CREATE_REQUEST",
  LOST_PACKS_CREATE_SUCCESS: "LOST_PACKS_CREATE_SUCCESS",
  LOST_PACKS_FAMILIES_REQUEST: "LOST_PACKS_FAMILIES_REQUEST",
  LOST_PACKS_FAMILIES_SUCCESS: "LOST_PACKS_FAMILIES_SUCCESS",
  LOST_PACKS_FAMILIES_FAILURE: "LOST_PACKS_FAMILIES_FAILURE",
};

// Actions
export default {
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  lostPacksRequest: (query = { code: "" }) =>
    createAction(types.LOST_PACKS_REQUEST, { query }),
  lostPacksSuccess: (list) => createAction(types.LOST_PACKS_SUCCESS, { list }),
  lostPacksByIdRequest: (id) =>
    createAction(types.LOST_PACKS_BY_ID_REQUEST, { id }),
  lostPacksByIdSuccess: (data) =>
    createAction(types.LOST_PACKS_BY_ID_SUCCESS, { data }),
  lostPacksDeleteRequest: (ids) =>
    createAction(types.LOST_PACKS_DELETE, { ids }),
  lostPacksDeleteSuccess: (ids) =>
    createAction(types.LOST_PACKS_DELETE_SUCCESS, { ids }),
  lostPacksCreateRequest: (values) =>
    createAction(types.LOST_PACKS_CREATE_REQUEST, { values }),
  lostPacksFamilies: () => createAction(types.LOST_PACKS_FAMILIES_REQUEST),
  lostPacksFamiliesSuccess: (families) =>
    createAction(types.LOST_PACKS_FAMILIES_SUCCESS, { families }),
};
