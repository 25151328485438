import React, { Component } from "react";
import "./error.css";
import { t } from "assets";

export class Error extends Component {
  state = {
    error: null,
    info: null,
  };

  constructor(props) {
    super(props);

    this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({ error: null, info: null });
      }
    });
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  handleSendError = () => {
    const { error, info } = this.state;
    if (error && info && info.componentStack) {
      const contact = t("generic_error_contact");
      const subject = t("generic_error_subject");
      const body = {
        message: `${t("generic_error_message")}%0A%0A`,
        error: `${error}%0A`,
        stack: `Stack:${info.componentStack}`,
      };

      window.location.href = `mailto:${contact}?subject=${subject}&body=${body.message}${body.error}${body.stack}}`;
    }
  };

  render() {
    if (this.state.error) {
      return (
        <div id={"error"}>
          <p className={"contact"}>
            {t("generic_error_contact_message")}
            <span className={"email"} onClick={this.handleSendError}>
              {"yourcontact@contact.com"}
            </span>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
