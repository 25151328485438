import { encode } from "base-64";
import { push } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { AuthenticationService } from "../../services";
import { CommonActions } from "../common";
import { LoaderActions } from "../loader";
import { default as AuthActions, types } from "./actions";
import { showError } from "../../utils/notifications-helper";
import { t } from "assets";

function* login({ username, password }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);

  const [error, response] = yield call(AuthenticationService.login, {
    username,
    password,
  });
  if (response && response.request.responseURL) {
    const url = response.request.responseURL;
    const splt = url.split("=");
    const code = splt[1];
    const [tokenError, tokenResponse] = yield call(
      AuthenticationService.getToken,
      {
        username,
        password,
        code,
      }
    );
    if (!tokenError && tokenResponse && tokenResponse.data.access_token) {
      const token = tokenResponse.data.access_token;
      const token_type = tokenResponse.data.token_type;
      const refresh_token = tokenResponse.data.refresh_token;

      const authorization = encode(username + ":" + password);
      yield put(
        AuthActions.loginSuccess(
          token_type,
          token,
          refresh_token,
          authorization
        )
      );
      yield put(push("/partners"));
    }
  }
  yield put(LoaderActions.loaded());
  if (error) {
    console.log(error);
    yield put(AuthActions.loginFailure());
    showError("authentication_failed");
  }
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const { refresh_token, authorization } = yield select((state) => state.auth);
  const [tokenError, tokenResponse] = yield call(
    AuthenticationService.refreshToken,
    {
      authorization,
      refresh_token,
    }
  );
  if (tokenResponse && tokenResponse.data.access_token) {
    const token = tokenResponse.data.access_token;
    const token_type = tokenResponse.data.token_type;
    const refresh_token = tokenResponse.data.refresh_token;
    yield put(
      AuthActions.loginSuccess(token_type, token, refresh_token, authorization)
    );
    return [null, `${token_type} ${token}`];
  } else return [tokenError, null];
}

function* logout() {
  yield put(CommonActions.resetReducers());
  yield put(push("/"));
}

export default [
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.LOGOUT, logout),
];
