import { createReducer } from "reduxsauce";
import { types } from "./actions";
import { uniq } from "lodash";

const initialState = {
  current: {},
  users: null,
  available: true,
  loading: false,
  roles: [],
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

const usersSuccess = (state = initialState, action) => {
  const { list } = action;
  return { ...state, users: list };
};

const userSuccess = (state = initialState, action) => {
  const { user } = action;
  const { user_fluxs, added_flux, ...rest } = user;
  let real_flux = user_fluxs;
  if (added_flux?.length) {
    real_flux = user_fluxs.map((el) => {
      if (el.flux?.archived) {
        const real = added_flux.find(
          (toFind) => toFind.original_flux_id === el.id_flux
        );
        if (real) return { id: el.id, id_flux: real.id, flux: real };
      }
      return el;
    });
  }
  const clients = uniq(user_fluxs.map(({ flux }) => flux.client_code));
  const roleClients = rest.role_sealogis_remitters_users.map(
    (role) => role.role_sealogis_remitter.sealogis_remitter.code
  );
  if (roleClients?.length) {
    roleClients.forEach((code) => {
      if (!clients.includes(code)) clients.push(code);
    });
  }
  const current = {
    flux: real_flux.map(({ id_flux }) => id_flux),
    clients: clients,
    role: rest.user_role?.role,
    user_fluxs: real_flux,
    ...rest,
  };
  return { ...state, current };
};

const userDetailSuccess = (state = initialState, action) => {
  const { user } = action;
  const { users } = state;

  const index = users?.findIndex((u) => {
    return u.id === user.id;
  });
  if (!index || index === -1) return { ...state };
  users[index] = user;
  return { ...state, users };
};

const userDeleteSuccess = (state = initialState, action) => {
  const { users } = state;
  const { ids } = action;
  const user_ids = ids.map((item) => item.id);
  const tmp = users?.filter((item) => !user_ids.includes(item.id));
  return { ...state, users: tmp };
};

const emailSuccess = (state = initialState, action) => {
  const { available } = action;
  return { ...state, available };
};

const emailLoading = (state = initialState, action) => {
  const { loading } = action;
  return { ...state, loading };
};

const rolesSuccess = (state = initialState, action) => {
  const { roles } = action;
  return { ...state, roles };
};

export default createReducer(initialState, {
  [types.CLEAN_CURRENT]: cleanCurrent,
  [types.USERS_SUCCESS]: usersSuccess,
  [types.USER_SUCCESS]: userSuccess,
  [types.USERS_DELETE_SUCCESS]: userDeleteSuccess,
  [types.EMAIL_SUCCESS]: emailSuccess,
  [types.EMAIL_LOADING]: emailLoading,
  [types.USER_DETAIL_SUCCESS]: userDetailSuccess,
  [types.ROLE_SUCCESS]: rolesSuccess,
});
