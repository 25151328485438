import {createAction} from "../../utils";

// Types
export const types = {
  PARTNERS_REQUEST: "PARTNERS_REQUEST",
  PARTNERS_SUCCESS: "PARTNERS_SUCCESS",
  PARTNER_REQUEST: "PARTNER_REQUEST",
  PARTNER_SUCCESS: "PARTNER_SUCCESS",
  PARTNERS_CREATE_REQUEST: "PARTNERS_CREATE_REQUEST",
  PARTNERS_UPDATE_REQUEST: "PARTNERS_UPDATE_REQUEST",
  PARTNER_DELETE: "PARTNER_DELETE",
  PARTNER_DELETE_SUCCESS: "PARTNER_DELETE_SUCCESS",
  TYPES_REQUEST: "TYPES_REQUEST",
  TYPES_SUCCESS: "TYPES_SUCCESS",
  CLEAN_CURRENT: "CLEAN_CURRENT",
  IMAGE_CREATE: "IMAGE_CREATE",
  IMAGE_CREATE_OVH: "IMAGE_CREATE_OVH",
  IMAGE_SUCCESS: "IMAGE_SUCCESS",
  IMAGE_OVH_SUCCESS: "IMAGE_OVH_SUCCESS",
  CLEAN_IMAGE_REQUEST: "CLEAN_IMAGE_REQUEST",
};

// Actions
export default {
  partnersRequest: () => createAction(types.PARTNERS_REQUEST),
  partnersSuccess: (list) => createAction(types.PARTNERS_SUCCESS, { list }),
  partnerRequest: (id) => createAction(types.PARTNER_REQUEST, { id }),
  partnerSuccess: (partner) => createAction(types.PARTNER_SUCCESS, { partner }),
  partnerUpdateRequest: (data) =>
    createAction(types.PARTNERS_UPDATE_REQUEST, { data }),
  partnerCreateRequest: (values) =>
    createAction(types.PARTNERS_CREATE_REQUEST, { values }),
  partnersDeleteRequest: (partnerIds) =>
    createAction(types.PARTNER_DELETE, { partnerIds }),
  partnersDeleteSuccess: (partnersIds) =>
    createAction(types.PARTNER_DELETE_SUCCESS, { partnersIds }),
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  typesRequest: () => createAction(types.TYPES_REQUEST),
  typesSuccess: (list) => createAction(types.TYPES_SUCCESS, { list }),
  imageCreate: (file, fileName) => createAction(types.IMAGE_CREATE, { file, fileName }),
  imageCreateOnOvh: (file, fileName) => createAction(types.IMAGE_CREATE_OVH, { file, fileName }),
  imageSuccess: (name) => createAction(types.IMAGE_SUCCESS, { name }),
  imageOvhSuccess: (name) => createAction(types.IMAGE_OVH_SUCCESS, { name }),
  cleanImageRequest: () => createAction(types.CLEAN_IMAGE_REQUEST),
};
