import { createSelector } from "reselect";

export const roles = createSelector(
  (state) => state.users.roles,
  (roles) => roles
);

export const cgu = createSelector(
  (state) => state.users.current.cgu,
  (cgu) => cgu
);

export const privacypolicy = createSelector(
  (state) => state.users.current.privacypolicy,
  (privacypolicy) => privacypolicy
);

export const current = createSelector(
  (state) => state.users.current,
  (current) => current
);

export const currentWithRoles = createSelector(
  (state) => state.users.current,
  (current) => {
    if (!current) return {};
    current["roles_by_remitters"] = current?.role_sealogis_remitters_users?.map(
      (el) => {
        let ret = `${el.role_sealogis_remitter.id_sealogis_remitter}_${el.role_sealogis_remitter.id}`;
        if (el.role_sealogis_remitter.user_role.role === "BCU") {
          ret += `_${current.bcu_code}`;
        }
        return ret;
      }
    );
    return current;
  }
);
