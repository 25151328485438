import React, { useEffect, useState } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import { useDispatch, useSelector } from "react-redux";
import { selectRemitter } from "../../../redux/remitters/selector";

import "antd/es/drawer/style/css";
import "antd/lib/drawer/style/css";
import RemitterArticlesTable from "./RemitterArticlesTable";
import ListPageFilters from "../../components/listPageFilters";
import { ROUTES } from "../../../core/routes";
import { BasicComponents } from "../../components/basic";
import { RemittersActions } from "../../../redux/remitters";
import { ArticlesActions } from "../../../redux/articles";
import { push } from "connected-react-router";
import { selectArticles } from "../../../redux/articles/selector";
import { LostPacksActions } from "../../../redux/lostPacks";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ModalImportReport from "./ModalImportReport";
import { t } from "assets";

export default ({ id }) => {
  const [valueList, setValueList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const dispatch = useDispatch();
  const remitter = useSelector(selectRemitter);
  const articles = useSelector(selectArticles) || [];

  useEffect(() => {
    if (id) {
      dispatch(RemittersActions.remitterRequest(id));
      dispatch(LostPacksActions.lostPacksRequest());
      dispatch(ArticlesActions.getAllRelatedArticleSelects());
    }
    return () => {
      dispatch(RemittersActions.cleanCurrent());
      dispatch(ArticlesActions.cleanArticles());
    };
  }, []);

  useEffect(() => {
    setValueList(articles || []);
  }, [articles]);

  useEffect(() => {
    const { code } = remitter || {};
    if (code) dispatch(ArticlesActions.remitterArticleRequest(code));
  }, [remitter]);

  const handleOnCreate = (article, options) => {
    delete article.id;
    article.remitter_name = remitter.name;

    dispatch(
      ArticlesActions.remitterArticleCreateRequest(
        article,
        options,
        remitter.code
      )
    );
  };

  const handleOnUpdate = (article, options) => {
    article.remitter_name = remitter.name;

    dispatch(
      ArticlesActions.remitterArticleCreateRequest(
        article,
        options,
        remitter.code
      )
    );
  };

  const handleOnDelete = (id) => {
    if (dispatch) dispatch(ArticlesActions.remitterArticleDeleteRequest([id]));
  };

  const onFilter = (result) => {
    setValueList(result || remitter?.articles_news);
  };

  const goBack = () => {
    dispatch(push(ROUTES.REMITTERS));
  };

  const beforeUpload = (file) => {
    if (!file) return;
    setUploadLoading(true);
    dispatch(
      ArticlesActions.uploadExcelRequest(file, remitter.code, () => setUploadLoading(false))
    );
  };

  return (
    <>
      <ModalImportReport />
      <AuthLayout
        current={"4"}
        bread={`Remettants:/#/remitters;${t(
          "remitters.form.title"
        )}:/#/remitters/${id};${"Base articles"}:/#/remitters/${id}/articles`}
      >
        <div id={"filters-div"}>
          <BasicComponents.Typography style={{ margin: "20px" }}>
            {`${t("remitters.article_base")} ${remitter?.name}`}
          </BasicComponents.Typography>
          <ListPageFilters
            filterList={articles || []}
            onFilter={onFilter}
            filterKeys={["internal_code"]}
            filterPlaceholder={t("search")}
            errorButtonPlaceholder={t("back")}
            onErrorClick={goBack}
            additionalComponent={
              <Upload
                id={`upload-excel-component`}
                beforeUpload={beforeUpload}
                maxCount={1}
                showUploadList={false}
                customRequest={() => 1}
                onPreview={() => {}}
                accept={".xlsx"}
              >
                <Button
                  id={`upload-button`}
                  type="primary"
                  style={{ marginLeft: 20 }}
                  icon={<UploadOutlined />}
                  loading={uploadLoading}
                >
                  {t("upload_excel")}
                </Button>
              </Upload>
            }
          />
        </div>
        <RemitterArticlesTable
          articles={valueList}
          onCreate={handleOnCreate}
          onUpdate={handleOnUpdate}
          onDelete={handleOnDelete}
        />
      </AuthLayout>
    </>
  );
};

/*

  TO CLEAN:
  deposant => depositor


  TO ADD:
  PCB => pcb


  EXISTING NOT CONNECTED:


  UNKNWON
  "Emballage perdue",
  "Consigne Emballage perdue",
  "Poids brut Emballage perdue kg",
  "Largeur Emballage perdue cm",
  "Longueur Emballage perdue cm",
  "Hauteur Emballage perdue cm",
  "Hauteur palette homogène brut cm",
  "Poids palette homogène brut kg",
 */
