import { createAction } from "../../utils";

export const types = {
  REMITTERS_REQUEST: "REMITTERS_REQUEST",
  REMITTERS_SUCCESS: "REMITTERS_SUCCESS",
  REMITTER_REQUEST: "REMITTER_REQUEST",
  REMITTER_SUCCESS: "REMITTER_SUCCESS",
  REMITTERS_CREATE_REQUEST: "REMITTERS_CREATE_REQUEST",
  REMITTERS_UPDATE_REQUEST: "REMITTERS_UPDATE_REQUEST",
  REMITTERS_DELETE_REQUEST: "REMITTERS_DELETE_REQUEST",
  REMITTERS_DELETE_SUCCESS: "REMITTERS_DELETE_SUCCESS",
  /* REMITTERS CONTACT */
  REMITTERS_CONTACT_CREATE_REQUEST: "REMITTERS_CONTACT_CREATE_REQUEST",
  REMITTERS_CONTACT_UPDATE_REQUEST: "REMITTERS_CONTACT_UPDATE_REQUEST",
  REMITTERS_WITH_ROLES_REQUEST: "REMITTERS_WITH_ROLES_REQUEST",
  REMITTERS_WITH_ROLES_SUCCESS: "REMITTERS_WITH_ROLES_SUCCESS",

  CLEAN_CURRENT: "CLEAN_CURRENT_REMITTER",
};

export default {
  remittersRequest: () => createAction(types.REMITTERS_REQUEST),
  remittersSuccess: (list) => createAction(types.REMITTERS_SUCCESS, { list }),
  remitterRequest: (id) => createAction(types.REMITTER_REQUEST, { id }),
  remitterSuccess: (remitter) =>
    createAction(types.REMITTER_SUCCESS, { remitter }),
  remitterUpdateRequest: (data) =>
    createAction(types.REMITTERS_UPDATE_REQUEST, { data }),
  remitterCreateRequest: (data) =>
    createAction(types.REMITTERS_CREATE_REQUEST, { data }),
  remitterContactUpdateRequest: (data) =>
    createAction(types.REMITTERS_CONTACT_UPDATE_REQUEST, { data }),
  remitterContactCreateRequest: (data) =>
    createAction(types.REMITTERS_CONTACT_CREATE_REQUEST, { data }),
  remitterDeleteRequest: (ids) =>
    createAction(types.REMITTERS_DELETE_REQUEST, { ids }),
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  remitterDeleteSuccess: (ids) =>
    createAction(types.REMITTERS_DELETE_SUCCESS, { ids }),
  remittersWithRoleRequest: () =>
    createAction(types.REMITTERS_WITH_ROLES_REQUEST),
  remittersWithRoleSuccess: (remitters, bcu) =>
    createAction(types.REMITTERS_WITH_ROLES_SUCCESS, { remitters, bcu }),
};
