import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { CountriesService } from "../../services";
import { CountriesActions } from ".";
import { showError } from "../../utils/notifications-helper";
import { t } from "assets";

function* countriesRequest() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(CountriesService.countries);
  if (error) showError("remitters.errors.countries_failed");
  else if (response)
    yield put(CountriesActions.countriesRequestSuccess(response));
  yield put(LoaderActions.loaded());
}

export default [takeLatest(types.COUNTRIES_REQUEST, countriesRequest)];
