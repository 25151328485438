import { createSelector } from "reselect";
import { uniq } from "lodash";

export const selectRemitter = createSelector(
  (state) => state.remitters.current,
  (current) => {
    const formatted = { ...current };
    return formatted;
  }
);

export const selectArticles = createSelector(
  (state) => state.remitters.articles,
  (articles) => {
    return articles;
  }
);

export const selectWithRoles = createSelector(
  (state) => [
    state.remitters.remittersWithRoles,
    state.remitters.bcu,
    state.users.roles,
  ],
  ([remitters, bcu, roles]) => {
    const bcuRole = uniq(bcu.map((el) => el.user_role_code));
    return remitters.map((remitter) => {
      const allowedRolesIds = remitter.role_sealogis_remitters.map(
        (el) => el.id_role
      );
      const isBcu = remitter.role_sealogis_remitters.find((el) =>
        bcuRole.includes(el.user_role.role)
      );
      return {
        ...remitter,
        value: remitter.id,
        title: remitter.name,
        nbChildren: !isBcu ? 1 : 2,
        idBcuExpand: !isBcu ? -1 : isBcu.id,
        children: roles
          .filter((role) => allowedRolesIds.includes(role.id))
          .map((role) => {
            const allowedRole = remitter.role_sealogis_remitters.find(
              (el) => el.id_role === role.id
            );

            const ret = {
              value: `${remitter.id}_${allowedRole.id}`,
              title: role.role,
              titleInSelect: `${remitter.name} / ${role.role}`,
            };
            if (isBcu && bcuRole.includes(allowedRole.user_role.role)) {
              ret.children = bcu.map((el) => ({
                value: `${remitter.id}_${allowedRole.id}_${el.code}`,
                title: el.code,
                titleInSelect: `${remitter.name} / ${role.role} / ${el.code}`,
              }));
            }
            return ret;
          }),
      };
    });
  }
);
