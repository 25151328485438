export const sortColumn = (a, b, type) => {
  switch(type) {
    case types.STRING : 
      return a ? a.localeCompare(b ? b : "") : "".localeCompare(b ? b : "")
    case types.INT : 
      return a - b
    default : 
      return 0
  }
}

export const types = {
  STRING: "STRING",
  INT: "INT"
}