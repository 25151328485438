import React, { useEffect } from "react";
import {Affix, Card, Col, Form, Input, Row, Space} from "antd";
import "./style.css";
import { SelectForm, Upload, WeekDayPicker } from "../../components";
import { requiredRules } from "../../../utils/form-helper";

import "antd/lib/form/style/css";
import { allCountries } from "../../../redux/countries/selectors";
import { useSelector } from "react-redux";
import {
  validatorEmail,
  validatorPhone,
} from "../../../utils/validator-helper";
import { t } from "assets";

export default ({ extra, onValidate, values, form, types, loadCountries }) => {
  const countries = useSelector(allCountries);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
    if (countries.length === 0) {
      if (loadCountries) loadCountries();
    }
  }, [values]);

  const formatCity = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };

  const onFinish = (values) => {
    if (onValidate) onValidate({ ...values, town: formatCity(values?.town) });
  };

  const fields = [
    { key: "comercial" },
    { key: "code", required: true },
    { key: "legal_form" },
    { key: "name", required: true },
    { key: "address" },
    { key: "zip_code" },
    { key: "town" },
    { key: "country", nest: ["country", "id"], component: "countryPicker" },
    {
      key: "phone_number",
      stringKey: "phone",
      validator: validatorPhone,
    },
    { key: "fax_number" },
    { key: "email", validator: validatorEmail },
    { key: "tva_number", required: true },
    { key: "EORI_number" },
    { key: "identification_number" },
    { key: "ape_code" },
    {
      key: "wms_code",
      stringKey: "wms_code",
      validator: (val) => val.length < 5,
    },
    { key: "id_management", component: "hide" },
    { key: "id_accounts", component: "hide" },
    { key: "id_sales", component: "hide" },
  ];

  const directionFields = [
    { key: "management.name", nest: ["management", "name"] },
    {
      key: "management.email",
      stringKey: "email",
      nest: ["management", "email"],
      validator: validatorEmail,
    },
    {
      key: "management.phone_number",
      stringKey: "phone",
      nest: ["management", "phone_number"],
      validator: validatorPhone,
    },
  ];

  const accountsFields = [
    { key: "accounts.name", nest: ["accounts", "name"] },
    {
      key: "accounts.email",
      stringKey: "email",
      nest: ["accounts", "email"],
      validator: validatorEmail,
    },
    {
      key: "accounts.phone_number",
      stringKey: "phone",
      nest: ["accounts", "phone_number"],
      validator: validatorPhone,
    },
  ];

  const salesFields = [
    { key: "sales.name", nest: ["sales", "name"] },
    {
      key: "sales.email",
      nest: ["sales", "email"],
      stringKey: "email",
      validator: validatorEmail,
    },
    {
      key: "sales.phone_number",
      nest: ["sales", "phone_number"],
      stringKey: "phone",
      validator: validatorPhone,
    },
  ];

  const onFinishFailed = ({ errorFields }) => {
    const names = errorFields[0]?.name;
    const id = names[names.length - 1];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      if (el) el.scrollIntoView();
    }
  };

  const components = {
    dayPicker: (item) => <WeekDayPicker id={item.key} />,
    //select: () => <SelectForm array={types} />,
    countryPicker: (item) => (
      <SelectForm
        id="country_picker"
        array={countries ? countries : []}
        codeKey={"id"}
        valueKey={"country_name"}
      />
    ),
    input: (item) => (
      <Input
        id={item.key}
        placeholder={`${t(`remitters.form.${item.key}`)}`}
        maxLength={50}
      />
    ),
    hide: (item) => (
      <Input
        className="hide"
        id={item.key}
        placeholder={`${t(`remitters.form.${item.key}`)}`}
        maxLength={50}
      />
    ),
    upload: (item) => (
      <Upload
        select={item.select}
        item={item}
        allowTypes={["image/jpeg", "image/png", "image/heic"]}
        errorMessageType={"wrong_image_format"}
        accept={".jpg, .jpeg, .png"}
      />
    ),
  };

  return (
    <div>
      <Card
        className={"card-remitters-create max-width-900"}
        style={{ margin: 25, width: "80%" }}
      >
        <Form
          onFinishFailed={onFinishFailed}
          initialValues={values}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map((item) => (
            <div id={`${item.key}-form`}>
              <Form.Item
                name={item.nest ?? item.key}
                validateTrigger={item.validateTrigger || "onChange"}
                label={t(`remitters.form.${item.key}`)}
                rules={[
                  requiredRules(item),
                  () => ({
                    validator(rule, value) {
                      if (item.validator && !item.validator(value))
                        return Promise.reject(
                          `${t(item.stringKey || item.key)} ${t("incorrect")}`
                        );

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[item.component || "input"](item)}
              </Form.Item>
            </div>
          ))}
        </Form>
      </Card>
      <Card
        className={"width-p-80 card-remitters-create max-width-900"}
        style={{ margin: 25 }}
      >
        <h2>{t("remitters.form.management.title")}</h2>
        <Form
          onFinishFailed={onFinishFailed}
          initialValues={values}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {directionFields.map((item) => (
            <div id={`${item.key}-form`}>
              <Form.Item
                name={item.nest ?? item.key}
                validateTrigger={item.validateTrigger || "onChange"}
                label={t(`remitters.form.${item.key}`)}
                rules={[
                  requiredRules(item),
                  () => ({
                    validator(rule, value) {
                      if (item.validator && !item.validator(value))
                        return Promise.reject(
                          `${t(item.stringKey || item.key)} incorrect`
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[item.component || "input"](item)}
              </Form.Item>
            </div>
          ))}
        </Form>
      </Card>
      <div className={"width-p-80 max-width-900"} style={{ margin: "0 auto" }}>
        <Row justify={"space-between"} wrap>
          <Col flex={1} style={{ marginRight: 3 }}>
            <Card style={{ width: "100%" }}>
              <h2>{t("remitters.form.sales.title")}</h2>
              <Form
                onFinishFailed={onFinishFailed}
                initialValues={values}
                onFinish={onFinish}
                layout="vertical"
                form={form}
              >
                {salesFields.map((item) => (
                  <div id={`${item.key}-form`}>
                    <Form.Item
                      name={item.nest ?? item.key}
                      validateTrigger={item.validateTrigger || "onChange"}
                      label={t(`remitters.form.${item.key}`)}
                      rules={[
                        requiredRules(item),
                        () => ({
                          validator(rule, value) {
                            if (item.validator && !item.validator(value))
                              return Promise.reject(
                                `${t(item.stringKey || item.key)} incorrect`
                              );
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {components[item.component || "input"](item)}
                    </Form.Item>
                  </div>
                ))}
              </Form>
            </Card>
          </Col>
          <Col flex={1} style={{ marginLeft: 3 }}>
            <Card style={{ width: "100%" }}>
              <h2>{t("remitters.form.accounts.title")}</h2>
              <Form
                onFinishFailed={onFinishFailed}
                initialValues={values}
                onFinish={onFinish}
                layout="vertical"
                form={form}
              >
                {accountsFields.map((item) => (
                  <div id={`${item.key}-form`}>
                    <Form.Item
                      name={item.nest ?? item.key}
                      validateTrigger={item.validateTrigger || "onChange"}
                      label={t(`remitters.form.${item.key}`)}
                      rules={[
                        requiredRules(item),
                        () => ({
                          validator(rule, value) {
                            if (item.validator && !item.validator(value))
                              return Promise.reject(
                                `${t(item.stringKey || item.key)} incorrect`
                              );
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {components[item.component || "input"](item)}
                    </Form.Item>
                  </div>
                ))}
              </Form>
            </Card>
          </Col>
        </Row>
        <Affix offsetBottom={0}>{extra}</Affix>
      </div>
    </div>
  );
};
