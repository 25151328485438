import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Layout,
  PageHeader,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import React from "react";

import SideMenu from "../../components/sideMenu";

import "antd/es/breadcrumb/style/css";
import "antd/lib/breadcrumb/style/css";
import { t } from "assets";

const { Header, Content } = Layout;

class AuthLayout extends React.Component {
  state = {
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onLogout = () => {
    this.props.logout();
  };

  render() {
    const bread = this.props.bread || "";
    const { Title } = Typography;

    return (
      <SideMenu
        current={this.props.current}
        inlineCollapsed={this.state.collapsed}
      >
        <Header
          style={{
            background: "#fff",
            padding: 0,
            zIndex: 2,
            boxShadow: "0 1px 4px rgba(0, 21, 41, 0.08)",
          }}
        >
          <Row type="flex" justify="space-between">
            <Col
              span={12}
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
            >
              {this.state.collapsed ? (
                <MenuUnfoldOutlined
                  onClick={this.toggleCollapsed}
                  style={{ marginLeft: 15 }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={this.toggleCollapsed}
                  style={{ marginLeft: 15 }}
                />
              )}
              {this.props.bread &&
                  <Breadcrumb style={{ margin: '16px' }}>
                    {this.props.bread.split(";").map( (item, i) => {

                      const title = item.split(':')[0]
                      const link = item.split(':')[1]
                      if (i + 1 === this.props.bread.split(";").length)
                        return (<Breadcrumb.Item>{title}</Breadcrumb.Item>)
                      return (<Breadcrumb.Item href={"/#" + link} >{title}</Breadcrumb.Item>)
                    })}
                  </Breadcrumb>
              }
            </Col>
            <Col span={1}>
              <Popconfirm
                title={t("confirm_logout")}
                okText="Oui"
                cancelText="Non"
                placement="bottomRight"
                onConfirm={this.onLogout}
              >
                <Button
                  id={"logout"}
                  icon={<LogoutOutlined />}
                  shape="circle"
                />
              </Popconfirm>
            </Col>
          </Row>
        </Header>
        {(this.props.title || this.props.extra) && <PageHeader
          title={this.props.title}
          subTitle={this.props.subtitle}
          tags={this.props.tags}
          extra={this.props.extra}
          footer={this.props.footer}
        >
          <div className="wrap">
            <div className="content">{this.props.content}</div>
            <div className="extraContent">{this.props.extraContent}</div>
          </div>
        </PageHeader>}
        <Content
          id={"content"}
          style={{
            margin: "0px 16px",
            maxHeight: "inherit",
            overflow: "auto",
          }}
        >
          <div>{this.props.children}</div>
        </Content>
      </SideMenu>
    );
  }
}

export default AuthLayout;
