import React, { useEffect, useState } from "react";
import { Affix, Card, Form, Input, Switch } from "antd";
import { CustomMultipleSelect, CustomTreeSelect } from "../../components";
import { difference } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { validatorPhone } from "../../../utils/validator-helper";
import { useSelector } from "react-redux";
import "./style.css";
import { t } from "assets";
import { selectWithRoles } from "../../../redux/remitters";

const { TextArea } = Input;

export default ({
  extra,
  onValidate,
  values,
  clients,
  flux,
  form,
  emailAvailable,
  emailLoading,
  emailRequest,
}) => {
  const [available, setAvailable] = useState(true);
  const [selectedClients, setSelectedClients] = useState(values?.clients || []);
  const remittersRole = useSelector(selectWithRoles);
  const [activated, setActivated] = useState(values?.activated || false);

  useEffect(() => {
    if (values) {
      form.resetFields();
      setSelectedClients(values?.clients || []);
      setActivated(values?.activated || false);
    }
  }, [values]);

  useEffect(() => {
    if (emailAvailable !== available) form.validateFields(["email"]);
    setAvailable(emailAvailable);
  }, [emailAvailable]);

  const onFinish = (values) => {
    if (onValidate) {
      onValidate(values);
    }
  };

  const onChange = (event) => {
    setActivated(event);
  };

  const components = {
    textarea: ({ key }) => (
      <TextArea id={key} placeholder={`${t(`users.form.${key}`)}`} />
    ),
    input: ({ key, args, disabled }) => (
      <Input
        id={key}
        placeholder={`${t(`users.form.${key}`)}`}
        maxLength={50}
        suffix={args ? <LoadingOutlined /> : <span />}
        disabled={disabled}
      />
    ),
    select: ({ args, key }) => (
      <CustomMultipleSelect
        id={key}
        array={args}
        placement={"topLeft"}
        placeholder={t(`users.form.${key}`)}
      />
    ),
    switch: ({ args, key, checked }) => (
      <Switch
        defaultChecked={false}
        id={key}
        checked={activated}
        onChange={onChange}
      />
    ),
    treeSelect: ({ args }) => (
      <CustomTreeSelect
        options={args.remitters}
        defaultValue={args.value}
        multiple={true}
      />
    ),
  };

  const filteredFlux =
    flux
      ?.filter(({ client_code }) => selectedClients.includes(client_code))
      .map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [];

  const fields = [
    { key: "first_name", required: true },
    { key: "last_name", required: true },
    { key: "password", required: values?.id ? false : true },
    { key: "activated", component: "switch" },
    {
      key: "phone",
      required: true,
      validator: validatorPhone,
    },
    {
      key: "email",
      required: true,
      args: emailLoading,
      error: t("email_format"),
      errorMail: t("email_not_available"),
      validator: (item) => {
        const ret = !item ? true : item.match(/\S+@\S+\.\S+/g);
        if (item && ret) {
          emailRequest(item);
        }
        return ret;
      },
      disabled: values?.id ? true : false,
    },
    { key: "company", required: true },
    { key: "note", component: "textarea" },
    {
      key: "clients",
      component: "select",
      required: true,
      args: clients.map((item) => ({
        label: item.name,
        value: item.code,
      })),
    },
    {
      key: "flux",
      component: "select",
      /*required: true,*/
      args: filteredFlux,
    },
    {
      key: "roles_by_remitters",
      component: "treeSelect",
      args: {
        remitters: remittersRole.filter((item) =>
          selectedClients.includes(item.code)
        ),
        value: values?.roles_by_remitters,
      },
      required: true,
      validator: (item) => {
        return item?.length === selectedClients?.length;
      },
      error: t("remitter_roles_length"),
    },
  ];

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required,
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFinishFailed = ({ errorFields }) => {
    const id = errorFields[0]?.name[0];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const handleOnChange = ({ clients }) => {
    if (clients) {
      const diff = difference(clients, selectedClients);
      const toAdd =
        flux
          ?.filter(({ client }) => diff.includes(client))
          .map(({ id }) => id) || [];
      const act = form.getFieldValue("flux");
      const filter =
        flux
          ?.filter(({ client_code }) => {
            return clients.includes(client_code);
          })
          .map(({ id }) => id) || [];
      form.setFieldsValue({
        flux: [
          ...(act?.filter((item) => filter.includes(item)) || []),
          ...toAdd,
        ],
      });
      setSelectedClients(clients);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }} className={"card-user-create"}>
      <div className={"ant-card-body"}>
        <Form
          initialValues={values}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleOnChange}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map(
            (
              {
                key,
                validateTrigger,
                noStyle,
                error,
                validator,
                validatorMail,
                errorMail,
                args,
                component,
                disabled,
              },
              index
            ) => (
              <div id={`${key}-form`} key={`${key}-form-key`}>
                <Form.Item
                  validateTrigger={validateTrigger || "onChange"}
                  noStyle={noStyle}
                  name={key}
                  label={t(`users.form.${key}`)}
                  rules={[
                    requiredRules(fields[index]),
                    () => ({
                      validator(rule, value) {
                        if (validator && !validator(value))
                          return Promise.reject(
                            error || `${t(`users.form.${key}`)} incorrect`
                          );
                        if (
                          errorMail &&
                          value &&
                          value !== values?.email &&
                          !emailAvailable
                        )
                          return Promise.reject(errorMail);
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  disabled={disabled}
                >
                  {components[component || "input"]({ key, args, disabled })}
                </Form.Item>
              </div>
            )
          )}
        </Form>
      </div>
      <Affix offsetBottom={0}>{extra}</Affix>
    </Card>
  );
};
