import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./history";
import store from "./store";
import {
  Admin,
  ErrorBoundary,
  Flux,
  FluxCreate,
  Login,
  LostPackagingCreate,
  LostPackagings,
  Partners,
  PartnersCreate,
  Remitters,
  RemittersArticles,
  RemittersCreate,
  UserCreate,
  Users,
  Clients,
  ClientsCreate,
} from "../ui/screens";
import { ROUTES } from "./routes";

const AuthRoute = ({ component: Component, ...rest }) => {
  // fixme: Add your own validation logic for auth pages (ex. a valid token)
  const { token } = store.getState().auth;
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <ErrorBoundary>
            <Component {...props} {...rest} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: "/", state: { ...rest.location } }} />
        )
      }
    />
  );
};

// Main router which handle user navigation
const Router = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={ROUTES.LOGIN} render={() => <Login/>}/>
        <Route exact path={ROUTES.PARTNERS} component={Partners}/>
        <Route exact path={ROUTES.ADD_PARTNER} component={PartnersCreate}/>
        <Route exact path={ROUTES.ONE_PARTNER} component={PartnersCreate}/>
        <Route exact path={ROUTES.FLUX} component={Flux}/>
        <Route exact path={ROUTES.ADD_FLUX} component={FluxCreate}/>
        <Route exact path={ROUTES.ONE_FLUX} component={FluxCreate}/>
        <Route exact path={ROUTES.USERS} component={Users}/>
        <Route exact path={ROUTES.ADD_USER} component={UserCreate}/>
        <Route exact path={ROUTES.ONE_USER} component={UserCreate}/>
        <Route exact path={ROUTES.ADMIN} component={Admin} />
        <Route exact path={ROUTES.REMITTERS} component={Remitters}/>
        <Route exact path={ROUTES.CLIENTS} component={Clients}/>
        <Route exact path={ROUTES.ADD_CLIENT} component={ClientsCreate}/>
        <Route exact path={ROUTES.ONE_CLIENT} component={ClientsCreate}/>
        <Route exact path={ROUTES.ADD_REMITTER} component={RemittersCreate}/>
        <Route exact path={ROUTES.ONE_REMITTER} component={RemittersCreate}/>
        <Route exact path={ROUTES.REMITTER_ARTICLES} component={RemittersArticles}/>
        <Route exact path={ROUTES.LOST_PACKS} component={LostPackagings}/>
        <Route exact path={ROUTES.ADD_LOST_PACK} component={LostPackagingCreate}/>
        <Route exact path={ROUTES.ONE_LOST_PACK} component={LostPackagingCreate}/>
        <Route component={() => <div>404</div>}/>
      </Switch>
    </ConnectedRouter>
  );
};

export default Router;
