import {createAction} from "../../utils";

// Types
export const types = {
  FLUXS_REQUEST: "FLUXS_REQUEST",
  FLUXS_SUCCESS: "FLUXS_SUCCESS",
  FLUX_DELETE: "FLUX_DELETE",
  FLUX_DELETE_SUCCESS: "FLUX_DELETE_SUCCESS",
  FLUX_REQUEST: "FLUX_REQUEST",
  FLUX_SUCCESS: "FLUX_SUCCESS",
  FLUX_CREATE: "FLUX_CREATE",
  FLUX_UPDATE: "FLUX_UPDATE",
  CLEAN_CURRENT: "CLEAN_CURRENT",
  CLIENT_REQUEST: "CLIENT_REQUEST",
  CLIENT_SUCCESS: "CLIENT_SUCCESS",
  CHECK_FLUX_REQUEST: "CHECK_FLUX_REQUEST",
  CHECK_FLUX_SUCCESS: "CHECK_FLUX_SUCCESS",
  CHECK_FLUX_LOADING: "CHECK_FLUX_LOADING",
  ADD_FLUX_SUCCESS: "ADD_FLUX_SUCCESS",
  RESET_FLUX_AVAILABLE: "RESET_FLUX_AVAILABLE"
};

// Actions
export default {
  resetFluxAvailable: () => createAction(types.RESET_FLUX_AVAILABLE ),
  checkFluxRequest: (values) => createAction(types.CHECK_FLUX_REQUEST, values ),
  checkFluxSuccess: (available) => createAction(types.CHECK_FLUX_SUCCESS, { available }),
  checkFluxLoading: (loading) => createAction(types.CHECK_FLUX_LOADING, { loading }),
  fluxsRequest: (where) => createAction(types.FLUXS_REQUEST, { where }),
  fluxsSuccess: (values) => createAction(types.FLUXS_SUCCESS, { values }),
  fluxsDeleteRequest: (fluxIds) => createAction(types.FLUX_DELETE, { fluxIds }),
  fluxsDeleteSuccess: (fluxIds) =>
    createAction(types.FLUX_DELETE_SUCCESS, { fluxIds }),
  fluxRequest: (id) => createAction(types.FLUX_REQUEST, { id }),
  fluxSuccess: (flux) => createAction(types.FLUX_SUCCESS, { flux }),
  fluxCreateRequest: (flux) => createAction(types.FLUX_CREATE, { flux }),
  fluxUpdateRequest: (flux) => createAction(types.FLUX_UPDATE, { flux }),
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  clientRequest: () => createAction(types.CLIENT_REQUEST),
  clientSuccess: (values) => createAction(types.CLIENT_SUCCESS, { values }),
  addFluxSuccess: (values) => createAction(types.ADD_FLUX_SUCCESS, { values }),
};
