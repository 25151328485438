import { gql } from "@apollo/client";

export default {
  remitters: () => gql`
    query getRemitters {
      sealogis_remitters {
        code
        id
        name
        address
        zip_code
        town
        phone_number
        fax_number
        email
        country {
          country_name
        }
      }
    }
  `,
  remitter: () => gql`
    query getRemitter($id: Int) {
      sealogis_remitters(where: { id: { _eq: $id } }) {
        id
        code
        comercial
        legal_form
        name
        address
        zip_code
        town
        phone_number
        fax_number
        email
        tva_number
        EORI_number
        identification_number
        ape_code
        wms_code
        id_accounts
        id_management
        id_sales
        country {
          id
          country_name
        }
        management {
          id
          name
          email
          phone_number
        }
        sales {
          id
          name
          email
          phone_number
        }
        accounts {
          id
          name
          email
          phone_number
        }
      }
    }
  `,

  getRoles: gql`
    query getRolesForRemitters($codes: [String!]!) {
      user_roles(where: { role: { _in: $codes } }) {
        id
      }
    }
  `,

  withRoles: gql`
    query getRemittersWithRoles {
      sealogis_remitters {
        code
        name
        id
        role_sealogis_remitters {
          id_role
          id
          user_role {
            role
          }
        }
      }

      bcu {
        code
        user_role_code
      }
    }
  `,
};
