import Users from "./Users";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { UsersActions } from "../../../redux/users";

const mapStateToProps = ({ locale, users }) => ({
  list: users.users,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  usersRequest: () => dispatch(UsersActions.usersRequest()),
  usersDeleteRequest: (ids) => dispatch(UsersActions.usersDeleteRequest(ids)),
  resendRequest: (id) => dispatch(UsersActions.resendRequest(id)),
  userDetailUpdateRequest: (user) => dispatch(UsersActions.userDetailUpdateRequest(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
