import { Select } from "antd";
import React from "react";
import { t } from "assets";

const { Option } = Select;

export default ({ array, clientKey, itemKeyName, itemKeyValue, handleFilterClick, width = 200 }) => {
  return (
    <Select
      className={"dropdown-filter"}
      allowClear
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={(e) => handleFilterClick(clientKey, e)}
      style={{ width }}
      placeholder={t(clientKey)}
      showSearch
    >
      {array.map((item, index) => (
        <Option value={itemKeyValue ? item[itemKeyValue] : item} key={`${itemKeyValue ? item[itemKeyValue] : itemKeyValue}-${index}`}>
          {itemKeyName ? item[itemKeyName] : item}
        </Option>
      ))}
    </Select>
  );
};
