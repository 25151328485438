import { createSelector } from "reselect";

export const selectCurrentFlux = createSelector(
  (state) => state.flux.current,
  (flux) => {
    if (!flux) return flux;
    return {
      ...flux,
      steps: flux.steps.map((step, index) => {
        const key = index + 1;
        return {
          ...step,
          key,
          expand: true,
          title: `droppable-${key}`,
        };
      }),
    };
  }
);

export const selectCheckFluxAvailable = createSelector(
  (state) => state.flux.available,
  (available) => available
);

export const selectCheckFluxLoading = createSelector(
  (state) => state.flux.loading,
  (loading) => loading
);

export const selectRemittersWithRoles = createSelector(
  (state) => [state.flux.clients, state.users.roles],
  ([remitters, roles]) => {
    return remitters.map((remitter) => ({
      ...remitter,
      value: remitter.id,
      title: remitter.name,
      children: roles.map((role) => ({
        value: `${remitter.id}_${role.id}`,
        title: role.role,
        titleInSelect: `${remitter.name} / ${role.role}`,
      })),
    }));
  }
);
