import React from "react";
import { Button, Form, Image, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./style.css";
import LogoSealogis from "../../../assets/images/logo-sealogis.png";
import { t } from "assets";

export default ({ loginRequest }) => {
  const onFinish = (values) => {
    const { username, password } = values;
    loginRequest(username, password);
  };

  return (
    <section id="allForm">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Image preview={false} src={LogoSealogis} />
        <br />
        <br />
        <h1>
          {t("title_login1")}
          <br />
          {t("title_login2")}
        </h1>
        <Form.Item
          name="username"
          rules={[{ required: true, message: t("empty_username") }]}
        >
          <Input
            id={"username"}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("username")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("empty_password") }]}
        >
          <Input
            id={"password"}
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("password")}
          />
        </Form.Item>

        <Form.Item>
          <Button
            id="submit"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};
