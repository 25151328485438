import { call, put, takeLatest } from "redux-saga/effects";
import { FileActions, FileTypes } from "./index";
import { FilesService } from "../../services";
import { showError, showSuccess } from "../../utils/notifications-helper";

function* fetchAdminFiles() {
  const [error, response] = yield call(FilesService.getAdminFile);
  if (response) {
    let cgu = response.admin_file.find((file) => file.cgu)?.cgu;
    let privacy_policy = response.admin_file.find(
      (file) => file.privacy_policy
    )?.privacy_policy;
    yield put(
      FileActions.fetchAdminFilesSuccess({
        cgu,
        privacy_policy,
      })
    );
  } else if (error) {
    showError("files.errors.adminFiles");
  }
}

function* fetchSignedUrl({ location, dataKey, callbacks }) {
  const [error, response] = yield call(FilesService.getSignedUrl, location);
  if (error) {
    showError("files.errors.signedUrl");
  } else {
    const { success } = callbacks;
    const { data: url } = response;
    showSuccess("files.success.signedUrl");
    success(url);
    //yield put(FileActions.fetchSignedURLSuccess({ url, dataKey }));
    // UNCOMMENT PREVIOUS LINE IF WE WANT TO DO SOME CHECKING URL BEFORE ASKING ANOTHER ONE
  }
}

export default [
  takeLatest(FileTypes.FETCH_ADMIN_FILES, fetchAdminFiles),
  takeLatest(FileTypes.FETCH_SIGNED_URL, fetchSignedUrl),
];
