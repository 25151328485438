import { push } from "connected-react-router";
import { connect } from "react-redux";

import SideMenuImage from "./SideMenuImage";

const mapStateToProps = ({ locale, currentUser }) => ({});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuImage);
