import { gql } from "@apollo/client";

export default {
  partners: () => gql`
    query getPartners {
      countries {
        id
        country_name
      }

      partner_type {
        code
        name
      }

      partners(where: { edi_code: { _neq: "BYPASS_FOR_DELIVERY" } }) {
        id
        sealogis_code
        edi_code
        name
        teaches
        image
        siren
        invoice_siren
        specificity1
        specificity2
        specificity3
        specificity4
        specificity5
        day_open_site
        day_open_office
        hourly_open_site
        hourly_open_office
        contact_id
        address_id
        contact_two_id
        invoice_address_id
        partner_type {
          name
          code
        }
        contact {
          name
          email
          tel
          function
        }
        address {
          addr1
          addr2
          addr3
          city
          zip_code
          country {
            country_code
            country_name
          }
        }
        contact_two {
          name
          email
          tel
          function
        }
        company_invoiced
        invoice_address {
          addr1
          addr2
          addr3
          city
          zip_code
          country {
            country_code
            country_name
          }
        }
      }
    }
  `,
  partner: () => gql`
    query getPartner($id: Int) {
      partner_type {
        name
        code
      }
      partners(where: { id: { _eq: $id } }) {
        id
        sealogis_code
        edi_code
        name
        type_code
        teaches
        image
        siren
        invoice_siren
        specificity1
        specificity2
        specificity3
        specificity4
        specificity5
        day_open_site
        day_open_office
        hourly_open_site
        hourly_open_office
        contact_id
        address_id
        contact_two_id
        invoice_address_id
        partner_type {
          name
          code
        }
        contact {
          email
          tel
          function
          name
        }
        contact_two {
          email
          tel
          function
          name
        }
        address {
          addr1
          addr2
          addr3
          city
          zip_code
          country {
            country_code
            country_name
          }
        }
        company_invoiced
        invoice_address {
          addr1
          addr2
          addr3
          city
          zip_code
          country {
            country_code
            country_name
          }
        }
      }
    }
  `,
  types: () => gql`
    query getPartnerTypes {
      partner_type {
        code
        name
      }
    }
  `,
};
