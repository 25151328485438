import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import { Button, Form } from "antd";
import UserForm from "./UserForm";
import { t } from "assets";
import { useSelector } from "react-redux";
import { currentWithRoles } from "../../../redux/users";

export default ({
  id,
  /*values,*/
  clients,
  flux,
  navigateTo,
  userRequest,
  userCreateRequest,
  userUpdateRequest,
  clientRequest,
  fluxRequest,
  cleanCurrent,
  emailAvailable,
  emailLoading,
  emailRequest,
  rolesRequest,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(currentWithRoles);

  useEffect(() => {
    if (id && userRequest) {
      userRequest(id);
    }
    if (fluxRequest) fluxRequest();
    if (clientRequest) clientRequest();
    if (rolesRequest) rolesRequest();
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (value) => {
    if (!values?.id && userCreateRequest) {
      userCreateRequest(value);
    } else if (userUpdateRequest) {
      const { clients, ...rest } = value;
      userUpdateRequest({ id, ...rest });
    }
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        onClick={() => navigateTo("/users")}
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
      >
        {t("cancel")}
      </Button>
      <Button
        disabled={emailLoading}
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        id="user-create-button"
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <AuthLayout
      current={"3"}
      bread={"Users:/users;" + t("gestion_user") + ":/users/add"}
    >
      <UserForm
        extra={extra}
        onValidate={onValidate}
        form={form}
        values={values}
        clients={clients}
        emailLoading={emailLoading}
        emailAvailable={emailAvailable}
        emailRequest={emailRequest}
        flux={flux}
      />
    </AuthLayout>
  );
};
