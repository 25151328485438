import RemitterArticle from "./RemitterArticle";
import { connect } from "react-redux";
import { resolveId } from "../../../utils";

const mapStateToProps = ({ router, locale }) => ({
  id: resolveId(router),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RemitterArticle);
