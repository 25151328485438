import { gql } from "@apollo/client";

export default {
  deleteFlux: () => gql`
    mutation FluxDelete($ids: [Int!]!) {
      update_flux(where: { id: { _in: $ids } }, _set: { archived: true }) {
        returning {
          id
        }
      }
    }
  `,
  createFlux: () => gql`
    mutation createFlux($fluxPayload: [flux_insert_input!]!) {
      insert_flux(objects: $fluxPayload) {
        returning {
          id
        }
      }
    }
  `,
  updateFlux: () => gql`
    mutation updateFlux($id: Int!, $fluxPayload: flux_insert_input!) {
      update_flux_by_pk(pk_columns: { id: $id }, _set: { archived: true }) {
        id
      }
      insert_flux_one(object: $fluxPayload) {
        id
      }
    }
  `,
};
