import { gql } from "@apollo/client";

export default {
  createArticleFile: () => gql`
    mutation createArticleFile(
      $articleFilePayload: [article_files_insert_input!]!
    ) {
      insert_article_files(objects: $articleFilePayload) {
        returning {
          name
          created_at
          id
        }
      }
    }
  `,
  deleteArticleFile: () => gql`
    mutation deleteArticleFile($id: Int!) {
      update_article_files(
        where: { id: { _eq: $id } }
        _set: { archived: true }
      ) {
        returning {
          id
        }
      }
    }
  `,
  createRemitterArticle: () => gql`
    mutation createRemitterArticles($object: articles_new_insert_input!) {
      insert_articles_new_one(
        object: $object
        on_conflict: {
          constraint: articles_new_pkey
          update_columns: [
            internal_code
            manufacturer_reference
            code
            ean13
            itf14
            desired_palletization
            label
            uvc_width
            uvc_length
            uvc_height
            uvc_weight_net
            uvc_weight_raw
            master_carton_width
            master_carton_length
            master_carton_height
            master_carton_weight
            uvc_by_palet
            nb_package_by_layer
            height_palet
            raw_weight_palet
            stackability
            handling_unit
            adr
            storage_class
            component
            component_code
            id_range
            id_collection
            id_main_supplier
            id_carton_weight_unity
            id_missing_packaging
            id_paletisation
            id_conversion_unit
            id_pictogram
            manufacturer_label
            uvc_cylinder_diameter
            uvc_m3
            uvc_packaging_weight_raw
            master_carton_type
            barrel_diameter
            master_carton_weight_raw
            master_carton_weight_net
            master_carton_m3
            master_carton_packaging_weight_raw
            layer_height
            total_no_palet_height
            nomenclature
            remitter_code
          ]
        }
      ) {
        id
        created_at
        updated_at
        internal_code
        manufacturer_reference
        code
        ean13
        itf14
        desired_palletization
        label
        uvc_width
        uvc_length
        uvc_height
        uvc_weight_net
        uvc_weight_raw
        master_carton_width
        master_carton_length
        master_carton_height
        master_carton_weight
        uvc_by_palet
        nb_package_by_layer
        height_palet
        raw_weight_palet
        stackability
        handling_unit
        adr
        storage_class
        component
        component_code
        id_range
        id_collection
        id_main_supplier
        id_carton_weight_unity
        id_missing_packaging
        id_paletisation
        id_conversion_unit
        id_pictogram
        manufacturer_label
        uvc_cylinder_diameter
        uvc_m3
        uvc_packaging_weight_raw
        master_carton_type
        barrel_diameter
        master_carton_weight_raw
        master_carton_weight_net
        master_carton_m3
        master_carton_packaging_weight_raw
        layer_height
        total_no_palet_height
        nomenclature
        remitter_code
      }
    }
  `,
};
