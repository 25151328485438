module.exports.fr = {
  can_not_edit: "Edition impossible",
  can_not_create: "Création impossible",
  search: "Saisissez votre recherche",
  authentication_failed: "Erreur de connexion",
  empty_username: "Entrez un nom d'utilisateur s'il vous plaît!",
  empty_password: "Entrez un mot de passe s'il vous plaît!",
  title_login1: "Interface Admin",
  title_login2: "Track And Trace",
  login: "Connexion",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  partners: "Prestataires",
  partners_failed: "Les prestataires n'ont pas pu être chargées",
  losk_packs_failed: "Les Emballages Perdus n'ont pas pu être chargées",
  partner_failed: "Le prestataire n'a pas pu être chargé",
  lost_pack_failed: "L'Emballage Perdu n'a pas pu être chargé",
  partner_create_failed: "Erreur durant la création du partenaire",
  lost_packs_create_failed: "Erreur durant la création de l'Emballage Perdu",
  lost_packs_update_failed: "Erreur durant la mise à jour de l'Emballage Perdu",
  partner_update_failed: "Erreur durant la mise à jour du partenaire",
  partner_delete_failed:
    "Suppression impossible, ce prestataire est utilisé dans des flux client existant",
  partners_delete_success: "Partenaire(s) supprimé(s) avec succès",
  lostpacks_delete_failed: "Suppression impossible pour cet Emballages Perdus",
  lostpacks_delete_success: "Emballage(s) Perdu(s) supprimé(s) avec succès",
  partners_update_success: "Partenaire mis à jour avec succès",
  lost_packs_update_success: "Emballage Perdu mis à jour avec succès",
  partners_create_success: "Partenaire créé avec succès",
  lost_packs_create_success: "Emballage Perdu créé avec succès",
  flux: "Flux",
  flux_from_update: "Ce flux dérive de la mise à jour d'un flux existant",
  add_lost_packs: "Ajouter un Emballage Perdu",
  add_partner: "Ajouter un Prestataire",
  city: "Ville",
  country: "Pays",
  type_id: "Type",
  type_code: "Type",
  type: "Type",
  family: "Famille",
  comment: "Commentaire",
  length: "Longeur en CM",
  width: "Largeur en CM",
  height: "Hauteur en CM",
  weight: "poids",
  m2: "M2",
  m3: "M3",
  m3_container_length: "M3 contenant longueur",
  m3_container_width: "M3 contenant largeur",
  m3_container_height: "M3 contenant hauteur",
  m3_container_total: "Total M3 contenant",
  m3_total: "Total M3 (support + contenant)",
  consigne: "consigne",
  gerbability: "gerbabilité",
  treatment: "traitement",
  value_80_per_120: "Equivalent 80*120",
  partners_count: "prestataire(s)",
  partners_type: "Type prestataire",
  partners_code: "Code prestataire",
  name: "Nom",
  teaches: "Enseigne",
  address1: "Adresse 1",
  address2: "Adresse 2",
  code: "Code",
  edi_code: "Code EDI",
  sealogis_code: "Code Sealogis",
  company_invoiced: "Société facturée",
  contact_function1: "Contact 1 fonction",
  contact_function2: "Contact 2 fonction",
  contact_mail1: "Contact 1 adresse mail",
  contact_mail2: "Contact 2 adresse mail",
  contact_name1: "Contact 1 nom",
  invoice_country: "Facturation Pays",
  invoice_region: "Facturation Département",
  invoice_siren: "Facturation Siren",
  invoice_zip: "Facturation Code postal",
  region: "Département",
  siren: "Siren",
  specificity1: "Spécificité 1",
  specificity2: "Spécificité 2",
  specificity3: "Spécificité 3",
  specificity4: "Spécificité 4",
  specificity5: "Spécificité 5",
  zip_code: "Code postal",
  invoice_city: "Facturation Ville",
  invoice_address2: "Facturation Adresse 2",
  invoice_address1: "Facturation Adresse 1",
  hourly_open_site: "Horaire ouverture site opérationnel",
  hourly_open_office: "Horaire ouverture bureau",
  day_open_site: "Jour ouverture site opérationnel",
  day_open_office: "Jour ouverture bureau",
  contact_tel2: "Contact 2 téléphone",
  contact_tel1: "Contact 1 téléphone",
  contact_name2: "Contact 2 nom",
  submit: "Enregistrer",
  cancel: "Annuler",
  back: "Retour",
  confirm_action: "Êtes-vous sûr ?",
  confirm_logout:
    "Vous allez être déconnecté de l'interface, voulez-vous continuer ?",
  flux_count: "flux",
  add_flux: "Ajouter un flux",
  client: "Client",
  client_code: "Client",
  remitter_code: "Remettant",
  create_date: "Date de création",
  nb_etapes: "Nombre d'étapes",
  status: "Status",
  gestion_flux: "Gestion flux",
  gestion_user: "Gestion utilisateur",
  fluxs_failed: "Les flux n'ont pas pu être chargés",
  delivered: "Livré",
  progress: "En cours",
  required_field: `Champ requis`,
  flux_delete_failed: "Erreur durant la supression",
  fluxs_delete_success: "Flux supprimé(s) avec succès",
  incorrect: "incorrect",
  steps: "Étapes",
  monday: "lundi",
  tuesday: "mardi",
  wednesday: "mercredi",
  thursday: "jeudi",
  friday: "vendredi",
  saturday: "samedi",
  sunday: "dimanche",
  pick_days: "Sélectionnez un jour",
  db_partners: "Prestataires non liés à ce flux",
  flux_partners: "Prestataires liés à ce flux",
  flux_create_failed: "Erreur durant la création du flux",
  flux_create_success: "Flux créé avec succés",
  flux_update_failed: "Erreur durant la modification du flux",
  flux_update_success: "Flux mis a jour avec succès",
  delay: "délai",
  error_delay: "Les délais de tous les prestataires doivent être remplis",
  error_no_partners:
    "Les prestataires de toutes les étapes doivent être remplis",
  error_last_step_required: "La dernière étape doit être obligatoire",
  free_notes: "Champ libre",
  open_days: "Ouvrés",
  client_failed: "Les clients n'ont pas pu être chargés",
  types_failed: "Les types de prestatires n'ont pas pu être chargés",
  wrong_image_format: "L'image doit être au format png ou jpg",
  wrong_file_format: "Le document doit être au format pdf",
  image: "Logo partenaire",
  wrong_image_size: "Le logo doit avoir une taille inférieure à 3 MB",
  image_failed: "L'image n'a pas pu être transférer",
  firstname: "Prénom",
  first_name: "Prénom",
  lastname: "Nom",
  last_name: "Nom",
  tel: "Téléphone",
  phone: "Téléphone",
  phone_number: "Téléphone",
  enterprise: "Entreprise",
  company: "Entreprise",
  email: "Email",
  users_failed: "Les utilisateurs n'ont pas pu être chargées",
  user_failed: "L'utilisateur n'a pas pu être chargé",
  user_update_failed: "Erreur durant la mise à jour de l'utilisateur",
  user_code_update_failed:
    "Erreur lors de la mise à jour des accès de l'utilisateur",
  user_update_success: "Utilisateur mis à jour avec succès",
  user_delete_failed: "Erreur durant la supression",
  user_delete_success: "Utilisateur(s) supprimé(s) avec succès",
  add_user: "Ajouter un utilisateur",
  note: "Notes libres",
  clients: "Clients",
  user_create_failed: "Erreur durant la création de l'utilisateur",
  user_create_success: "Utilisateur créé avec succès",
  email_not_available: "L'adresse email n'est pas disponible",
  email_format: "L'adresse email n'est pas au bon format",
  remitter_roles_length: "Définissez un rôle par remettant séléctionné",
  confirm_resend:
    "Êtes-vous sur de vouloir effectuer cette action ? le mot de passe sera changé",
  user_send_success: "L'email a été envoyé avec succés",
  user_send_failed: "Erreur durant l'envoi de l'email",
  user_count: "utilisateur(s)",
  admin: "Admin",
  cgu: "CGU",
  privacy_policy: "Politique de confidentialité",
  admin_file_failed: "Les documents n'ont pas pu être chargés",
  add_privacy_policy_success:
    'Le document "Politique de confidentialité" à été ajouté',
  add_cgu_success: 'Le document "CGU" à été ajouté',
  update: "Modifier",
  preview: "Visualiser",
  add_new_step: "Ajouter une nouvelle étape",
  step: "Étape",
  required: "Obligatoire",
  flux_used: "Ce flux est déjà utilisé dans un BL",
  flux_not_available: "Ce flux existe déjà, veuillez changer le nom",
  manufacturer_reference: "Référence constructeur",
  remitter_article: {
    internal_code: "Code interne EDI",
    sealogis_created_at: "Date de création",
    created_at: "Date de création",
    code: "Code",
    label: "Libellé",
    ean13: "EAN13",
    itf14: "IFT14",
    manufacturer_label: "Libellé constructeur",
    range: "Gamme",
    collection: "Collection",
    main_supplier: "Fournisseur principal",
    uvc_cylinder_diameter: "UVC diamètre cylindre en cm",
    uvc_width: "UVC largeur en cm",
    uvc_length: "UVC longueur en cm",
    uvc_height: "UVC hauteur en cm",
    uvc_m3: "UVC en m3",
    uvc_weight_net: "UVC poids net en kg",
    uvc_weight_raw: "UVC poids brut en kg",
    uvc_packaging_weight_raw: "UVC poids brut emballage en kg",
    pcb: "PCB",
    barrel_diameter: "Diamètre (Fûts) en cm",
    master_carton_width: "Master carton largeur en cm",
    master_carton_length: "Master carton longueur en cm",
    master_carton_height: "Master carton hauteur en cm",
    master_carton_m3: "Master carton M3",
    master_carton_weight: "Master carton poid en cm",
    master_carton_type: "Master carton type",
    layer_height: "Hauteur couche",
    total_no_palet_height: "Hauteur totale sans palette",
    master_carton_weight_raw: "Master carton poids brut en kg",
    master_carton_weight_net: "Master carton poids net en kg",
    master_carton_packaging_weight_raw:
      "Master carton poids brut emballage en kg",
    desired_paletisation: "Palettisation souhaitée par le client (NB /PAL/UVC)",
    uvc_by_palet: "UVC / PALETTE",
    nb_cartons_by_palet: "Nb cartons / palette",
    nb_package_by_layer: "Nb de colis / couche",
    nb_layer_by_palet: "Nb de couches / palette",
    lost_pack: "Code emballage",
    status_pallet: "Statut Palettisation",
    stackability: "Gerbabilité",
    is_pictogram: "Pictogramme",
    conversion_unit: "Unité conversion",
    conversion_handling_unit_um: "Unité manutention conversion UM",
    temperature: "T°",
    packaging: "Packaging",
    adr: "ADR",
    storage_class: "Classe stockage",
    component: "Composé",
    nomenclature: "Nomenclature",
    compound_nomencal_code: "Code nomencalature associé",
    date_maj_pcb_cdt: "Date MAJ PCB/cdt",
    no_archive: "Aucun archivage",
    data_index: "Index donnée",
    old_data: "Ancienne donnée",
  },
  user_role_ADMIN: "Admin",
  user_role_USER: "Utilisateur lecture seule",
  user_role_USERWRITE: "Utilisateur lecture & écriture",

  users: {
    users: "Utilisateurs",
    form: {
      first_name: "Prénom",
      last_name: "Nom",
      password: "Mot de passe",
      activated: "Activé",
      role: "Role",
      email: "Email",
      phone: "Téléphone",
      clients: "Remettants",
      company: "Entreprise",
      flux: "Flux",
      note: "Notes libres",
      roles_by_remitters: "Role / Remettant",
    },
    errors: {
      role_error: "",
      rights_error: "",
    },
  },
  lost_packs: {
    lost_packs: "Emballage Perdus",
  },
  clients: {
    clients: "Clients",
    name: "Nom",
    email: "Email",
    address: "Addresse",
    zipCode: "Code postal",
    city: "Ville",
    country: "Pays",
    phoneNumber: "Téléphone",
    faxNumber: "Fax",
    emailAddress: "Adresse Email",
    add_client: "Ajouter un client",
    form: {
      title: "Gestion Client",
      code: "Code",
      remitter_code: "Remettant",
      legal_form: "Forme",
      name: "Nom",
      address1: "Adresse 1",
      address2: "Adresse 2",
      zip_code: "Code postal",
      city: "Ville",
      country: "Pays",
      phone_number: "Téléphone",
      mobile_phone_number: "Téléphone mobile",
      fax: "Fax",
      email: "Adresse Email",
      party_function_code_qualifier: "party_function_code_qualifier",
      contact: "contact",
			director_note: "Mot du directeur",
			instructions: "Instructions",
			tradbrand: "Marque commercial",
			iata_code: "Code IATA",
			pudo_code: "code PUDO",
			region: "Région",
			km: "Kilomètres",
			num_tax_add: "N° taxe additionnelle",
			grouping_cod: "Code regroupement",
			contract_num: "Numéro de contrat",
			alert: "Alerte",
			tva_num: "Numéro de TVA",
			client_dst_type_code: "Type destinataire",
			tour_code: "Code tournée",
			latitude: "Latitude",
			longitude: "Longitude",
			langue: "Langue",
			delivery_mode: "Mode de livraison",
			Comments: "Commentaires",
			eori_num: "Numéro EORI",
    },
    errors: {
      clients_failed: "Les clients n'ont pas pu être chargés",
      insertion: "L'insertion a échouée",
      update: "La mise à jour a échouée",
      create: "La création du client a échoué",
      delete: "La suppression du client a échouée",
    },
    success: {
      insert: "Insertion réussie",
      update: "Mise à jour réussie",
      create: "Création du client réussie",
      delete: "Suppression du client réussie",
      create_article: "Création de du client réussie",
      delete_article: "Suppresion de du client réussie",
      update_article: "Mise à jour du client réussie",
    },
  },
  remitters: {
    remitters: "Remettants",
    companyName: "Nom société",
    address: "Addresse",
    zipCode: "Code postal",
    town: "Ville",
    country: "Pays",
    phoneNumber: "Téléphone",
    faxNumber: "Fax",
    emailAddress: "Adresse Email",
    add_remitter: "Ajouter un remettant",
    article_base: "Base article de ",
    errors: {
      remitters_failed: "Les remettants n'ont pas pu être chargés",
      insertion: "L'insertion a échouée",
      update: "La mise à jour a échouée",
      create: "La création du remettant a échoué",
      delete: "La suppression du remettant a échouée",
      countries_failed: "La liste des pays n'a pas pu être chargée",
      deleteContraintFailed:
        "Des flux sont rattachés à ce remettant, suppression impossible",
      delete_article: "La suppression de l'article a échoué",
      update_article: "La mise à jour de l'article a échoué",
      create_article: "La création de l'article a échoué",
      fetch_archived_articles:
        "Erreur lors de la récupération des articles archivés",
      link_to_role:
        "Erreur lors de la liaison du remettant aux rôles, merci de contacter le support",
    },
    success: {
      insert: "Insertion réussie",
      update: "Mise à jour réussie",
      create: "Création du remettant réussie",
      delete: "Suppression du remettant réussie",
      create_article: "Création de l'article réussie",
      delete_article: "Suppresion de l'article réussie",
      update_article: "Mise à jour de l'article réussie",
    },
    form: {
      title: "Gestion Remettant",
      comercial: "N° COMPTE",
      code: "Code EDI",
      legal_form: "Forme",
      name: "Nom société",
      address: "Adresse",
      zip_code: "Code postal",
      town: "Ville",
      country: "Pays",
      phone_number: "Téléphone",
      fax_number: "Fax",
      email: "Adresse Email",
      tva_number: "N° TVA INTRA",
      EORI_number: "N° EORI",
      identification_number: "N° SIRET",
      ape_code: "Code APE",
      wms_code: "Code WMS",
      management: {
        title: "Direction",
        name: "Nom",
        email: "Email",
        phone_number: "Tel",
      },
      sales: {
        title: "Commercial",
        name: "Nom",
        email: "Email",
        phone_number: "Tel",
      },
      accounts: {
        title: "Comptabilité",
        name: "Nom",
        email: "Email",
        phone_number: "Tel",
      },
    },
  },
  form: {
    errors: {
      integer_only: "doit être un nombre entier",
      length_ean: "Doit contenir 13 caractères",
    },
  },

  files: {
    errors: {
      adminFiles: "Les documents n'ont pas pu être chargés",
      signedUrl: "Erreur lors de la récupération de votre document",
    },
  },
  action: "Action",
  upload: "Ajouter un fichier",
  create_article_file_error: "Erreur durant l'ajout du fichier",
  create_article_file_success: "Fichier ajouté avec succès",
  delete_article_error: "Erreur durant la suppression du fichier",
  delete_article_success: "Fichier supprimé avec succès",
  get_signed_url_error: "Erreur durant le téléchargement du fichier",
  upload_excel: "Importer un fichier",
  upload_excel_error: "Erreur durant le téléchargement du fichier excel",
  upload_excel_success: "Fichier chargé avec succès",
  file_size_error: "Erreur, le fichier doit être inférieur a 4 Mo",
  yes: "Oui",
  no: "Non",
  wms_code: "Code WMS",
};
