import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import AdminForm from "./AdminForm";
import { useDispatch } from "react-redux";
import { FileActions } from "../../../redux/files";

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch) {
      dispatch(FileActions.fetchAdminFiles());
    }
  }, []);

  return (
    <AuthLayout current={"0"} bread={"Admin:/admin"}>
      <br />
      <AdminForm />
    </AuthLayout>
  );
};
