import "./sideMenuImage.css";

import React, { useEffect } from "react";

const SideMenuImage = () => {
  useEffect(() => {}, []);

  return (
    <div className="logo" style={{ color: "white", textAlign: "center" }}></div>
  );
};

export default SideMenuImage;
