import { authenticatedMutation, authenticatedQuery } from "./middleware";
import remittersQueries from "./GraphQLRequest/remittersQueries";
import remittersMutations from "./GraphQLRequest/remittersMutations";

export default {
  remitters: () => authenticatedQuery(remittersQueries.remitters()),
  remitter: (id) => authenticatedQuery(remittersQueries.remitter(), {}, { id }),
  remitterCreate: (remitterPayload) =>
    authenticatedQuery(
      remittersMutations.createRemitter(),
      {},
      remitterPayload
    ),
  remitterUpdate: (data) =>
    authenticatedQuery(remittersMutations.updateRemitter(), {}, data),
  remitterContactUpdate: (contact) =>
    authenticatedQuery(remittersMutations.updateContact(), {}, contact),
  remitterContactCreate: (contact) =>
    authenticatedQuery(remittersMutations.createContact(), {}, contact),
  remitterDelete: (ids) =>
    authenticatedQuery(remittersMutations.deleteRemitter(), {}, { ids }),
  getRoles: (codes) => authenticatedQuery(remittersQueries.getRoles, {}, codes),
  remittersWithRoles: () => authenticatedQuery(remittersQueries.withRoles),
};
