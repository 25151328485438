import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import RemittersForm from "./RemittersForm";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { selectRemitter } from "../../../redux/remitters/selector";

import "antd/es/drawer/style/css";
import "antd/lib/drawer/style/css";
import { t } from "assets";

export default ({
  id,
  cleanCurrent,
  navigateTo,
  remitterRequest,
  remitterCreateRequest,
  remittersUpdateRequest,
  loadCountries,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(selectRemitter);
  const remitter = useSelector(selectRemitter);

  useEffect(() => {
    if (id && remitterRequest) {
      remitterRequest(id);
    }
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (values) => {
    if (remitter.id) {
      values.id = remitter.id;
      remittersUpdateRequest(values);
    } else {
      remitterCreateRequest(values);
    }
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
        onClick={() => navigateTo("/remitters")}
      >
        {t("cancel")}
      </Button>
      <Button
        id="remitter-create-button"
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <>
      <AuthLayout
        current={"4"}
        bread={`Remettants:/remitters;${t(
          "remitters.form.title"
        )}:/remitters/add`}
      >
        <RemittersForm
          extra={extra}
          form={form}
          values={values}
          isModify={id !== null}
          onValidate={onValidate}
          loadCountries={loadCountries}
        />
      </AuthLayout>
    </>
  );
};
