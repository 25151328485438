import { Button, Col, Row, Space } from "antd";
import Filter from "../filter";
import { DropdownFilter } from "../index";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "assets";

export default ({
  list,
  filterList,
  cities,
  types,
  filters,
  setFilters,
  onFilter,
  onClickNewStep,
}) => {
  const handleOnFilter = (result) => {
    if (onFilter) onFilter(result);
  };

  const handleFilterClick = (key, e) => {
    setFilters({ ...filters, [key]: e || "" });
  };

  const getAttr = (key) => {
    const ret = [];
    filterList(list).forEach((item) => {
      if (key === "city") {
        const city = item?.address?.[key];
        if (city && !ret.includes(city)) ret.push(city);
      } else {
        if (item[key] && !ret.includes(item[key])) ret.push(item[key]);
      }
    });
    return ret;
  };

  return (
    <div>
      <Row justify={"space-between"}>
        <Col className={"default-space-filter"}>
          <Space size={8} wrap>
            <Filter
              list={list}
              onFilter={handleOnFilter}
              keys={["name"]}
              placeHolder={"Saisissez votre recherche"}
            />
            <DropdownFilter
              width={160}
              array={getAttr("city")}
              clientKey={"city"}
              handleFilterClick={handleFilterClick}
            />
            <DropdownFilter
              width={160}
              array={getAttr("type")}
              clientKey={"type"}
              handleFilterClick={handleFilterClick}
            />
          </Space>
        </Col>
        <Col>
          <Button
            id={"add_new_step"}
            type={"primary"}
            onClick={onClickNewStep}
            icon={<PlusOutlined />}
          >
            {t("add_new_step")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
