import { createSelector } from "reselect";

export const selectLostPack = createSelector(
  (state) => state.lostPacks.current,
  (current) => {
    return { ...current };
  }
);

export const selectLostPackFamilies = createSelector(
  (state) => state.lostPacks.families,
  (families) => {
    return families;
  }
);

export const allLostPackCodes = createSelector(
  (state) => state.lostPacks.lostPacks,
  (lostPacks) => lostPacks
);
