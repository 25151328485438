import React from "react";
import { DatePicker as AntDDatePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";

export const DatePicker = ({ format, ...props }) => {
  const handleChange = (date) => {
    props.onChange(date);
  };
  return (
    <AntDDatePicker
      {...props}
      locale={locale}
      format={format || "DD/MM/YYYY"}
      onChange={handleChange}
    />
  );
};
