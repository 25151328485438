import { Draggable } from "react-beautiful-dnd";
import React from "react";
import DndCard from "./DndCard";

export default ({
  id,
  index,
  item,
  onDelayChange,
  onOpenChange,
  onRequiredChange,
  removeFromFlux,
  lengthPartners,
}) => {
  return (
    <Draggable
      key={`subdrag-${id}-${index}`}
      draggableId={`subdrag-${id}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <DndCard
          id={id}
          lengthPartners={lengthPartners}
          provided={provided}
          snapshot={snapshot}
          item={item}
          index={index}
          onDelayChange={onDelayChange}
          onOpenChange={onOpenChange}
          onRequiredChange={onRequiredChange}
          removeFromFlux={removeFromFlux}
        />
      )}
    </Draggable>
  );
};
