import Login from "./Login";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { AuthActions } from "../../../redux/auth";

const mapStateToProps = ({ locale }) => ({});

const mapDispatchToProps = dispatch => ({
  navigateTo: (path, param) => dispatch(push(path, {param})),
  loginRequest: (username, password) => dispatch(AuthActions.loginRequest(username, password)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
