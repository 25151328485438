import React, { useEffect } from "react";
import { Affix, Card, Form, Input } from "antd";
import "./style.css";
import { SelectForm, Upload, WeekDayPicker } from "../../components";
import { requiredRules } from "../../../utils/form-helper";
import { selectImgSignedUrl } from "../../../redux/partners";
import {
  validatorEmail,
  validatorPhone,
} from "../../../utils/validator-helper";
import { allCountries } from "../../../redux/countries/selectors";
import { useSelector } from "react-redux";
import { t } from "assets";

import "antd/lib/form/style/css";

export default ({ onValidate, values, form, types, loadCountries, extra }) => {
  const countries = useSelector(allCountries);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
    if (countries.length === 0) {
      if (loadCountries) loadCountries();
    }
  }, [values]);

  const formatCity = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };

  const onFinish = (values) => {
    if (onValidate)
      onValidate({ ...values /*city: formatCity(values?.city)*/ });
  };

  const fields = [
    { key: "type_code", required: true, component: "select" },
    { key: "sealogis_code", required: true },
    { key: "edi_code", required: true },
    { key: "name", required: true },
    { key: "teaches" },
    { key: "address1", nest: ["address", "addr1"] },
    { key: "address2", nest: ["address", "addr2"] },
    { key: "zip_code", nest: ["address", "zip_code"] },
    { key: "city", nest: ["address", "city"] },
    {
      key: "country",
      nest: ["address", "country_code"],
      component: "countryPicker",
    },
    { key: "siren" },
    { key: "contact_name1", nest: ["contact", "name"] },
    {
      key: "contact_mail1",
      nest: ["contact", "email"],
      validator: validatorEmail,
    },
    {
      key: "contact_tel1",
      nest: ["contact", "tel"],
      validator: validatorPhone,
    },
    { key: "contact_name2", nest: ["contact_two", "name"] },
    {
      key: "contact_mail2",
      nest: ["contact_two", "email"],
      validator: validatorEmail,
    },
    {
      key: "contact_tel2",
      nest: ["contact_two", "tel"],
      validator: validatorPhone,
    },
    { key: "hourly_open_office" },
    { key: "day_open_office", component: "dayPicker" },
    { key: "hourly_open_site" },
    { key: "day_open_site", component: "dayPicker" },
    { key: "specificity1" },
    { key: "specificity2" },
    { key: "specificity3" },
    { key: "specificity4" },
    { key: "specificity5" },
    { key: "company_invoiced" },
    { key: "invoice_address1", nest: ["invoice_address", "addr1"] },
    { key: "invoice_address2", nest: ["invoice_address", "addr2"] },
    { key: "invoice_zip", nest: ["invoice_address", "zip_code"] },
    { key: "invoice_city", nest: ["invoice_address", "city"] },
    {
      key: "invoice_country",
      nest: ["invoice_address", "country_code"],
      component: "countryPicker",
    },
    { key: "invoice_siren" },
    {
      key: "image",
      component: "upload",
      select: selectImgSignedUrl,
      validateTrigger: "onSubmit",
      validator: (item) => {
        return item !== "false";
      },
    },
  ];

  const onFinishFailed = ({ errorFields }) => {
    const names = errorFields[0]?.name;
    const id = names[names.length - 1];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const components = {
    dayPicker: (item) => <WeekDayPicker id={item.key} />,
    select: () => <SelectForm array={types} />,
    countryPicker: (item) => (
      <SelectForm
        id={item.key}
        array={countries}
        codeKey={"country_code"}
        valueKey={"country_name"}
      />
    ),
    input: (item) => (
      <Input id={item.key} placeholder={t(item.key)} maxLength={50} />
    ),
    upload: (item) => (
      <Upload
        select={item.select}
        item={item}
        allowTypes={["image/jpeg", "image/png", "image/heic"]}
        errorMessageType={"wrong_image_format"}
        accept={".jpg, .jpeg, .png"}
        onOvh={true}
      />
    ),
  };

  return (
    <Card bodyStyle={{ padding: 0 }} className={"card-partner-create"}>
      <div className={"ant-card-body"}>
        <Form
          onFinishFailed={onFinishFailed}
          initialValues={values}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map((item) => (
            <div id={`${item.key}-form`}>
              <Form.Item
                name={item.nest ?? item.key}
                validateTrigger={item.validateTrigger || "onChange"}
                label={t(item.key)}
                rules={[
                  requiredRules(item),
                  () => ({
                    validator(rule, value) {
                      if (item.validator && !item.validator(value))
                        return Promise.reject(`${t(item.key)} incorrect`);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[item.component || "input"](item)}
              </Form.Item>
            </div>
          ))}
        </Form>
      </div>
      <Affix offsetBottom={0}>{extra}</Affix>
    </Card>
  );
};
