import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  partners: null,
  current: null,
  cities: [],
  countries: [],
  types: [],
  typeNames: [],
  imgUrl: null,
  signedUrl: null,
};

const cleanImageRequest = (state = initialState, action) => {
  return { ...state, imgUrl: null };
};

const imageSuccess = (state = initialState, action) => {
  const { name } = action;

  const imgUrl = `${window.env.REACT_APP_STORAGE_URL}/${name}`;

  return { ...state, imgUrl };
};

const imageOnOvhSuccess = (state = initialState, action) => {
  const { name } = action;
  const imgUrl = `${name.location}`;
  const signedUrl = `${name.signedUrl}`;

  return { ...state, imgUrl, signedUrl };
};

const PartnersSuccess = (state = initialState, action) => {
  const { list } = action;
  const cities = [];
  const countries = list.countries;
  const types = list.partner_type;

  /*list.partners.map((item) => {
    if (item.address && cities.indexOf(item.address.city) === -1) cities.push(item.address.city);
    if (item.address && countries.indexOf(item.address.country.country_code) === -1) countries.push(item.address.country.country_code);
  });*/

  const partners = list.partners
    .map((item) => ({
      ...item,
      type: item.partner_type.name,
    }))
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

  return { ...state, partners, countries, cities, types };
};

const PartnerSuccess = (state = initialState, action) => {
  const partner = action.partner.partners[0];

  const current = { ...partner, type: partner.partner_type.name };
  return { ...state, current };
};

const partnersDeleteSuccess = (state = initialState, action) => {
  const { partners } = state;
  const partners_ids = action.partnersIds.map((item) => item.id);
  const tmp = partners?.filter((item) => !partners_ids.includes(item.id));
  return { ...state, partners: tmp };
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

const typesSuccess = (state = initialState, action) => {
  const { list } = action;

  const typeNames = list.map(({ name, id }) => {
    return name;
  });
  const types = list;
  return { ...state, types, typeNames };
};

export default createReducer(initialState, {
  [types.PARTNERS_SUCCESS]: PartnersSuccess,
  [types.PARTNER_SUCCESS]: PartnerSuccess,
  [types.PARTNER_DELETE_SUCCESS]: partnersDeleteSuccess,
  [types.CLEAN_CURRENT]: cleanCurrent,
  [types.TYPES_SUCCESS]: typesSuccess,
  [types.IMAGE_SUCCESS]: imageSuccess,
  [types.IMAGE_OVH_SUCCESS]: imageOnOvhSuccess,
  [types.CLEAN_IMAGE_REQUEST]: cleanImageRequest,
});
