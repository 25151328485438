import { t } from "assets";

export const requiredRules = ({ required, ...item }) => {
  let ret = {
    message: t("required_field"),
    required,
  };
  if (!item.component) ret = { ...ret, whitespace: true };
  return ret;
};
