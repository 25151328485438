import { push } from "connected-react-router";
import { connect } from "react-redux";

import SideMenu from "./SideMenu";

const mapStateToProps = ({ locale, router }) => ({
  currentLocation: router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
