import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton } from "../../components/button";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import ModifyButton from "../../components/button/ModifyButton";
import Filter from "../../components/filter";
import { DataTable, DropdownFilter } from "../../components";
import { allCountries } from "../../../redux/countries/selectors";
import { useSelector } from "react-redux";
import "./partners.css";
import { t } from "assets";

import "antd/lib/style/css";
import "antd/lib/input/style/css";

const { Text } = Typography;

export default ({
  partnersRequest,
  typesRequest,
  partnersDeleteRequest,
  navigateTo,
  list,
  cities,
  types,
  countriesRequest,
}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ city: "", type: "", country: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const countries = useSelector(allCountries);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
      sorter: (a, b) => a.id > b.id,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
      width: 200,
      sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : 0),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Code",
      dataIndex: "sealogis_code",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        a.sealogis_code ? a.sealogis_code.localeCompare(b.sealogis_code) : 0,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Nom",
      dataIndex: "name",
      align: "center",
      width: 200,
      sorter: (a, b) => (a.name ? a.name.localeCompare(b.name) : 0),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Enseigne",
      dataIndex: "teaches",
      align: "center",
      width: 200,
      sorter: (a, b) => (a.teaches ? a.teaches.localeCompare(b.teaches) : 0),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Ville",
      dataIndex: "address",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        a.address?.city ? a.city.localeCompare(b.address.city) : 0,
      render: (val, row) => {
        return <span>{val?.city}</span>;
      },
    },
    {
      title: "Pays",
      dataIndex: "address",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        a.country?.country_name
          ? a.country?.country_name?.localeCompare(b.country?.country_name)
          : 0,
      render: (val) => <span>{val?.country?.country_name}</span>,
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      align: "center",
      width: 120,
      render: (item) => {
        return (
          <Space>
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (partnersRequest) partnersRequest();
    if (typesRequest) typesRequest();
    if (countries.length === 0) {
      if (countriesRequest) countriesRequest();
    }
  }, []);

  useEffect(() => {
    setValueList(list);
  }, [list]);

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const onCreate = () => {
    navigateTo("/partners/add");
  };

  const onModify = (center) => {
    navigateTo(`/partners/${center.id}`);
  };

  const handleFilterClick = (key, e) => {
    setFilters({ ...filters, [key]: e || "" });
  };

  const filterList = (list) => {
    return list.filter((item) => {
      return (
        (filters.city.length === 0 || item.address.city === filters.city) &&
        (filters.type.length === 0 ||
          item.partner_type.code === filters.type) &&
        (filters.country.length === 0 ||
          item.address?.country.country_code === filters.country)
      );
    });
  };

  const filteredList = filterList(valueList);

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      partnersDeleteRequest(user);
    } else {
      partnersDeleteRequest([user.id]);
    }
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t(
            "partners_count"
          )}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  const getAttr = (key) => {
    const ret = [];
    filteredList.forEach((item) => {
      if (item[key] && !ret.includes(item[key])) ret.push(item[key]);
    });
    return ret;
  };

  return (
    <AuthLayout current={"1"} bread={"Prestataires:/partners"}>
      <Row id={"filters-div"} justify={"space-between"} className={"margin-vertical"}>
        <Col className={"default-space-filter"}>
          <Space size={8} wrap>
            <Filter
              list={list}
              onFilter={onFilter}
              keys={["name"]}
              placeHolder={"Saisissez votre recherche"}
            />
            <DropdownFilter
              width={150}
              array={cities}
              clientKey={"city"}
              itemKeyName={"name"}
              itemKeyValue={"code"}
              handleFilterClick={handleFilterClick}
            />
            <DropdownFilter
              width={150}
              array={countries}
              clientKey={"country"}
              itemKeyName={"country_name"}
              itemKeyValue={"country_code"}
              handleFilterClick={handleFilterClick}
            />
            <DropdownFilter
              width={150}
              array={types}
              clientKey={"type"}
              itemKeyName={"name"}
              itemKeyValue={"code"}
              handleFilterClick={handleFilterClick}
            />
          </Space>
        </Col>
        <Col className={"default-space-filter"}>
          <Button
            id={"add_partner"}
            key={"create-partner"}
            type="primary"
            onClick={onCreate}
          >
            {t("add_partner")}
          </Button>
        </Col>
      </Row>
      <DataTable
        items={filteredList}
        reset={reset}
        itemClick={onModify}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
