import { connect } from "react-redux";
import { push } from "connected-react-router";
import { resolveId } from "../../../utils";
import UserCreate from "./UserCreate";
import { UsersActions } from "../../../redux/users";
import { FluxActions } from "../../../redux/flux";
import { RemittersActions } from "../../../redux/remitters";

const mapStateToProps = ({ locale, router, users, flux }) => ({
  id: resolveId(router),
  /*values: users.current,*/
  clients: flux.clients,
  flux: flux.fluxs,
  emailAvailable: users.available,
  emailLoading: users.loading,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  userRequest: (id) => dispatch(UsersActions.userRequest(id)),
  userCreateRequest: (user) => dispatch(UsersActions.usersCreateRequest(user)),
  userUpdateRequest: (user) => dispatch(UsersActions.usersUpdateRequest(user)),
  /*clientRequest: () => dispatch(FluxActions.clientRequest()),*/
  clientRequest: () => dispatch(RemittersActions.remittersWithRoleRequest()),
  fluxRequest: () => dispatch(FluxActions.fluxsRequest({})),
  cleanCurrent: () => dispatch(UsersActions.cleanCurrent()),
  emailRequest: (value) => dispatch(UsersActions.emailRequest(value)),
  rolesRequest: (value) => dispatch(UsersActions.roleRequest(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
