import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import React from "react";
import { t } from "assets";

export default ({
  provided,
  snapshot,
  item,
  index,
  removeFromFlux,
  children,
  onClickExpand,
  onRequiredChange,
}) => {
  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={
          snapshot.isDragging ? "draggable_item_dragging" : "draggable_item"
        }
        style={{
          ...provided.draggableProps.style,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className={"row-between"}>
          <div className="info-container-step">
            {item.expand ? (
              <UpOutlined onClick={() => onClickExpand(item, false)} />
            ) : (
              <DownOutlined onClick={() => onClickExpand(item, true)} />
            )}
            {`  ${t("step")} ${index + 1}`}
            {onRequiredChange && (
              <>
                <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
                  {t("required")}
                </span>
                <Switch
                  checked={item.required}
                  onChange={(value) => onRequiredChange(item.key, value)}
                  size="small"
                  style={{
                    marginLeft: "5px",
                  }}
                />
              </>
            )}
          </div>
          <div className="close-container">
            {removeFromFlux && (
              <CloseOutlined
                className="close-button"
                onClick={() => removeFromFlux("flux", item)}
              />
            )}
          </div>
        </div>
        <div className="title-partner-step">
          {`${
            item?.steps_partners?.length ? item.steps_partners.length : 0
          } ${t("partners")} ${item.expand ? ":" : ""}`}
        </div>
        {item.expand && children}
      </div>
    </>
  );
};
