import { gql } from "@apollo/client";

export default {
  users: () => gql`
    query getUsers {
      users(order_by: {id: desc}) {
        email
        company
        first_name
        last_name
        id
        phone
        note
        activated
      }
    }
  `,
  user: () => gql`
    query getUser($id: Int) {
      users(where: { id: { _eq: $id } }) {
        email
        company
        first_name
        last_name
        id
        phone
        note
        activated
        bcu_code
        user_role {
          role
        }
        role_sealogis_remitters_users {
          role_sealogis_remitter {
            id
            id_sealogis_remitter
            user_role {
              role
            }
            sealogis_remitter {
              code
            }
          }
        }
        user_fluxs {
          id_flux
          id
          flux {
            client_code
            name
            sealogis_remitter {
              id
              name
            }
            archived
          }
        }
      }
    }
  `,
  roles: () => gql`
    query getRole {
      user_roles {
        id
        role
      }
    }
  `,
};
