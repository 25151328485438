import { gql } from "@apollo/client";

export default {
  lostPacks: () => gql`
    query getLostPacks($code: String) {
      lost_packaging_families {
        code
        label
      }

      lost_packagings(where: { code: { _regex: $code } }) {
        id
        code
        family
        type
        comment
      }
    }
  `,
  lostPackById: () => gql`
    query getLostPack($id: Int) {
      lost_packaging_families {
        code
        label
      }

      lost_packagings(where: { id: { _eq: $id } }) {
        code
        comment
        consigne
        created_at
        family
        gerbability
        height
        id
        length
        m2
        m3
        m3_container_height
        m3_container_length
        m3_container_total
        m3_container_width
        m3_total
        treatment
        type
        updated_at
        value_80_per_120
        weight
        width
      }
    }
  `,
  types: () => gql`
    query getPartnerTypes {
      partner_type {
        code
        name
      }
    }
  `,
  families: () => gql`
    query lostPacksFamilies {
      lost_packaging_families {
        code
        label
      }
    }
  `,
};
