import React, { useEffect, useState } from "react";
import { Affix, Card, Form, Input } from "antd";
import DragAndDrop from "../../components/dragAndDrop";
import { CustomSelect } from "../../components";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCheckFluxAvailable,
  selectCheckFluxLoading,
} from "../../../redux/flux/selector";
import FormRow from "./FormRow";
import FormItem from "./FormItem";

import "antd/lib/style/css";
import "antd/lib/form/style/css";
import { t } from "assets";

const { TextArea } = Input;

export default ({
  extra,
  onValidate,
  values,
  form,
  partners,
  types,
  cities,
  clients
}) => {
  const [available, setAvailable] = useState(true)
  const [errors, setErrors] = useState([])
  const checkFluxAvailable = useSelector(selectCheckFluxAvailable)
  const checkFluxLoading = useSelector(selectCheckFluxLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  const onFinish = (values) => {
    if (onValidate) onValidate(values);
  };


  const components = {
    dnd: ({key}) => (
      <DragAndDrop
        isError={isError}
        errors={errors}
        id={key}
        partners={partners}
        cities={cities}
        types={types}
      />
    ),
    textarea: ({key}) => <TextArea id={key} placeholder={`${t(key)}`} />,
    input: ({key, args}) => (
      <Input id={key} placeholder={`${t(key)}`} maxLength={50} suffix={args ? <LoadingOutlined /> : <span />}/>
    ),
    select: ({key}) => <CustomSelect array={clients} id={key} />,
  };

  useEffect(() => {
    if (checkFluxAvailable !== available) {
      form.validateFields(["name"]);
    }
    setAvailable(checkFluxAvailable);
  }, [checkFluxAvailable]);

  const fields = [
    { row: [
        { key: "name", required: true,
          args: checkFluxLoading,
          errorCheck: t("flux_not_available"),
          validator: async (_, getFieldValue) => {
            const {name, client} = getFieldValue()
            if (name && client) {
            //  dispatch(FluxActions.checkFluxRequest({name, client}));
            }
            return true;
          }
        },
        { key: "client_code", required: true, component: "select",
          validator: async (_, getFieldValue) => {
            const {name, client} = getFieldValue()
            if (name && client) {
              //dispatch(FluxActions.checkFluxRequest({name, client}));
            }
            return true;
          } }
      ]
    },
    { key: "free_notes", component: "textarea" },
    {
      key: "steps",
      required: true,
      noStyle: true,
      component: "dnd",
      error: t("error_delay"),
      validateTrigger: "onSubmit",
      validator: (item) => {
        if (!item) return false
        const noPartner = item.some((i) => i.steps_partners.length === 0)
        const isDelay = item.some((i) => i.steps_partners.some((p) => isNaN(p.delay) || p.delay === null || p.delay?.length === 0))
        const last = item[item.length - 1]
        const res = []
        if (noPartner)
          res.push(t("error_no_partners"))
        if (isDelay)
          res.push(t("error_delay"))
        if (!last.required)
          res.push(t("error_last_step_required"))
        setErrors(res)
        return res.length === 0
      },
    },
  ];

  const [isError, setError] = useState(false);

  const onFinishFailed = ({ errorFields }) => {
    const id = errorFields[0]?.name[0];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
    const err = errorFields.find((error) => error.name && error.name[0] === "steps")
    setError(!!err);
  };

  return (
    <Card bodyStyle={{padding: 0}} className={"card-flux-create"}>
      <div className={"ant-card-body"}>
        <Form
          initialValues={values}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map((item) => {
            if ("row" in item) {
              return <FormRow row={item.row} checkFluxAvailable={checkFluxAvailable} values={values} components={components}/>
            } else {
              return <FormItem checkFluxAvailable={checkFluxAvailable} values={values} components={components} item={item}/>
            }
          })}
        </Form>
      </div>
      <Affix offsetBottom={0}>
        {extra}
      </Affix>
    </Card>
  );
};
