import { createReducer } from "reduxsauce";
import { FileTypes } from "./index";

const initialState = {
  cgu: null,
  privacy_policy: null,
};

const fetchAdminFilesSuccess = (state = initialState, action) => {
  const { cgu, privacy_policy } = action;
  return {
    ...state,
    cgu,
    privacy_policy,
  };
};

const fetchSignedURLSuccess = (state = initialState, action) => {
  const { url, dataKey } = action;

  return {
    ...state,
    [dataKey]: url,
  };
};

export default createReducer(initialState, {
  [FileTypes.FETCH_ADMIN_FILES_SUCCESS]: fetchAdminFilesSuccess,
  [FileTypes.FETCH_SIGNED_URL_SUCCESS]: fetchSignedURLSuccess,
});
