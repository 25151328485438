import React, { useEffect } from "react";
import { Affix, Card, Form, Input, InputNumber } from "antd";
import "./style.css";
import { SelectForm, Upload, WeekDayPicker } from "../../components";
import { requiredRules } from "../../../utils/form-helper";
import { selectLostPackFamilies } from "../../../redux/lostPacks/selector";
import { useSelector } from "react-redux";

import "antd/lib/form/style/css";
import "antd/lib/input/style/css";
import "antd/lib/input-number/style/css";
import { BasicComponents } from "../../components/basic";
import { t } from "assets";

export default ({ extra, onValidate, values, form, types }) => {
  const families = useSelector(selectLostPackFamilies);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  const onFinish = (values) => {
    if (onValidate)
      onValidate({ ...values /*city: formatCity(values?.city)*/ });
  };

  const fields = [
    { key: "code", required: true },
    { key: "family", required: true, component: "familyPicker" },
    { key: "type", required: true },
    { key: "comment", required: true },
    { key: "length", required: true, component: "inputNumber" },
    { key: "width", required: true, component: "inputNumber" },
    { key: "height", required: true, component: "inputNumber" },
    { key: "m2", required: true, component: "inputNumber" },
    { key: "m3", required: true, component: "inputNumber" },
    { key: "m3_container_length", required: true, component: "inputNumber" },
    { key: "m3_container_height", required: true, component: "inputNumber" },
    { key: "m3_container_width", required: true, component: "inputNumber" },
    { key: "m3_container_total", required: true, component: "inputNumber" },
    { key: "m3_total", required: true, component: "inputNumber" },
    { key: "weight", required: true, component: "inputNumber" },
    { key: "consigne", required: false, component: "switchInput" },
    { key: "gerbability", required: false, component: "switchInput" },
    { key: "treatment", required: true },
    { key: "value_80_per_120", required: true, component: "inputNumber" },
  ];

  const onFinishFailed = ({ errorFields }) => {
    const names = errorFields[0]?.name;
    const id = names[names.length - 1];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const components = {
    switchInput: (item) => <BasicComponents.Switch id={item.key} />,
    dayPicker: (item) => <WeekDayPicker id={item.key} />,
    select: () => <SelectForm array={types} />,
    familyPicker: (item) => (
      <SelectForm array={families} codeKey={"code"} valueKey={"label"} />
    ),
    inputNumber: (item) => <InputNumber id={item.key} controls={true} />,
    input: (item) => (
      <Input id={item.key} placeholder={`${t(item.key)}`} maxLength={50} />
    ),
    upload: (item) => (
      <Upload
        select={item.select}
        item={item}
        allowTypes={["image/jpeg", "image/png", "image/heic"]}
        errorMessageType={"wrong_image_format"}
        accept={".jpg, .jpeg, .png"}
        onOvh={true}
      />
    ),
  };

  return (
    <Card bodyStyle={{ padding: 0 }} className={"card-lost-packaging-create"}>
      <div className="ant-card-body">
        <Form
          onFinishFailed={onFinishFailed}
          initialValues={values}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map((item) => (
            <div id={`${item.key}-form`}>
              <Form.Item
                name={item.nest ?? item.key}
                validateTrigger={item.validateTrigger || "onChange"}
                label={t(item.key)}
                rules={[
                  requiredRules(item),
                  () => ({
                    validator(rule, value) {
                      if (item.validator && !item.validator(value))
                        return Promise.reject(`${t(item.key)} incorrect`);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[item.component || "input"](item)}
              </Form.Item>
            </div>
          ))}
        </Form>
      </div>
      <Affix offsetBottom={0}>{extra}</Affix>
    </Card>
  );
};
