import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton } from "../../components/button";
import { Divider, Typography } from "antd";
import ModifyButton from "../../components/button/ModifyButton";
import { DataTable } from "../../components";
import "./remitters.css";
import { sortColumn, types } from "../../../utils";
import ListPageFilters from "../../components/listPageFilters";
import { BasicComponents, BUTTON_TYPES } from "../../components/basic";
import { ROUTES } from "../../../core/routes";
import { t } from "assets";

const { Text } = Typography;

export default ({
  remittersRequest,
  typesRequest,
  remittersDeleteRequest,
  navigateTo,
  list,
  countriesRequest,
}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ city: "", type: "", country: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
      sorter: (a, b) => sortColumn(a.id, b.id, types.INT),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.companyName"),
      dataIndex: "name",
      align: "center",
      width: 250,
      sorter: (a, b) => sortColumn(a.name, b.name, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.address"),
      dataIndex: "address",
      align: "center",
      width: 320,
      sorter: (a, b) => sortColumn(a.address, b.address, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.zipCode"),
      dataIndex: "zip_code",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.zip_code, b.zip_code, types.INT),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.town"),
      dataIndex: "town",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.town, b.town, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.country"),
      dataIndex: "country",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        sortColumn(
          a.country?.country_name,
          b.country?.country_name,
          types.STRING
        ),
      render: (text) => <span>{text?.country_name}</span>,
    },
    {
      title: t("remitters.phoneNumber"),
      dataIndex: "phone_number",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        sortColumn(a.phone_number, b.phone_number, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.faxNumber"),
      dataIndex: "fax_number",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a.fax_number, b.fax_number, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("remitters.emailAddress"),
      dataIndex: "email",
      align: "center",
      width: 320,
      sorter: (a, b) => sortColumn(a.email, b.email, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      width: 150,
      align: "center",
      fixed: 'right',
      render: (item) => {
        return (
          <span id="last-column-remitter">
            <BasicComponents.Button
              id={`remitter-base-article-${item.id}`}
              buttonType={BUTTON_TYPES.ARTICLE}
              onClick={() => onNavigateToArticleBase(item)}
            />
            <Divider type="vertical" />
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (remittersRequest) remittersRequest();
    if (typesRequest) typesRequest();
    if (countriesRequest) countriesRequest();
  }, []);

  useEffect(() => {
    setValueList(list);
  }, [list]);

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const onCreate = () => {
    navigateTo("/remitters/add");
  };

  const onModify = (center) => {
    navigateTo(`/remitters/${center.id}`);
  };

  const onNavigateToArticleBase = (item) => {
    navigateTo(ROUTES.REMITTER_ARTICLES.replace(":id", item.id));
  };

  const handleFilterClick = (key, e) => {
    setFilters({ ...filters, [key]: e || "" });
  };

  const filterList = (list) => {
    return list.filter((item) => {
      return (
        (filters.city.length === 0 || item.city === filters.city) &&
        (filters.type.length === 0 || item.type === filters.type) &&
        (filters.country.length === 0 || item.country === filters.country)
      );
    });
  };

  const filteredList = filterList(valueList);

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      remittersDeleteRequest(user);
    } else {
      remittersDeleteRequest([user.id]);
    }
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t(
            "partners_count"
          )}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  const getAttr = (key) => {
    const ret = [];
    filteredList.forEach((item) => {
      if (item[key] && !ret.includes(item[key])) ret.push(item[key]);
    });
    return ret;
  };

  return (
    <AuthLayout current={"4"} bread={"Remettants:/remitters"}>
      <ListPageFilters
        filterKeys={["name"]}
        filterPlaceholder={t("search")}
        filterList={list}
        onFilter={onFilter}
        onCreateClick={onCreate}
        buttonId="add_remitter"
        createButtonPlaceholder={t("remitters.add_remitter")}
      />
      <DataTable
        items={filteredList}
        reset={reset}
        itemClick={onModify}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
