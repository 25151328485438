import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";

import "antd/lib/popconfirm/style/css";

const ConfirmButton = ({
  id,
  title,
  confirmTitle,
  confirm,
  loading,
  type,
  shape,
  icon,
  onClick,
  onCancel,
  disabled,
  ghost,
  tooltipTitle,
  danger,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      {disabled && (
        <Tooltip title={tooltipTitle || "Suppression impossible"}>
          <Button
            id={id || "delete-button"}
            key={id}
            disabled={disabled}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            danger={danger}
          >
            {title}
          </Button>
        </Tooltip>
      )}
      {!disabled && confirm && (
        <Popconfirm
          title={confirmTitle || "Êtes-vous sûr ?"}
          okText="Oui"
          cancelText="Non"
          onConfirm={handleClick}
          onCancel={handleCancel}
        >
          <Button
            id={id || "delete-button"}
            key={id}
            disabled={disabled}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            danger={danger}
          >
            {title}
          </Button>
        </Popconfirm>
      )}
      {!disabled && !confirm && (
        <Tooltip key={id} title={tooltipTitle}>
          <Button
            id={id || "delete-button"}
            disabled={disabled}
            ghost={ghost}
            type={type}
            shape={shape}
            icon={icon}
            loading={loading}
            onClick={handleClick}
            danger={danger}
          >
            {title}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ConfirmButton;
