import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import FluxForm from "./FluxForm";
import { Button, Form } from "antd";
import { t } from "assets";
import { useSelector } from "react-redux";
import { selectCurrentFlux } from "../../../redux/flux";

export default ({
  id,
  partners,
  cities,
  clients,
  types,
  navigateTo,
  FluxRequest,
  PartnersRequest,
  typesRequest,
  ClientRequest,
  fluxCreateRequest,
  FluxUpdateRequest,
  cleanCurrent,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(selectCurrentFlux);

  useEffect(() => {
    if (id && FluxRequest) {
      FluxRequest(id);
    }
    if (ClientRequest) ClientRequest();
    if (PartnersRequest) PartnersRequest();
    if (typesRequest) typesRequest();
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (value) => {
    if (values == null && fluxCreateRequest) {
      fluxCreateRequest(value);
    }
    else if (FluxUpdateRequest) {
      FluxUpdateRequest({id, ...value, original_flux_id: values.original_flux_id || id});
    }
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        onClick={() => navigateTo("/flux")}
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
      >
        {t("cancel")}
      </Button>
      <Button
        id="add_flux"
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <AuthLayout
      current={"2"}
      bread={"Flux:/flux;" + t("gestion_flux") + ":/flux/add"}
    >
      {values?.original_flux_id ? t("flux_from_update") : ""}
      <FluxForm
        extra={extra}
        onValidate={onValidate}
        form={form}
        values={values}
        partners={partners}
        cities={cities}
        clients={clients}
        types={types}
        isModify={id !== null}
      />
    </AuthLayout>
  );
};
