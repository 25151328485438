import { encode } from "base-64";

import { methods } from "../utils";
import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
  service,
} from "./middleware";
import authQueries from "./GraphQLRequest/authQueries";
import authMutations from "./GraphQLRequest/authMutations";

const { POST, GET } = methods;
const routes = {
  login: window.env.REACT_APP_AUTH_API_URL + "/auth/authorize",
  getToken: window.env.REACT_APP_AUTH_API_URL + "/auth/token",
  getFile: window.env.REACT_APP_STORAGE_URL + "/photo",
};

export default {
  login: ({ username, password }) =>
    service(
      GET,
      routes.login.replace("undefined/", ""),
      {},
      {
        prompt: "none",
        response_type: "code",
        redirect_uri: window.env.REACT_APP_AUTH_API_URL,
        client_id: "SEALOGIS_BACKEND",
      },
      {
        Authorization: encode(username + ":" + password),
      }
    ),
  getToken: ({ username, password, code }) =>
    service(
      POST,
      routes.getToken,
      {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: window.location.origin,
      },
      {},
      {
        Authorization: encode(username + ":" + password),
      }
    ),
  refreshToken: ({ authorization, refresh_token }) =>
    service(
      POST,
      routes.getToken,
      {
        grant_type: "refresh_token",
        refresh_token: refresh_token,
        redirect_uri: window.location.origin,
      },
      {},
      {
        Authorization: authorization,
      }
    ),
  getAdminFile: () => authenticatedQuery(authQueries.adminFile()),
  getFile: (value) => authenticatedService(GET, routes.getFile, value),
  addCGU: (value) =>
    authenticatedMutation(authMutations.addAdminFile, {}, { adminFile: value }),
  addPrivacyPolicy: (value) =>
    authenticatedMutation(authMutations.addAdminFile, {}, { adminFile: value }),
};
