import React, { useMemo } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileSearchOutlined,
  FolderOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ConfirmButton from "./ConfirmButton";

export const BUTTON_TYPES = {
  SAVE: "save",
  MODIFY: "modify",
  DELETE: "delete",
  CANCEL: "cancel",
  ARTICLE: "article",
  DOWNLOAD: "download",
  DOCUMENT: "document",
  FOLDER: "folder",
};

export const Button = ({ buttonType, shouldConfirm, ...rest }) => {
  const icon = useMemo(() => {
    switch (buttonType) {
      case BUTTON_TYPES.SAVE:
        return <CheckOutlined />;
      case BUTTON_TYPES.MODIFY:
        return <SettingOutlined />;
      case BUTTON_TYPES.DELETE:
        return <DeleteOutlined />;
      case BUTTON_TYPES.CANCEL:
        return <CloseOutlined />;
      case BUTTON_TYPES.ARTICLE:
        return <UnorderedListOutlined />;
      case BUTTON_TYPES.DOWNLOAD:
        return <DownloadOutlined />;
      case BUTTON_TYPES.DOCUMENT:
        return <FileSearchOutlined />;
      case BUTTON_TYPES.FOLDER:
        return <FolderOutlined />;
      default:
        break;
    }
  }, [buttonType]);

  return (
    <ConfirmButton
      {...rest}
      type={"dashed"}
      shape={"circle"}
      confirm={shouldConfirm}
      icon={icon}
    />
  );
};
