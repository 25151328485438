import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  token: null,
  token_type: null,
  attempts: 0,
  user_blocked: false,
};

// region Reducers
const loginAttempt = (state = initialState) => {
  const { attempts } = state;
  return { ...state, attempts: attempts + 1 };
};

const loginFailed = (state = initialState) => {
  const { attempts } = state;
  return { ...state, user_blocked: attempts >= 5 };
};

const loginSuccess = (state = initialState, action) => {
  const { token, refresh_token, token_type, authorization } = action;
  return { token, refresh_token, token_type, authorization, attempts: 0 };
};

const logout = () => {
  return { ...initialState };
};
// endregion

export default createReducer(initialState, {
  [types.LOGIN_REQUEST]: loginAttempt,
  [types.LOGIN_REFRESH]: loginAttempt,
  [types.LOGOUT]: logout,
  [types.LOGIN_FAILURE]: loginFailed,
  [types.LOGIN_SUCCESS]: loginSuccess,
});
