export const validatorPhone = (item) => {
  return !item
    ? true
    : (typeof item === "number" ? item.toString() : item).match(
        /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
      );
};

export const validatorEmail = (item) => {
  return !item ? true : item.match(/\S+@\S+\.\S+/g);
};

export const validatorInteger = (item) => {
  return !item ? false : !Number.isInteger(item);
};

export const validatorEAN13 = (item) => {
  return !item ? false : item.toString().length !== 13;
};
