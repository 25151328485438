import React, { useEffect } from "react";
import {Affix, Card, Col, Form, Input, Row, Checkbox, Radio, Space} from "antd";
import "./style.css";
import { SelectForm, Upload, WeekDayPicker } from "../../components";
import { requiredRules } from "../../../utils/form-helper";

import "antd/lib/form/style/css";
import "antd/lib/radio/style/css";
import "antd/lib/button/style/css";
import { allCountries } from "../../../redux/countries/selectors";
import { useSelector } from "react-redux";
import {
  validatorEmail,
  validatorPhone,
} from "../../../utils/validator-helper";
import { t } from "assets";

export default ({ extra, onValidate, values, form, types, loadCountries, remitters }) => {
  const countries = useSelector(allCountries);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
    if (countries.length === 0) {
      if (loadCountries) loadCountries();
    }
  }, [values]);

  const formatCity = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };

  const onFinish = (values) => {
    if (onValidate) onValidate({ ...values, town: formatCity(values?.town) });
  };

  const fields = [
    { key: "remitter_code", required: true, component: "remitterPicker"},
    { key: "code", required: true },
    { key: "name", required: true },
    { key: "party_function_code_qualifier", required: true  },
    { key: "address1", nest: ["address", "addr1"] },
    { key: "address2", nest: ["address", "addr2"] },
    { key: "zip_code", nest: ["address", "zip_code"] },
    { key: "city", nest: ["address", "city"] },
    {
      key: "country",
      nest: ["address", "country"],
      component: "countryPicker",
    },
    {
      key: "phone_number",
      stringKey: "phone",
      validator: validatorPhone,
    },
    {
      key: "mobile_phone_number",
      stringKey: "phone",
      validator: validatorPhone,
    },
    { key: "fax" },
    { key: "email", validator: validatorEmail },
    { key: "contact" },
    { key: "director_note" },
    { key: "instructions" },
    { key: "tradbrand" },
    { key: "iata_code" },
    { key: "pudo_code" },
    { key: "region" },
    { key: "km" },
    { key: "num_tax_add" },
    { key: "grouping_cod" },
    { key: "contract_num" },
    { key: "alert" },
    { key: "tva_num" },
    { key: "client_dst_type_code", component: "clientDstTypePicker" },
    { key: "tour_code" },
    { key: "latitude" },
    { key: "longitude" },
    { key: "langue" },
    { key: "delivery_mode", component: "radioGroup" },
    { key: "Comments" },
    { key: "eori_num" },
  ];

  const onFinishFailed = ({ errorFields }) => {
    const names = errorFields[0]?.name;
    const id = names[names.length - 1];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      if (el) el.scrollIntoView();
    }
  };

  const components = {
    dayPicker: (item) => <WeekDayPicker id={item.key} />,
    //select: () => <SelectForm array={types} />,
    clientDstTypePicker: (item) => (
      <SelectForm
      id="country_picker"
      array={[{code: "P", name: "Particulier"}, {code: "E", name: "Entreprise"}]}
      codeKey={"code"}
      valueKey={"name"}
    />
    ),
    countryPicker: (item) => (
      <SelectForm
        id="country_picker"
        array={countries ? countries : []}
        codeKey={"country_code"}
        valueKey={"country_name"}
      />
    ),
    remitterPicker: (item) => (
      <SelectForm
        id="remitter_picker"
        array={remitters ? remitters : []}
        codeKey={"code"}
        valueKey={"name"}
      />
    ),
    input: (item) => (
      <Input
        id={item.key}
        placeholder={`${t(`clients.form.${item.key}`)}`}
        maxLength={50}
      />
    ),
    hide: (item) => (
      <Input
        className="hide"
        id={item.key}
        placeholder={`${t(`clients.form.${item.key}`)}`}
        maxLength={50}
      />
    ),
    upload: (item) => (
      <Upload
        select={item.select}
        item={item}
        allowTypes={["image/jpeg", "image/png", "image/heic"]}
        errorMessageType={"wrong_image_format"}
        accept={".jpg, .jpeg, .png"}
      />
    ),
    radioGroup: (item) => (
      <Radio.Group buttonStyle="solid"  optionType="button"  options={[
          { label: 'Aucun', value: 0 },
          { label: 'Bureau Restant', value: 1 },
          { label: 'Prendre Rdv', value: 2 },
          { label: 'Bureau Restant et Prendre Rdv', value: 3 }
        ]}
        defaultValue={0} />
    )
    ,
  };

  return (
    <div>
      <Card
        className={"card-clients-create max-width-900"}
        style={{ margin: 25, width: "80%" }}
      >
        <Form
          onFinishFailed={onFinishFailed}
          initialValues={values}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          {fields.map((item) => (
            <div id={`${item.key}-form`}>
              <Form.Item
                name={item.nest ?? item.key}
                validateTrigger={item.validateTrigger || "onChange"}
                label={t(`clients.form.${item.key}`)}
                rules={[
                  requiredRules(item),
                  () => ({
                    validator(rule, value) {
                      if (item.validator && !item.validator(value))
                        return Promise.reject(
                          `${t(item.stringKey || item.key)} ${t("incorrect")}`
                        );

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {components[item.component || "input"](item)}
              </Form.Item>
            </div>
          ))}
        </Form>
      </Card>
      <div className={"width-p-80 max-width-900"} style={{ margin: "0 auto" }}>
        <Row justify={"space-between"} wrap>
          <Col flex={1} style={{ marginRight: 3 }}>
          </Col>
          <Col flex={1} style={{ marginLeft: 3 }}>
          </Col>
        </Row>
        <Affix offsetBottom={0}>{extra}</Affix>
      </div>
    </div>
  );
};
