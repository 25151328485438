import { createReducer } from "reduxsauce";
import { types } from "./actions";
import { findIndex, omit } from "lodash";
import { dateFormat } from "../../utils/wrappers/dateWrapper";
import moment from "moment";

const initialState = {
  articles: [],
  importReports: [],
  currentArchived: [],
  // key for holding all selects articles upsert process
  allSelects: {},
};

// Reducers
const RemitterArticleDeleteSuccess = (state = initialState, action) => {
  const { articles } = state;
  const articles_ids = action.ids;
  const tmp = articles.filter((item) => !articles_ids.includes(item.id));
  return { ...state, articles: tmp };
};

const RemitterArticleCreateSuccess = (state = initialState, action) => {
  const { articles } = state;
  const article = action.article.article;

  const index = findIndex(articles, { id: article.id });

  if (index == -1) {
    articles.push(article);
  } else {
    articles[index] = article;
  }

  return { ...state, articles };
};

const RemitterArticleSuccess = (state = initialState, action) => {
  const { articles_not_archived, articles_archived } = action.list;
  const articles = articles_not_archived.map(({ files, ...item }) => {
    const archived = articles_archived.filter(({ code }) => {
      const split = code.split("_");

      return split.length === 2 ? split[0] === item.code : false;
    });
    return {
      ...item,
      archived,
      sealogis_created_at: item.sealogis_created_at
        ? moment(item.sealogis_created_at)
        : null,
      date_maj_pcb_cdt: item.date_maj_pcb_cdt
          ? moment(item.date_maj_pcb_cdt)
          : null,
      range_label: item.range?.label,
      paletisation_label: item.paletisation?.label,
      conversion_unit_label: item.conversion_unit?.label,
      packaging_type_type: item.packaging_type?.type,
      lost_packaging_code: item.lost_packaging?.code,
      files: files.map(({ created_at, ...file }) => ({
        ...file,
        created_at: dateFormat(created_at, "dd/MM/yy hh:mm"),
      })),
    };
  });
  return { ...state, articles };
};

const archivedArticleSuccess = (state = initialState, action) => {
  const { article, archived } = action;
  const keys = Object.keys(
    omit(article, ["id", "created_at", "updated_at", "archived", "files"])
  );
  let archivedDate = null;
  const differences = archived.map((archive) => {
    const ret = [];
    keys.forEach((key) => {
      const d1 = article[key];
      const d2 = archive[key];

      if (key === "code") {
        const split = d2.split("_");
        if (split.length >= 2) {
          const date = new Date(split[1]);
          archivedDate = moment(parseInt(split[1])).format("DD/MM/YYYY");
        }
      } else if (d1 !== d2) {
        ret.push(key);
      }
    });
    return {
      ...archive,
      archived_date: archivedDate,
      errorKeys: ret,
    };
  });
  return { ...state, currentArchived: differences };
};

const createArticleFileSuccess = (state = initialState, action) => {
  const {
    id,
    toAdd: { created_at, ...toAdd },
  } = action;
  const { articles: old } = state;
  const file = {
    ...toAdd,
    created_at: dateFormat(created_at, "dd/MM/yy hh:mm"),
  };
  const articles = old.map((item) => {
    const { files, ...article } = item;
    return article.id === id ? { ...article, files: [file, ...files] } : item;
  });
  return { ...state, articles };
};

const deleteArticleFileSuccess = (state = initialState, action) => {
  const { fileId, articleId } = action;
  const { articles: old } = state;
  const articles = old.map((item) => {
    const { files, ...article } = item;
    return article.id === articleId
      ? { ...article, files: files.filter(({ id }) => id !== fileId) }
      : item;
  });
  return { ...state, articles };
};

const cleanArticles = (state = initialState, action) => {
  return { ...state, articles: [] };
};

const SetImportReports = (state = initialState, action) => {
  return { ...state, importReports: action.reports };
};

const getAllRelatedArticleSelectsSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, allSelects: data };
};

export default createReducer(initialState, {
  [types.REMITTER_ARTICLE_SUCCESS]: RemitterArticleSuccess,
  [types.GET_ARCHIVED_ARTICLES_SUCCESS]: archivedArticleSuccess,
  [types.REMITTER_ARTICLE_DELETE_SUCCESS]: RemitterArticleDeleteSuccess,
  [types.REMITTER_ARTICLE_CREATE_SUCCESS]: RemitterArticleCreateSuccess,
  [types.CREATE_ARTICLE_FILE_SUCCESS]: createArticleFileSuccess,
  [types.DELETE_ARTICLE_FILE_SUCCESS]: deleteArticleFileSuccess,
  [types.CLEAN_ARTICLES]: cleanArticles,
  [types.SET_IMPORT_REPORTS]: SetImportReports,
  [types.GET_ALL_RELATED_ARTICLE_SELECTS_SUCCESS]:
    getAllRelatedArticleSelectsSuccess,
});
