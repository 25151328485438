import Remitters from "./Remitters";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { RemittersActions } from "../../../redux/remitters";
import { CountriesActions } from "../../../redux/countries";

const mapStateToProps = ({ locale, remitters }) => ({
  list: remitters.remitters || [],
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  remittersRequest: () => dispatch(RemittersActions.remittersRequest()),
  countriesRequest: () => dispatch(CountriesActions.countriesRequest()),
  remittersDeleteRequest: (ids) => dispatch(RemittersActions.remitterDeleteRequest(ids))
});

export default connect(mapStateToProps, mapDispatchToProps)(Remitters);
