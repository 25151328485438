import { createReducer } from "reduxsauce";

import { types } from "./actions";

const initialState = {
  countries: [],
};

// Reducers
const loadCountries = (state = initialState, action) => {
  const countries = action.data.countries;
  return { ...state, countries };
};

export default createReducer(initialState, {
  [types.COUNTRIES_REQUEST_SUCCESS]: loadCountries,
});
