import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DndSubDraggable from "./DndSubDraggable";

export default ({
  id,
  items = [],
  removeFromFlux,
  onDelayChange,
  onOpenChange,
  onRequiredChange,
}) => (
  <Droppable
    droppableId={id}
    key={`sub-droppable-${id}`}
    type={`droppableSubItem`}
  >
    {(provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        id={id}
        className={"sub_draggable_container"}
      >
        {items?.map((item, index) => (
          <DndSubDraggable
            id={item.id}
            lengthPartners={items.length}
            index={index}
            item={item}
            onOpenChange={onOpenChange}
            onDelayChange={onDelayChange}
            onRequiredChange={onRequiredChange}
            removeFromFlux={removeFromFlux}
          />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);
