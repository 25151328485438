import React from "react";
import { Checkbox as AntDCheckbox } from "antd";

export const Checkbox = ({ value, onChange, ...rest }) => (
  <AntDCheckbox
    checked={value}
    onChange={(e) => onChange(e?.target?.checked)}
    {...rest}
  />
);
