import { createSelector } from "reselect";

export const selectArticles = createSelector(
  ({ articles }) => articles.articles,
  (articles) => {
    return articles;
  }
);

export const selectArchivedArticle = createSelector(
  ({ articles }) => articles.currentArchived,
  (archived) => archived
);

export const allPaletisation = createSelector(
  (state) => state.articles.allSelects,
  ({ paletisations }) => {
    return paletisations;
  }
);

export const allConversionUnits = createSelector(
  (state) => state.articles.allSelects,
  ({ conversion_units }) => conversion_units
);

export const allPackagingType = createSelector(
    (state) => state.articles.allSelects,
    ({ packaging_type }) => packaging_type
);
