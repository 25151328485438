import {createAction} from "../../utils/index";

// Types
export const types = {
  FETCH_ADMIN_FILES: "FETCH_ADMIN_FILES",
  FETCH_ADMIN_FILES_SUCCESS: "FETCH_ADMIN_FILES_SUCCESS",

  FETCH_SIGNED_URL: "FETCH_SIGNED_URL",
  FETCH_SIGNED_URL_SUCCESS: "FETCH_SIGNED_URL_SUCCESS",
};

// Actions
export default {
  fetchAdminFiles: () => createAction(types.FETCH_ADMIN_FILES),
  fetchAdminFilesSuccess: ({ cgu, privacy_policy }) =>
    createAction(types.FETCH_ADMIN_FILES_SUCCESS, { cgu, privacy_policy }),

  fetchSignedURL: ({
    location,
    dataKey,
    callbacks = { success: () => {}, error: () => {} },
  }) => createAction(types.FETCH_SIGNED_URL, { location, dataKey, callbacks }),
  fetchSignedURLSuccess: ({ url, dataKey }) =>
    createAction(types.FETCH_SIGNED_URL_SUCCESS, { url, dataKey }),
};
