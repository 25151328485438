import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton } from "../../components/button";
import { Divider, Typography, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ModifyButton from "../../components/button/ModifyButton";
import {DataTable, DropdownFilter} from "../../components";
import "./clients.css";
import { sortColumn, types } from "../../../utils";
import ListPageFilters from "../../components/listPageFilters";
import { BasicComponents, BUTTON_TYPES } from "../../components/basic";
import { ROUTES } from "../../../core/routes";
import { t } from "assets";

import XLSX from "xlsx";

const { Text } = Typography;

export default ({
  clientsRequest,
  typesRequest,
  clientsDeleteRequest,
  navigateTo,
  list,
  remitters,
  countriesRequest,
  clientImportXlsRequest
}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ remitter_code: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
      sorter: (a, b) => sortColumn(a.id, b.id, types.INT),
      render: (text) => <span>{text}</span>,
    },
   {
      title: t("clients.name"),
      dataIndex: "name",
      align: "center",
      width: 250,
      sorter: (a, b) => sortColumn(a.name, b.name, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("clients.address"),
      dataIndex: "address",
      align: "center",
      width: 320,
      sorter: (a, b) => sortColumn(a?.addr1, b?.addr1, types.STRING),
      render: (address) => <span>{address?.addr1}</span>,
    },
    {
      title: t("clients.zipCode"),
      dataIndex: "address",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a?.zip_code, b?.zip_code, types.INT),
      render: (address) => <span>{address?.zip_code}</span>,
    },
    {
      title: t("clients.city"),
      dataIndex: "address",
      align: "center",
      width: 200,
      sorter: (a, b) => sortColumn(a?.city, b?.city, types.STRING),
      render: (address) => <span>{address?.city}</span>,
    },
    {
      title: t("clients.country"),
      dataIndex: "address",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        sortColumn(
          a.country?.country_name,
          b.country?.country_name,
          types.STRING
        ),
      render: (address) => <span>{address?.country?.country_name}</span>,
    },
    {
      title: t("clients.phoneNumber"),
      dataIndex: "phone_number",
      align: "center",
      width: 200,
      sorter: (a, b) =>
        sortColumn(a.phone_number, b.phone_number, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("clients.emailAddress"),
      dataIndex: "email",
      align: "center",
      width: 320,
      sorter: (a, b) => sortColumn(a.email, b.email, types.STRING),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      width: 150,
      align: "center",
      fixed: 'right',
      render: (item) => {
        return (
          <span id="last-column-client">
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (clientsRequest) clientsRequest();
    if (typesRequest) typesRequest();
    if (countriesRequest) countriesRequest();
  }, []);

  useEffect(() => {
    setValueList(list);
  }, [list]);

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const onCreate = () => {
    navigateTo("/clients/add");
  };

  const onModify = (center) => {
    navigateTo(`/clients/${center.id}`);
  };

  const onNavigateToArticleBase = (item) => {
    navigateTo(ROUTES.REMITTER_ARTICLES.replace(":id", item.id));
  };

  const handleFilterClick = (key, e) => {
    setFilters({ ...filters, [key]: e || "" });
  };

  const filterList = (list) => {
    return list.filter((item) => {
      return (
        (filters.remitter_code.length === 0 || (item.sealogis_remitter?.code ?? "") === filters.remitter_code)
      );
    });
  };

  const filteredList = filterList(valueList);

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      clientsDeleteRequest(user);
    } else {
      clientsDeleteRequest([user.id]);
    }
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t(
            "partners_count"
          )}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  const getAttr = (key) => {
    const ret = [];
    filteredList.forEach((item) => {
      if (item[key] && !ret.includes(item[key])) ret.push(item[key]);
    });
    return ret;
  };

  const beforeUpload = (file) => {

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {

      // upload file
      const binarystr = new Uint8Array(e.target.result);
      const wb= XLSX.read(binarystr, { type: 'array', raw: true, cellFormula: false });

      const wsname = wb.SheetNames[0];
      const data = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);
      clientImportXlsRequest(data)
    }
  };

  return (
    <AuthLayout current={"7"} bread={"Clients:/clients"}>
      <ListPageFilters
        filterKeys={["name"]}
        filterPlaceholder={t("search")}
        filterList={list}
        onFilter={onFilter}
        onCreateClick={onCreate}
        buttonId="add_client"
        createButtonPlaceholder={t("clients.add_client")}
        additionalComponent={
          <span style={{marginRight: 10}}>
            <DropdownFilter
              array={remitters}
              clientKey={"remitter_code"}
              itemKeyName={"name"}
              itemKeyValue={"code"}
              handleFilterClick={handleFilterClick}
            />
          </span>
      }/>

      <Upload
        id={`upload-excel-component`}
        beforeUpload={beforeUpload}
        maxCount={1}
        showUploadList={false}
        customRequest={() => 1}
        onPreview={() => {}}
        accept={".xlsx"}
      >
        <Button
          id={`upload-button`}
          type="primary"
          style={{ marginBottom: 10 }}
          icon={<UploadOutlined />}
          loading={false}
        >
          {t("upload_excel")}
        </Button>
      </Upload>

      <DataTable
        items={filteredList}
        reset={reset}
        itemClick={onModify}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
