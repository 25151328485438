import { gql } from "@apollo/client";

export default {
  createClient: () => gql`
    mutation createClient(
      $clientPayload: clients_insert_input!
    ) {
      insert_clients_one(object: $clientPayload) {
        id
      }
    }
  `,
  duplicateAndArchive: () => gql`
  mutation createClient(
    $clientPayload: clients_insert_input!
    $clientIDtoArchive: Int!
  ) {
    insert_clients_one(object: $clientPayload) {
      id
    }

    update_clients(where: {id: { _eq: $clientIDtoArchive}}, _set: {archived: true}) {
      returning {
        id
      }
    }
  }
  `,
  updateClient: () => gql`
  mutation ClientUpdate($clientPayload: clients_insert_input!) {
    insert_clients_one(
      object: $clientPayload,
      on_conflict: {
        constraint: clients_pkey1,
        update_columns: [code, name, email, phone_number, mobile_phone_number, fax, contact, party_function_code_qualifier],
      }) {
        id
    }
  }
  `,
  updateManyClient: () =>gql`
    mutation ClientManyUpdate($clientsPayload: [clients_insert_input!]!, $clientIDtoArchive: [Int!]!) {
      update_clients(where: {id: { _in: $clientIDtoArchive}}, _set: {archived: true}) {
        returning {
          id
        }
      }
        insert_clients(
          objects: $clientsPayload,
          on_conflict: {
            constraint: clients_pkey1,
            update_columns: [code, name, email, phone_number, mobile_phone_number, fax, contact, party_function_code_qualifier],
          }) {
            returning {
              id
            }
        }
      }`,
  deleteClient: () => gql`
    mutation ClientsDelete($ids: [Int!]!) {
      delete_clients(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
};


