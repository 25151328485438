import { gql } from "@apollo/client";

export default {
  createLostPacks: () => gql`
    mutation createLostPacks($object: lost_packagings_insert_input!) {
      insert_lost_packagings_one(
        object: $object
        on_conflict: {
          constraint: lost_packaging_pkey
          update_columns: [
            code
            type
            family
            comment
            length
            width
            height
            m2
            m3
            m3_container_length
            m3_container_height
            m3_container_width
            m3_container_total
            m3_total
            weight
            consigne
            gerbability
            treatment
            value_80_per_120
          ]
        }
      ) {
        id
      }
    }
  `,
  deleteLostPacks: () => gql`
    mutation LostPacksDelete($ids: [Int!]!) {
      delete_lost_packagings(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
};
