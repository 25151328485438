import { gql } from "@apollo/client";

export default {
  createPartner: () => gql`
    mutation createPartner(
      $addressRef: addresses_obj_rel_insert_input
      $invoiceAddressRef: addresses_obj_rel_insert_input
      $contactRef: contacts_obj_rel_insert_input
      $contactTwoRef: contacts_obj_rel_insert_input
      $edi_code: String!
      $name: String!
      $sealogis_code: bpchar!
      $type_code: bpchar!
      $teaches: String
      $siren: String
      $invoice_siren: String
      $specificity1: String
      $specificity2: String
      $specificity3: String
      $specificity4: String
      $specificity5: String
      $day_open_site: jsonb
      $day_open_office: jsonb
      $hourly_open_site: String
      $hourly_open_office: String
      $company_invoiced: String
      $image: String
    ) {
      insert_partners_one(
        object: {
          address: $addressRef
          invoice_address: $invoiceAddressRef
          contact: $contactRef
          contact_two: $contactTwoRef
          edi_code: $edi_code
          name: $name
          sealogis_code: $sealogis_code
          type_code: $type_code
          teaches: $teaches
          siren: $siren
          invoice_siren: $invoice_siren
          specificity1: $specificity1
          specificity2: $specificity2
          specificity3: $specificity3
          specificity4: $specificity4
          specificity5: $specificity5
          day_open_site: $day_open_site
          day_open_office: $day_open_office
          hourly_open_site: $hourly_open_site
          hourly_open_office: $hourly_open_office
          company_invoiced: $company_invoiced
          image: $image
        }
        on_conflict: {
          constraint: partners_pkey
          update_columns: [
            name
            sealogis_code
            type_code
            day_open_site
            day_open_office
            hourly_open_office
            hourly_open_site
            image
            invoice_siren
            specificity1
            specificity2
            specificity3
            specificity4
            specificity5
            teaches
            siren
            contact_id
            contact_two_id
            address_id
            invoice_address_id
            day_open_site
            hourly_open_site
            day_open_office
            hourly_open_office
            company_invoiced
            image
          ]
        }
      ) {
        id
      }
    }
  `,
  updatePartner: () => gql`
    mutation updatePartner(
      $addressRef: addresses_obj_rel_insert_input
      $invoiceAddressRef: addresses_obj_rel_insert_input
      $contactRef: contacts_obj_rel_insert_input
      $contactTwoRef: contacts_obj_rel_insert_input
      $edi_code: String!
      $name: String!
      $sealogis_code: bpchar!
      $type_code: bpchar!
      $id: Int
      $teaches: String
      $siren: String
      $invoice_siren: String
      $specificity1: String
      $specificity2: String
      $specificity3: String
      $specificity4: String
      $specificity5: String
      $day_open_site: jsonb
      $day_open_office: jsonb
      $hourly_open_site: String
      $hourly_open_office: String
      $company_invoiced: String
      $image: String
    ) {
      insert_partners_one(
        object: {
          id: $id
          address: $addressRef
          invoice_address: $invoiceAddressRef
          contact: $contactRef
          contact_two: $contactTwoRef
          edi_code: $edi_code
          name: $name
          sealogis_code: $sealogis_code
          type_code: $type_code
          teaches: $teaches
          siren: $siren
          invoice_siren: $invoice_siren
          specificity1: $specificity1
          specificity2: $specificity2
          specificity3: $specificity3
          specificity4: $specificity4
          specificity5: $specificity5
          day_open_site: $day_open_site
          day_open_office: $day_open_office
          hourly_open_site: $hourly_open_site
          hourly_open_office: $hourly_open_office
          company_invoiced: $company_invoiced
          image: $image
        }
        on_conflict: {
          constraint: partners_pkey
          update_columns: [
            name
            sealogis_code
            type_code
            day_open_site
            day_open_office
            hourly_open_office
            hourly_open_site
            invoice_siren
            specificity1
            specificity2
            specificity3
            specificity4
            specificity5
            teaches
            siren
            contact_id
            contact_two_id
            address_id
            invoice_address_id
            day_open_site
            hourly_open_site
            day_open_office
            hourly_open_office
            company_invoiced
            image
          ]
        }
      ) {
        id
      }
    }
  `,
  deletePartner: () => gql`
    mutation PartnersDelete($ids: [Int!]!) {
      delete_partners(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
};
