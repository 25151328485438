import InputNumber from "../inputNumber";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { Switch } from "../index";
import { t } from "assets";

export default ({
  provided,
  snapshot,
  item,
  index,
  id,
  lengthPartners,
  onDelayChange,
  onOpenChange,
  onRequiredChange,
  removeFromFlux,
}) => {
  return (
    <>
      <div
        id={`partner-${id}`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={
          snapshot.isDragging
            ? "sub_draggable_item_dragging"
            : "sub_draggable_item"
        }
        style={{
          ...provided.draggableProps.style,
          display: item.name !== "Entrée manuelle" ? "flex" : "none",
        }}
      >
        <div className="info-container">
          {t("name")} : {item.name}
          <br />
          {t("type")} : {item.type}
          <br />
          {t("city")} : {item?.address?.city}
        </div>
        <div className="input-container">
          {onOpenChange && onDelayChange && (
            <InputNumber
              id={item.id}
              initialDelay={item.delay}
              initialOpen={item.open_days}
              onChangeDelay={(value) => onDelayChange(item.id, value)}
              onChangeOpen={(value) => onOpenChange(item.id, value)}
            />
          )}
          {onRequiredChange && (
            <div className={"flex-center-start"}>
              <Switch
                disabled={lengthPartners > 1}
                label={t("required")}
                value={item.required}
                onChange={(value) => onRequiredChange(item.id, value)}
              />
            </div>
          )}
        </div>
        <div className="close-container">
          {removeFromFlux && (
            <CloseOutlined
              className="close-button"
              onClick={() => removeFromFlux("partners", item)}
            />
          )}
        </div>
      </div>
    </>
  );
};
