import LostPacksCreate from "./LostPacksCreate";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { LostPacksActions } from "../../../redux/lostPacks";
import { resolveId } from "../../../utils";

const mapStateToProps = ({ router, locale, lostPacks }) => ({
  id: resolveId(router),
  families: lostPacks.families || [],
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  LostPacksByIdRequest: (id) => dispatch(LostPacksActions.lostPacksByIdRequest(id)),
  LostPacksCreateRequest: (data) => dispatch(LostPacksActions.lostPacksCreateRequest(data)),
  getLostPacksFamilies: () => dispatch(LostPacksActions.lostPacksFamilies()),
  cleanCurrent: () => dispatch(LostPacksActions.cleanCurrent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostPacksCreate);
