import {createAction} from "../../utils";

// Types
export const types = {
  CLEAN_CURRENT: "CLEAN_CURRENT",
  USER_REQUEST: "USER_REQUEST",
  USER_SUCCESS: "USER_SUCCESS",
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_DELETE_REQUEST: "USERS_DELETE_REQUEST",
  USERS_DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  USERS_UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
  USERS_CREATE_REQUEST: "USERS_CREATE_REQUEST",
  EMAIL_REQUEST: "EMAIL_REQUEST",
  EMAIL_SUCCESS: "EMAIL_SUCCESS",
  EMAIL_LOADING: "EMAIL_LOADING",
  RESEND_REQUEST: "RESEND_REQUEST",
  USER_DETAIL_UPDATE_REQUEST: "USER_DETAIL_UPDATE_REQUEST",
  USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",
  ROLE_REQUEST: "ROLE_REQUEST",
  ROLE_SUCCESS: "ROLE_SUCCESS",
};

// Actions
export default {
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  userRequest: (id) => createAction(types.USER_REQUEST, { id }),
  userSuccess: (user) => createAction(types.USER_SUCCESS, { user }),
  usersRequest: () => createAction(types.USERS_REQUEST),
  usersSuccess: (list) => createAction(types.USERS_SUCCESS, { list }),
  usersCreateRequest: (user) =>
    createAction(types.USERS_CREATE_REQUEST, { user }),
  usersUpdateRequest: (user) =>
    createAction(types.USERS_UPDATE_REQUEST, { user }),
  usersDeleteRequest: (ids) =>
    createAction(types.USERS_DELETE_REQUEST, { ids }),
  usersDeleteSuccess: (ids) =>
    createAction(types.USERS_DELETE_SUCCESS, { ids }),
  emailRequest: (email) => createAction(types.EMAIL_REQUEST, { email }),
  emailSuccess: (available) => createAction(types.EMAIL_SUCCESS, { available }),
  emailLoading: (loading) => createAction(types.EMAIL_LOADING, { loading }),
  resendRequest: (user) => createAction(types.RESEND_REQUEST, { user }),
  userDetailUpdateRequest: (user) =>
    createAction(types.USER_DETAIL_UPDATE_REQUEST, { user }),
  userDetailSuccess: (user) => createAction(types.USER_DETAIL_SUCCESS, { user }),
  roleRequest: () => createAction(types.ROLE_REQUEST),
  roleSuccess: (roles) => createAction(types.ROLE_SUCCESS, {roles})
};
