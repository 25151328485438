import Flux from "./Flux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { FluxActions } from "../../../redux/flux";

const mapStateToProps = ({ locale, flux }) => ({
  list: flux.fluxs,
  clients: flux.clients,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  fluxsRequest: (where = {}) => dispatch(FluxActions.fluxsRequest(where)),
  fluxsDeleteRequest: (fluxIds) =>
    dispatch(FluxActions.fluxsDeleteRequest(fluxIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Flux);
