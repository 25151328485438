import { authenticatedQuery, authenticatedService } from "./middleware";
import fileQueries from "./GraphQLRequest/fileQueries";
import { methods } from "../utils";

const { GET } = methods;

const STORAGE_URL = window.env.REACT_APP_STORAGE_URL;

const routes = {
  signedUrl: `${STORAGE_URL}/codex/`,
};

export default {
  getAdminFile: () => authenticatedQuery(fileQueries.adminFile()),
  getSignedUrl: (location) =>
    authenticatedService(GET, routes.signedUrl, {}, { location }),
};
