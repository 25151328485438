import React from "react";
import { Button, Col, Row } from "antd";
import Filter from "../filter";

export const ListPageFilters = ({
  filterList,
  onFilter,
  onFilterValueChange,
  filterKeys,
  filterPlaceholder,
  errorButtonPlaceholder,
  onErrorClick,
  onCreateClick,
  createButtonPlaceholder,
  buttonId = "",
  additionalComponent,
}) => (
  <Row
    style={{ width: "100%" }}
    justify={"space-between"}
    id={"filters-div"}
    className={"margin-vertical"}
  >
    <Col>
      {additionalComponent}
      <Filter
        list={filterList}
        onFilter={onFilter}
        onValueChange={onFilterValueChange}
        keys={filterKeys}
        placeHolder={filterPlaceholder}
      />
    </Col>
    <Col>
      {errorButtonPlaceholder ? (
        <Button
          style={{ marginRight: createButtonPlaceholder ? "30px" : "Opx" }}
          danger
          onClick={onErrorClick}
        >
          {errorButtonPlaceholder}
        </Button>
      ) : null}
      {createButtonPlaceholder ? (
        <Button
          type="primary"
          style={{ width: "auto" }}
          onClick={onCreateClick}
          id={buttonId}
        >
          {createButtonPlaceholder}
        </Button>
      ) : null}
    </Col>
  </Row>
);
