import Upload from "./Upload";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { PartnersActions } from "../../../redux/partners";

const mapStateToProps = ({ locale, partners }) => ({
  imgUrl: partners.imgUrl,
  signedUrl: partners.signedUrl,
  partner: partners.current,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  createImageRequest: (file, fileName) =>
    dispatch(PartnersActions.imageCreate(file, fileName)),
  createImageRequestOnOvh: (file, fileName) =>
    dispatch(PartnersActions.imageCreateOnOvh(file, fileName)),
  cleanImageRequest: () => dispatch(PartnersActions.cleanImageRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
