import { gql } from "@apollo/client";

export default {
  addAdminFile: gql`
    mutation AdminFileAdd($adminFile: admin_file_insert_input!) {
      insert_admin_file_one(object: $adminFile) {
        id
        created_at
        cgu
        privacy_policy
      }
    }
  `,
};
