import {createAction} from "../../utils";

// Types
export const types = {
  LOGIN_REFRESH: "LOGIN_REFRESH",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
};

// Actions
export default {
  loginRefresh: () => createAction(types.LOGIN_REFRESH),

  loginRequest: (username, password) =>
    createAction(types.LOGIN_REQUEST, { username, password }),

  loginSuccess: (token_type, token, refresh_token, authorization) =>
    createAction(types.LOGIN_SUCCESS, {
      token_type,
      token,
      refresh_token,
      authorization,
    }),

  loginFailure: () => createAction(types.LOGIN_FAILURE),

  logout: () => createAction(types.LOGOUT),
};
