import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import LostPacksForm from "./LostPacksForm";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { selectLostPack } from "../../../redux/lostPacks/selector";
import { ROUTES } from "../../../core/routes";
import { t } from "assets";

export default ({
  id,
  cleanCurrent,
  navigateTo,
  types,
  typesRequest,
  LostPacksByIdRequest,
  LostPacksCreateRequest,
  getLostPacksFamilies,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(selectLostPack);

  useEffect(() => {
    getLostPacksFamilies();
    if (id && LostPacksByIdRequest) {
      LostPacksByIdRequest(id);
    }
    if (typesRequest) typesRequest();
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (value) => {
    if (id) {
      value.id = id;
    }
    if (LostPacksCreateRequest) {
      LostPacksCreateRequest(value);
    }
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
        onClick={() => navigateTo(ROUTES.LOST_PACKS)}
      >
        {t("cancel")}
      </Button>
      <Button
        id="lostpack-create-btn"
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <AuthLayout
      current={"5"}
      bread={
        "Emballages Perdus:/lostpacks;Gestion emballages perdus:/lostpacks/add"
      }
    >
      <LostPacksForm
        extra={extra}
        onValidate={onValidate}
        form={form}
        values={values}
        types={types}
        isModify={id !== null}
      />
    </AuthLayout>
  );
};
