import { createAction } from "../../utils";

export const types = {
  CLIENTS_REQUEST: "CLIENTS_REQUEST",
  CLIENTS_SUCCESS: "CLIENTS_SUCCESS",
  CLIENT_REQUEST: "CLIENT_DETAIL_REQUEST",
  CLIENT_SUCCESS: "CLIENT_DETAIL_SUCCESS",
  CLIENTS_CREATE_REQUEST: "CLIENTS_CREATE_REQUEST",
  CLIENTS_UPDATE_REQUEST: "CLIENTS_UPDATE_REQUEST",
  CLIENTS_DELETE_REQUEST: "CLIENTS_DELETE_REQUEST",
  CLIENTS_DELETE_SUCCESS: "CLIENTS_DELETE_SUCCESS",
  CLEAN_CURRENT: "CLEAN_CURRENT_CLIENT",
  CLIENTS_IMPORT_XLS_REQUEST: "CLIENTS_IMPORT_XLS_REQUEST",
  CLIENTS_IMPORT_XLS_SUCCESS: "CLIENTS_IMPORT_XLS_SUCCESS",
};

export default {
  clientsRequest: () => createAction(types.CLIENTS_REQUEST),
  clientsSuccess: (list) => createAction(types.CLIENTS_SUCCESS, { list }),
  clientRequest: (id) => createAction(types.CLIENT_REQUEST, { id }),
  clientSuccess: (client) =>
    createAction(types.CLIENT_SUCCESS, { client }),
  clientUpdateRequest: (data) =>
    createAction(types.CLIENTS_UPDATE_REQUEST, { data }),
  clientCreateRequest: (data) =>
    createAction(types.CLIENTS_CREATE_REQUEST, { data }),
  clientDeleteRequest: (ids) =>
    createAction(types.CLIENTS_DELETE_REQUEST, { ids }),
  cleanCurrent: () => createAction(types.CLEAN_CURRENT),
  clientDeleteSuccess: (ids) =>
    createAction(types.CLIENTS_DELETE_SUCCESS, { ids }),
    clientImportXlsRequest: (data) =>
      createAction(types.CLIENTS_IMPORT_XLS_REQUEST, { data }),
};
