import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  remitters: [],
  remittersWithRoles: [],
  bcu: [],
  current: null,
};

const RemittersSuccess = (state = initialState, action) => {
  const { list } = action;

  /*list.partners.map((item) => {
    if (item.address && cities.indexOf(item.address.city) === -1) cities.push(item.address.city);
    if (item.address && countries.indexOf(item.address.country.country_code) === -1) countries.push(item.address.country.country_code);
  });*/

  const remitters = list.sealogis_remitters;
  /*.map((item) => ({
      ...item,
      type: item.partner_type.name,
    }))
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );*/

  return { ...state, remitters };
};

const RemitterDeleteSuccess = (state = initialState, action) => {
  const { remitters } = state;
  const remitters_ids = action.ids;
  const tmp = remitters?.filter((item) => !remitters_ids.includes(item.id));
  return { ...state, remitters: tmp };
};

const RemitterSuccess = (state = initialState, action) => {
  const remitter = action.remitter.sealogis_remitters[0];
  const current = { ...remitter };
  return { ...state, current };
};

const cleanCurrent = (state = initialState, action) => {
  return { ...state, current: null };
};

const RemittersWithRolesSuccess = (state = initialState, action) => {
  const { remitters, bcu } = action;
  return { ...state, remittersWithRoles: remitters, bcu };
};

export default createReducer(initialState, {
  [types.REMITTERS_SUCCESS]: RemittersSuccess,
  [types.REMITTER_SUCCESS]: RemitterSuccess,
  [types.CLEAN_CURRENT]: cleanCurrent,
  [types.REMITTERS_DELETE_SUCCESS]: RemitterDeleteSuccess,
  [types.REMITTERS_WITH_ROLES_SUCCESS]: RemittersWithRolesSuccess,
});
