import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton, DesactivateButton } from "../../components/button";
import { Button, Divider, Popconfirm, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import ModifyButton from "../../components/button/ModifyButton";
import { DataTable } from "../../components";
import ListPageFilters from "../../components/listPageFilters";
import "./style.css";
import { t } from "assets";

const { Text } = Typography;

export default ({
  list,
  usersRequest,
  usersDeleteRequest,
  resendRequest,
  navigateTo,
  userDetailUpdateRequest,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ client: "" });

  useEffect(() => {
    if (usersRequest) usersRequest();
  }, []);

  const columns = [
    {
      title: t("firstname"),
      dataIndex: "first_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("lastname"),
      dataIndex: "last_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("email"),
      dataIndex: "email",
      align: "center",
      width: 320,
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("tel"),
      dataIndex: "phone",
      align: "center",
      width: 200,
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("enterprise"),
      dataIndex: "company",
      align: "center",
      width: 200,
      sorter: (a, b) => a.company.localeCompare(b.company),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      width: 200,
      align: "center",
      fixed: "right",
      render: (item) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Popconfirm
              title={t("confirm_resend")}
              okText="Oui"
              cancelText="Non"
              placement="right"
              onConfirm={() => resendRequest(item)}
            >
              <Button id={"logout"} icon={<MailOutlined />} shape="circle" />
            </Popconfirm>
            <Divider type="vertical" />
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DesactivateButton
              desactivate={() => onDesactivate(item)}
              desactivated={item.activated}
            />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </div>
        );
      },
    },
  ];

  const onCreate = () => {
    navigateTo("/users/add");
  };

  const onModify = (center) => {
    navigateTo(`/users/${center.id}`);
  };

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      usersDeleteRequest(user);
    } else {
      usersDeleteRequest([user.id]);
    }
  };

  const onDesactivate = (user) => {
    user.activated = !user.activated;
    userDetailUpdateRequest(user);
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const filterList = (list) => {
    return list.filter((item) => {
      return filters.client.length === 0 || item.client === filters.client;
    });
  };

  useEffect(() => {
    if (list) setValueList(list);
  }, [list]);

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t("user_count")}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  return (
    <AuthLayout current={"3"} bread={"Utilisateurs:/users"}>
      <ListPageFilters
        filterList={list}
        onFilter={onFilter}
        filterKeys={["first_name", "last_name"]}
        filterPlaceholder={t("search")}
        onCreateClick={onCreate}
        buttonId="add_user"
        createButtonPlaceholder={t("add_user")}
      />
      <DataTable
        items={filterList(valueList)}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
