import React, { useEffect, useState } from "react";
import "./style.css";
import DndContext from "./DndContext";
import FilterDnd from "./FilterDnd";

import DndDroppable from "./DndDroppable";
import DndSubDroppable from "./DndSubDroppable";
import { get } from "lodash";
import DndSubDraggable from "./DndSubDraggable";
import DndDraggable from "./DndDraggable";
import { t } from "assets";

export default ({ value = [], onChange, partners, isError, errors = [] }) => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({ city: "", type: "", country: "" });

  useEffect(() => {
    if (partners) setList(partners);
  }, [partners]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppableItem") {
      const items = reorder(value, sourceIndex, destIndex);
      onChange && onChange(items);
    } else if (result.type === "droppableSubItem") {
      let itemSubItemMap = value.reduce((acc, item) => {
        acc[item.title] = item.steps_partners;
        return acc;
      }, {});
      itemSubItemMap = { ...itemSubItemMap, db: filterList(list) };
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...value];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map((item) => {
          if (item.title === sourceParentId) {
            item.steps_partners = reorderedSubItems;
          }
          return item;
        });
        if (newItems) onChange(newItems);
      } else {
        let newSourceSubItems = [...sourceSubItems];
        let [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];

        if (sourceParentId === "db") {
          draggedItem = {
            ...draggedItem,
            required: false,
            open_days: false,
            delay: null,
            partner: draggedItem,
          };
        }
        newDestSubItems.splice(destIndex, 0, draggedItem);
        if (newDestSubItems.length > 1)
          newDestSubItems = newDestSubItems.map((v) => ({
            ...v,
            required: false,
          }));
        if (newSourceSubItems.length > 1)
          newSourceSubItems = newSourceSubItems.map((v) => ({
            ...v,
            required: false,
          }));
        newItems = newItems.map((item) => {
          if (item.title === sourceParentId) {
            item.steps_partners = newSourceSubItems;
          } else if (item.title === destParentId) {
            item.steps_partners = newDestSubItems;
          }
          return item;
        });
        if (newItems) onChange(newItems);
      }
    }
  };

  const onFilter = (result) => {
    setSearch(result);
  };

  const filterList = (map) => {
    const ids = [];
    if (value) {
      value.forEach((v) => {
        const steps_partners = get(v, ["steps_partners"], null);
        if (steps_partners)
          steps_partners.forEach(({ partner }) => {
            ids.push(partner.id);
          });
      });
    }
    const searchIds = search?.map((item) => item.id);
    return map?.filter((item) => {
      return (
        !ids.includes(item.id) &&
        (searchIds ? searchIds.includes(item.id) : true) &&
        (filters.city.length === 0 || item?.address?.city === filters.city) &&
        (filters.type.length === 0 || item.type === filters.type) &&
        (filters.country.length === 0 || item.country === filters.country)
      );
    });
  };

  const removeFromFlux = (key, item) => {
    let tmp = [];
    if (key === "flux")
      tmp = [...value].filter((v) => !(v?.title && v.title === item.title));
    else if (key === "partners") {
      tmp = [...value].map((v) => ({
        ...v,
        steps_partners: v.steps_partners.filter(
          (p) => !(p?.partner?.id === item.id)
        ),
      }));
    }
    onChange(tmp);
  };

  const handleDelayChange = (key, delay) => {
    onChange(
      value.map((item) => ({
        ...item,
        steps_partners: item.steps_partners.map((p) =>
          p?.partner?.id === key ? { ...p, delay } : p
        ),
      }))
    );
  };

  const handleOpenChange = (key, open_days) => {
    onChange(
      value.map((item) => ({
        ...item,
        steps_partners: item.steps_partners.map((p) =>
          p?.partner?.id === key ? { ...p, open_days } : p
        ),
      }))
    );
  };

  const handleRequiredChange = (key, required) => {
    onChange(
      value.map((item) => ({
        ...item,
        steps_partners: item.steps_partners.map((p) =>
          p?.partner?.id === key ? { ...p, required } : p
        ),
      }))
    );
  };

  const handleRequiredChangeStep = (key, required) => {
    onChange(
      value.map((item) => ({
        ...item,
        required: item.key == key ? required : item.required,
      }))
    );
  };

  const getKey = (key) => {
    const ret = [];
    const map = filterList(list);
    if (map)
      map.forEach((item) => {
        if (key === "city") {
          const city = item?.address?.[key];
          if (city && ret.indexOf(city) === -1) ret.push(city);
        } else {
          if (ret.indexOf(item[key]) === -1) ret.push(item[key]);
        }
      });
    return ret;
  };

  const onClickNewStep = () => {
    const length = (value ? value : []).length;

    onChange &&
      onChange([
        ...value,
        {
          title: `droppable-${length + 100}`,
          expand: true,
          order: length,
          key: length + 100,
          required: false,
          steps_partners: [],
        },
      ]);
  };

  const onClickExpand = (item, expand) => {
    const tmpValue = value.map((v) => {
      if (v.title === item.title) return { ...v, expand };
      else return v;
    });
    onChange && onChange(tmpValue);
  };

  return (
    <>
      <FilterDnd
        cities={getKey("city")}
        types={getKey("type")}
        list={list}
        filterList={filterList}
        filters={filters}
        setFilters={setFilters}
        onFilter={onFilter}
        onClickNewStep={onClickNewStep}
      />
      <DndContext onDragEnd={onDragEnd} isError={isError} errors={errors}>
        <DndDroppable
          id={"db"}
          className={`${isError ? "error-drag-and-drop-left " : ""}drag_left`}
          name={t("db_partners")}
          type={"droppableSubItem"}
        >
          {filterList(list)?.map((item, index) => (
            <DndSubDraggable id={item.id} index={index} item={item} />
          ))}
        </DndDroppable>
        <DndDroppable
          id={"flux"}
          className={`${isError ? "error-drag-and-drop-right " : ""}drag_right`}
          name={t("flux_partners")}
        >
          {value &&
            value?.map((item, index) => {
              return (
                <DndDraggable
                  id={`draggable-${item.key}`}
                  index={index}
                  item={item}
                  onClickExpand={onClickExpand}
                  removeFromFlux={removeFromFlux}
                  onRequiredChange={handleRequiredChangeStep}
                >
                  <DndSubDroppable
                    id={`droppable-${item.key}`}
                    items={item?.steps_partners || []}
                    removeFromFlux={removeFromFlux}
                    onDelayChange={handleDelayChange}
                    onOpenChange={handleOpenChange}
                    onRequiredChange={handleRequiredChange}
                  />
                </DndDraggable>
              );
            })}
        </DndDroppable>
      </DndContext>
    </>
  );
};
