import "./sideMenu.css";

import Icon, {
  FolderViewOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect } from "react";

import SideMenuImage from "../sideMenuImage/index";
import { t } from "assets";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default ({
  navigateTo,
  inlineCollapsed,
  children,
  current,
}) => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const routes = [
    {
      key: "1",
      title: t("partners"),
      path: "/partners",
      icon: TeamOutlined,
    },
    {
      key: "2",
      title: t("flux"),
      path: "/flux",
      icon: FormOutlined,
    },
    {
      key: "3",
      title: t("users.users"),
      path: "/users",
      icon: UserOutlined,
    },
    {
      key: "4",
      title: t("remitters.remitters"),
      path: "/remitters",
      icon: FolderViewOutlined,
    },{
      key: "5",
      title: t("lost_packs.lost_packs"),
      path: "/lostpacks",
      icon: QuestionCircleOutlined,
    },
    {
      key: "7",
      title: t("client"),
      path: "/clients",
      icon: FolderViewOutlined,
    },
    {
      key: "0",
      title: t("admin"),
      path: "/admin",
      icon: SettingOutlined,
    },
  ];

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClick = ({ key }) => {
    const route = routes.find((r) =>
      r.sub ? r.sub.find((s) => s.key === key) : r.key === key
    );

    if (route.sub) {
      let subRoute = route.sub.find((s) => s.key === key);
      navigateTo(subRoute.path);
    } else {
      navigateTo(route.path);
    }
  };

  return (
    <div id={"partners"} className={"screen-wrapper"}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsed={inlineCollapsed}>
          <SideMenuImage />
          <Menu
            theme="dark"
            defaultSelectedKeys={[current]}
            mode="inline"
            onClick={onClick}
          >
            {routes.map((r) =>
              r.sub ? (
                <SubMenu
                  key={r.key}
                  title={
                    <span>
                      <Icon component={r.icon} />
                      <span>{r.title}</span>
                    </span>
                  }
                >
                  {r.sub.map((s) => (
                    <Menu.Item key={s.key}>
                      <Icon component={s.icon} />
                      <span>{s.title}</span>
                    </Menu.Item>
                  ))}{" "}
                </SubMenu>
              ) : (
                <Menu.Item id={r.path} key={r.key}>
                  <Icon component={r.icon} />
                  <span>{r.title}</span>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{ height: dimensions.height }}>{children}</Layout>
      </Layout>
    </div>
  );
};
