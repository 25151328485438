import { Button, notification } from "antd";
import React from "react";
import { t } from "assets";

export const showSuccess = (str) => {
  notification.success({ message: t(str) });
};
export const showError = (str, description, duration) => {
  duration
    ? notification.error({
        message: t(str),
        description: description,
      })
    : notification.error({ message: t(str), description: description });
};
export const showInfo = (str, description) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Ok
    </Button>
  );
  notification.config({ duration: 0 });
  notification.info({
    message: t(str),
    description: description,
    btn,
    key,
  });
  notification.config({ duration: 6 });
};
