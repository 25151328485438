import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { DeleteButton } from "../../components/button";
import { Button, Col, Divider, Input, Row, Space, Typography } from "antd";
import ModifyButton from "../../components/button/ModifyButton";
import moment from "moment";
import { DataTable, DropdownFilter } from "../../components";
import { FluxActions } from "../../../redux/flux";
import { useDispatch } from "react-redux";
import "./style.css";
import { t } from "assets";

const { Text } = Typography;

export default ({
  list,
  fluxsRequest,
  fluxsDeleteRequest,
  navigateTo,
  clients,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [filters, setFilters] = useState({ client_code: "", name: "" });
  const dispatch = useDispatch();
  const { Search } = Input;

  useEffect(() => {
    if (fluxsRequest) fluxsRequest();
  }, []);

  useEffect(() => {
    if (fluxsRequest && filters) {
      fluxsRequest(filters);
    }
  }, [filters]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
      sorter: (a, b) => a.id > b.id,
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.name ? a.name.localeCompare(b.name) : 0;
      },
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("client"),
      dataIndex: "client_code",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.client_code ? a.client_code.localeCompare(b.client_code) : 0;
      },
      render: (text) => {
        const client = clients.find((c) => c.code === text);
        return <span>{client?.name}</span>;
      },
    },
    {
      title: t("create_date"),
      dataIndex: "created_at",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return moment(a).unix() - moment(b).unix();
      },
      render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: t("nb_etapes"),
      dataIndex: "count",
      align: "center",
      width: 150,
      sorter: (a, b) => a.count > b.count,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (item) => {
        return (
          <span>
            <ModifyButton modify={() => onModify(item)} />
            <Divider type="vertical" />
            <DeleteButton deletion={() => onDelete([item && item.id])} />
          </span>
        );
      },
    },
  ];

  const onCreate = () => {
    navigateTo("/flux/add");
    dispatch && dispatch(FluxActions.resetFluxAvailable());
  };

  const onModify = (center) => {
    navigateTo(`/flux/${center.id}`);
  };

  const onDelete = (user) => {
    if (Array.isArray(user)) {
      fluxsDeleteRequest(user.map((v) => list.find((f) => f.id === v)));
    } else {
      fluxsDeleteRequest([list.find((f) => f.id === user.id)]);
    }
  };

  const onScrollTop = () => {
    const element = document.getElementById("content");
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const onFilter = (result) => {
    if (!result) setReset(!reset);
    setValueList(result || list);
  };

  const handleFilterClick = (key, e) => {
    setFilters({ ...filters, [key]: e || "" });
  };

  useEffect(() => {
    if (list) setValueList(list);
  }, [list]);

  const actionsRender = () => {
    return (
      <>
        <span>
          <span style={{ marginRight: 6 }}>
            <DeleteButton
              title={"Supprimer"}
              onCancel={() => handleReset()}
              deletion={() => onDelete(selectedRowKeys)}
            />
          </span>
          <Text strong>{`${selectedRowKeys.length} ${t(
            "flux_count"
          )}`}</Text>
        </span>
        <br />
        <br />
      </>
    );
  };

  const handleSearchChange = (event) => {
    setFilters({ ...filters, name: event.target.value });
  };

  return (
    <AuthLayout current={"2"} bread={"Flux:/flux"}>
      <Row id={"filters-div"} justify={"space-between"} className={"margin-vertical"}>
        <Col className={"default-space-filter"}>
          <Space size={8} wrap>
            <Search
              id={"search"}
              className={"default-width-select"}
              placeholder={"Saisissez votre recherche"}
              value={filters.name}
              onChange={handleSearchChange}
              allowClear={true}
              enterButton
            />
            <DropdownFilter
              array={clients}
              clientKey={"client_code"}
              itemKeyName={"name"}
              itemKeyValue={"code"}
              handleFilterClick={handleFilterClick}
            />
          </Space>
        </Col>
        <Col className={"default-space-filter"}>
          <Button
            id={"add_flux"}
            key={"create-flux"}
            type="primary"
            onClick={onCreate}
          >
            {t("add_flux")}
          </Button>
        </Col>
      </Row>
      <DataTable
        items={valueList}
        columns={columns}
        actionsRender={actionsRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </AuthLayout>
  );
};
