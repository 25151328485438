import { connect } from "react-redux";
import { push } from "connected-react-router";
import FluxCreate from "./FluxCreate";
import { resolveId } from "../../../utils";
import { FluxActions } from "../../../redux/flux";
import { PartnersActions } from "../../../redux/partners";

const mapStateToProps = ({ locale, router, flux, partners }) => ({
  id: resolveId(router),
  clients: flux.clients,
  partners: partners.partners,
  cities: partners.cities,
  types: partners.typeNames,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  FluxRequest: (id) => dispatch(FluxActions.fluxRequest(id)),
  PartnersRequest: () => dispatch(PartnersActions.partnersRequest()),
  fluxCreateRequest: (flux) => dispatch(FluxActions.fluxCreateRequest(flux)),
  FluxUpdateRequest: (flux) => dispatch(FluxActions.fluxUpdateRequest(flux)),
  cleanCurrent: () => dispatch(FluxActions.cleanCurrent()),
  ClientRequest: () => dispatch(FluxActions.clientRequest()),
  typesRequest: () => dispatch(PartnersActions.typesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FluxCreate);
