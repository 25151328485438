import React from "react";
import { Card, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ArticlesActions } from "../../../redux/articles";

const ModalImportReport = () => {
  const reports = useSelector((state) => state.articles.importReports);
  const dispatch = useDispatch();

  const onOkClick = () => {
    if (dispatch) {
      dispatch(ArticlesActions.resetImportReport());
    }
  };

  return (
    <Modal
      maskClosable={false}
      cancelButtonProps={{ hidden: true }}
      open={reports.length > 0}
      onOk={onOkClick}
      onCancel={onOkClick}
    >
      <Card bordered={false} style={{ overflow: "auto", height: "60vh" }}>
        {reports?.map((error, index) => (
          <p
            dangerouslySetInnerHTML={{ __html: error }}
            key={`report_modal_${index}`}
          />
        ))}
      </Card>
    </Modal>
  );
};

export default ModalImportReport;
