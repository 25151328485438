import React, { useEffect } from "react";
import "./style.css";
import AuthLayout from "../../layouts/authLayout";
import PartnersForm from "./PartnersForm";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { selectPartner } from "../../../redux/partners/selector";
import { t } from "assets";

export default ({
  id,
  cleanCurrent,
  navigateTo,
  types,
  typesRequest,
  PartnerRequest,
  PartnersCreateRequest,
  PartnersUpdateRequest,
}) => {
  const [form] = Form.useForm();
  const values = useSelector(selectPartner);

  useEffect(() => {
    if (id && PartnerRequest) {
      PartnerRequest(id);
    }
    if (typesRequest) typesRequest();
    return () => {
      cleanCurrent();
    };
  }, []);

  const onValidate = (value) => {
    if (
      (values == null || Object.keys(values)?.length === 0) &&
      PartnersCreateRequest
    )
      PartnersCreateRequest(value);
    else if (PartnersUpdateRequest)
      PartnersUpdateRequest({
        id,
        address_id: values.address_id,
        invoice_address_id: values.invoice_address_id,
        contact_id: values.contact_id,
        contact_two_id: values.contact_two_id,
        ...value,
      });
  };

  const extra = (
    <div className={"extra-div ant-card-bordered"}>
      <Button
        style={{ marginRight: 30, width: 250 }}
        danger
        className="login-form-button"
        onClick={() => navigateTo("/partners")}
      >
        {t("cancel")}
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        style={{ width: 250 }}
        onClick={() => form.submit()}
        className="login-form-button"
      >
        {t("submit")}
      </Button>
    </div>
  );

  return (
    <AuthLayout
      current={"1"}
      bread={"Prestataires:/partners;Gestion prestataire:/partners/add"}
    >
      <PartnersForm
        extra={extra}
        onValidate={onValidate}
        form={form}
        values={values}
        types={types}
        isModify={id !== null}
      />
    </AuthLayout>
  );
};
