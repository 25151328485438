import { createSelector } from "reselect";

export const selectImgUrl = createSelector(
  (state) => state.partners.imgUrl,
  (imgUrl) => imgUrl
);

export const selectImgSignedUrl = createSelector(
  (state) => state.partners.signedUrl,
  (signedUrl) => signedUrl
);

export const selectPartner = createSelector(
  (state) => state.partners.current,
  (current) => {
    const formatted = { ...current };
    if (current?.address) {
      formatted.address = {
        ...current.address,
        zip_code: current.address.zip_code
          ? `${current.address.zip_code}`
          : undefined,
        country_code: current?.address?.country?.country_code,
      };
    }
    if (current?.invoice_address) {
      formatted.invoice_address = {
        ...current.invoice_address,
        zip_code: current.invoice_address.zip_code
          ? `${current.invoice_address.zip_code}`
          : undefined,
        country_code: current?.invoice_address?.country?.country_code,
      };
    }
    return formatted;
  }
);
