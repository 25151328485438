import React, { useState } from "react";
import { InputNumber, Switch } from "antd";
import "./style.css";
import { t } from "assets";

export default ({
  id,
  initialDelay,
  initialOpen,
  onChangeDelay,
  onChangeOpen,
}) => {
  const [valueDelay, setValueDelay] = useState(initialDelay);
  const [valueOpen, setValueOpen] = useState(initialOpen);

  const handleChange = (v) => {
    setValueDelay(v);
  };

  const handleBlur = () => {
    if (onChangeDelay) onChangeDelay(valueDelay);
  };

  const handleChangeOpen = (v) => {
    setValueOpen(v);
    onChangeOpen(v);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <InputNumber
          value={valueDelay}
          min={0}
          onBlur={handleBlur}
          onChange={handleChange}
          id={`number-${id}`}
          placeholder={`${t("delay")}`}
          maxLength={3}
          size="small"
          style={{
            maxWidth: "50px",
            marginRight: "5px",
          }}
        />
        H
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <span style={{ alignSelf: "start" }}>{t("open_days")}</span>
        <Switch
          checked={valueOpen}
          onChange={handleChangeOpen}
          size="small"
          style={{
            marginLeft: "5px",
          }}
        />
      </div>
    </div>
  );
};
