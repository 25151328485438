import { Select } from "antd";
import React, { useState } from "react";

const { Option } = Select;

export default ({ array, value, onChange, placeholder, id, keyForValue="code", keyForLabel="name" }) => {
  const [selectedValue, setSelectedValue] = useState(value || "");

  const handleSearch = (value) => {
    if (value?.length > 0) {
      setSelectedValue(value);
      onChange(value);
    }
  };

  const handleClear = () => {
    setSelectedValue("");
  };

  const handleChange = (v) => {
    onChange(v);
  };

  const handleSelect = (v) => {
    setSelectedValue(v);
  };

  return (
    <Select
      id={id}
      allowClear
      value={selectedValue}
      getPopupContainer={(trigger) => trigger.parentNode}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      onChange={handleChange}
      placeholder={placeholder}
      showSearch
    >
      {array.map((item, index) => (
        <Option value={item[keyForValue]} key={`${item[keyForValue]}-${index}`}>
          {item[keyForLabel]}
        </Option>
      ))}
    </Select>
  );
};
