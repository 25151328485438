import {createAction} from "../../utils";

// Types
export const types = {
  REMITTER_ARTICLE_REQUEST: "REMITTER_ARTICLE_REQUEST",
  REMITTER_ARTICLE_SUCCESS: "REMITTER_ARTICLE_SUCCESS",
  REMITTER_ARTICLE_DELETE_REQUEST: "REMITTERS_ARTICLE_DELETE_REQUEST",
  REMITTER_ARTICLE_DELETE_SUCCESS: "REMITTERS_ARTICLE_DELETE_SUCCESS",
  REMITTER_ARTICLE_CREATE_REQUEST: "REMITTERS_ARTICLE_CREATE_REQUEST",
  REMITTER_ARTICLE_CREATE_SUCCESS: "REMITTERS_ARTICLE_CREATE_SUCCESS",
  CREATE_ARTICLE_FILE_REQUEST: "CREATE_ARTICLE_FILE_REQUEST",
  CREATE_ARTICLE_FILE_SUCCESS: "CREATE_ARTICLE_FILE_SUCCESS",
  DELETE_ARTICLE_FILE_REQUEST: "DELETE_ARTICLE_FILE_REQUEST",
  DELETE_ARTICLE_FILE_SUCCESS: "DELETE_ARTICLE_FILE_SUCCESS",
  GET_SIGNED_URL: "GET_SIGNED_URL",
  CLEAN_ARTICLES: "CLEAN_ARTICLES",
  GET_ARCHIVED_ARTICLES_REQUEST: "GET_ARCHIVED_ARTICLES_REQUEST",
  GET_ARCHIVED_ARTICLES_SUCCESS: "GET_ARCHIVED_ARTICLES_SUCCESS",

  // ADDITIONAL DATA FOR ARTICLE CREATE
  GET_ALL_RELATED_ARTICLE_SELECTS: "GET_ALL_RELATED_ARTICLE_SELECTS",
  GET_ALL_RELATED_ARTICLE_SELECTS_SUCCESS:
    "GET_ALL_RELATED_ARTICLE_SELECTS_SUCCESS",
  UPLOAD_EXCEL_REQUEST: "UPLOAD_EXCEL_REQUEST",
  SET_IMPORT_REPORTS: "SET_IMPORT_REPORTS",
};

// Actions
export default {
  remitterArticleRequest: (remitterCode) =>
    createAction(types.REMITTER_ARTICLE_REQUEST, { remitterCode }),
  remitterArticleSuccess: (list) =>
    createAction(types.REMITTER_ARTICLE_SUCCESS, { list }),
  remitterArticleDeleteRequest: (ids) =>
    createAction(types.REMITTER_ARTICLE_DELETE_REQUEST, { ids }),
  remitterArticleDeleteSuccess: (ids) =>
    createAction(types.REMITTER_ARTICLE_DELETE_SUCCESS, { ids }),
  remitterArticleCreateSuccess: (article) =>
    createAction(types.REMITTER_ARTICLE_CREATE_SUCCESS, { article }),
  remitterArticleCreateRequest: (values, options, remitterCode) =>
    createAction(types.REMITTER_ARTICLE_CREATE_REQUEST, {
      values,
      options,
      remitterCode,
    }),
  createArticleFileRequest: (file, articleId, callback) =>
    createAction(types.CREATE_ARTICLE_FILE_REQUEST, {
      file,
      articleId,
      callback,
    }),
  createArticleFileSuccess: (toAdd, id) =>
    createAction(types.CREATE_ARTICLE_FILE_SUCCESS, {
      id,
      toAdd,
    }),
  deleteArticleFileRequest: (fileId, articleId, callback) =>
    createAction(types.DELETE_ARTICLE_FILE_REQUEST, {
      fileId,
      articleId,
      callback,
    }),
  deleteArticleFileSuccess: (fileId, articleId) =>
    createAction(types.DELETE_ARTICLE_FILE_SUCCESS, {
      fileId,
      articleId,
    }),
  getSignedUrl: (name, callback) =>
    createAction(types.GET_SIGNED_URL, { name, callback }),
  cleanArticles: () => createAction(types.CLEAN_ARTICLES),
  getArchivedArticles: (article) =>
    createAction(types.GET_ARCHIVED_ARTICLES_REQUEST, { article }),
  getArchivedArticlesSuccess: (article, archived) =>
    createAction(types.GET_ARCHIVED_ARTICLES_SUCCESS, { article, archived }),

  getAllRelatedArticleSelects: () =>
    createAction(types.GET_ALL_RELATED_ARTICLE_SELECTS),
  getAllRelatedArticleSelectsSuccess: (data) =>
    createAction(types.GET_ALL_RELATED_ARTICLE_SELECTS_SUCCESS, { data }),
  uploadExcelRequest: (file, code, callback) =>
    createAction(types.UPLOAD_EXCEL_REQUEST, { file, code, callback }),
  setImportReport: (reports) =>
    createAction(types.SET_IMPORT_REPORTS, { reports }),
  resetImportReport: () =>
    createAction(types.SET_IMPORT_REPORTS, { reports: [] }),
};
