import { DateTime } from "luxon";

export const dateFormat = (date, format = "dd/MM/yy") => {
  let d = date;
  if (typeof date !== "Date") d = new Date(date);
  return date ? DateTime.fromJSDate(d).toFormat(format) : null;
};

export const createDate = (date, format = "dd/MM/yy") => {
  return DateTime.fromFormat(date, format).toJSDate();
};
