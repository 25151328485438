import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import fluxQueries from "./GraphQLRequest/fluxQueries";
import fluxMutations from "./GraphQLRequest/fluxMutations";
import { methods } from "../utils";

const { GET } = methods;

const routes = {
  checkFlux: window.env.REACT_APP_BASE_API_URL + "/auth/verify-flux",
  getFluxs: window.env.REACT_APP_SYNC_URL + "/fluxs",
  getFlux: (id) => window.env.REACT_APP_SYNC_URL + "/flux/" + id,
};

export default {
  fluxs: (data) => {
    return authenticatedQuery(fluxQueries.fluxs(), {}, data);
  },
  clients: () => authenticatedQuery(fluxQueries.clients()),
  getHistoryFlux: (values) =>
    authenticatedQuery(fluxQueries.historyFlux, {}, values),
  checkFluxRequest: (values) =>
    authenticatedService(GET, routes.checkFlux, {}, values),
  fluxsDelete: (ids) =>
    authenticatedMutation(fluxMutations.deleteFlux(), {}, { ids }),
  flux: (id) => authenticatedQuery(fluxQueries.testFlux(), {}, { id }),
  fluxCreate: (data) =>
    authenticatedMutation(fluxMutations.createFlux(), {}, data),
  fluxUpdate: (data) =>
    authenticatedMutation(fluxMutations.updateFlux(), {}, data),
  getLastFluxForArchived: (ids) => authenticatedQuery(
      fluxQueries.getLastFluxForArchived(), {}, {ids}
  )
};
