import React from "react";
import { Form } from "antd";
import { requiredRules } from "../../../utils/form-helper";

import "antd/lib/style/css";
import "antd/lib/form/style/css";
import { t } from "assets";

export default ({ item, checkFluxAvailable, values, components }) => {
  return (
    <div id={`${item.key}-form`}>
      {item.component === "dnd" && (
        <>
          <label className={"label-copied-antd"}>
            <span className={"span-label-copied-antd"}>*</span>
            {t("steps")}
          </label>
        </>
      )}
      <Form.Item
        validateTrigger={item.validateTrigger || "onChange"}
        noStyle={item.noStyle}
        name={item.key}
        label={t(item.key)}
        rules={[
          requiredRules(item),
          () => ({
            validator(rule, value) {
              if (item.validator && !item.validator(value)) {
                return Promise.reject(item.error || `${t(item.key)} incorrect`);
              }
              if (
                item?.errorCheck &&
                value &&
                value !== values?.name &&
                value !== values?.client &&
                !checkFluxAvailable
              ) {
                return Promise.reject(item.errorCheck);
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {components[item.component || "input"]({
          key: item.key,
          args: item.args,
        })}
      </Form.Item>
    </div>
  );
};
