import { gql } from "@apollo/client";

export default {
  createRemitter: () => gql`
    mutation createRemitter(
      $remitterPayload: sealogis_remitters_insert_input!
    ) {
      insert_sealogis_remitters_one(object: $remitterPayload) {
        id
      }
    }
  `,
  updateRemitter: () => gql`
    mutation updateRemitter(
      $id: Int!
      $EORI_number: String
      $address: String
      $ape_code: String
      $wms_code: String
      $code: String
      $comercial: String
      $id_country: Int
      $email: String
      $fax_number: String
      $legal_form: String
      $name: String
      $phone_number: String
      $town: String
      $tva_number: String!
      $identification_number: String
      $zip_code: String
      $id_sales: Int
      $id_accounts: Int
      $id_management: Int
    ) {
      update_sealogis_remitters_by_pk(
        pk_columns: { id: $id }
        _set: {
          EORI_number: $EORI_number
          address: $address
          ape_code: $ape_code
          wms_code: $wms_code
          code: $code
          comercial: $comercial
          email: $email
          fax_number: $fax_number
          id_country: $id_country
          identification_number: $identification_number
          legal_form: $legal_form
          name: $name
          phone_number: $phone_number
          town: $town
          tva_number: $tva_number
          zip_code: $zip_code
          id_sales: $id_sales
          id_accounts: $id_accounts
          id_management: $id_management
        }
      ) {
        id
      }
    }
  `,
  updateContact: () => gql`
    mutation update_contacts_by_pk(
      $id: Int!
      $email: String!
      $phone_number: String!
      $name: String!
    ) {
      update_contacts_by_pk(
        pk_columns: { id: $id }
        _set: { email: $email, phone_number: $phone_number, name: $name }
      ) {
        id
      }
    }
  `,
  createContact: () => gql`
    mutation insert_contacts_one($data: contacts_insert_input!) {
      insert_contacts_one(object: $data) {
        id
      }
    }
  `,
  deleteRemitter: () => gql`
    mutation RemittersDelete($ids: [Int!]!) {
      delete_role_sealogis_remitters_users(
        where: {
          role_sealogis_remitter: { id_sealogis_remitter: { _in: $ids } }
        }
      ) {
        returning {
          id
        }
      }
      delete_role_sealogis_remitters(
        where: { id_sealogis_remitter: { _in: $ids } }
      ) {
        returning {
          id
        }
      }
      delete_sealogis_remitters(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
};
