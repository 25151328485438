import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import usersQueries from "./GraphQLRequest/usersQueries";
import usersMutations from "./GraphQLRequest/usersMutations";
import { methods } from "../utils";

const { POST, GET } = methods;
const routes = {
  signin: window.env.REACT_APP_AUTH_API_URL + "/auth/signin",
  email: window.env.REACT_APP_AUTH_API_URL + "/auth/verify-email",
  resend: window.env.REACT_APP_AUTH_API_URL + "/auth/resend",
  sendCreateAccountMail:
    window.env.REACT_APP_BASE_API_URL + "/bo/send-mail-create-account",
  changePassword: window.env.REACT_APP_AUTH_API_URL + "/auth/change-password",
  changeExpirationToken:
    window.env.REACT_APP_AUTH_API_URL + "/auth/desactivate-user",
};

export default {
  users: () => authenticatedQuery(usersQueries.users()),
  user: (id) => authenticatedQuery(usersQueries.user(), {}, { id }),
  updateUser: (data) =>
    authenticatedMutation(usersMutations.updateUser(), {}, data),
  insertUserFlux: (data) =>
    authenticatedMutation(usersMutations.insertUserFlux(), {}, data),
  deleteUserFlux: (data) =>
    authenticatedMutation(usersMutations.deleteUserFlux(), {}, data),
  //createUser: (data) => authenticatedMutation(usersMutations.createUser(), {}, data),
  createUser: (data) => authenticatedService(POST, routes.signin, data),
  deleteUser: (ids) =>
    authenticatedMutation(usersMutations.deleteUser(), {}, { ids }),
  emailRequest: (email) =>
    authenticatedService(GET, routes.email, {}, { email }),
  resend: (id) => authenticatedService(POST, routes.resend, { id }),
  updateUserDetail: (data) =>
    authenticatedQuery(usersMutations.updateUserDetail(), {}, data),
  deleteUserToken: (id) =>
    authenticatedQuery(usersMutations.deleteUserToken(), {}, id),
  changeExpirationToken: (id) =>
    authenticatedService(POST, routes.changeExpirationToken, id),
  roleRequest: () => authenticatedQuery(usersQueries.roles()),
  userFluxCreate: (data) =>
    authenticatedMutation(usersMutations.createUserFlux(), {}, data),
  emailCreateAccountRequest: (data) =>
    authenticatedService(POST, routes.sendCreateAccountMail, { data }),
  updateUserPassword: (data) =>
    authenticatedService(POST, routes.changePassword, data),
  fullUpdateUser: (data) =>
    authenticatedMutation(usersMutations.fullUpdateUser, {}, { ...data }),
};
