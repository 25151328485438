import { connect } from "react-redux";
import { push } from "connected-react-router";
import Admin from "./Admin";

const mapStateToProps = ({ locale }) => ({});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
