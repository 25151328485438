import React, { useEffect, useState } from "react";
import { Button, Tooltip, Upload } from "antd";
import {
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./style.css";
import moment from "moment";
import { showError } from "../../../utils/notifications-helper";
import { useDispatch, useSelector } from "react-redux";
import { FileActions } from "../../../redux/files";
import { t } from "assets";

export default ({
  createImageRequest,
  value,
  select,
  onChange,
  partner,
  cleanImageRequest,
  allowTypes,
  accept,
  errorMessageType,
  type = null,
  createImageRequestOnOvh,
  onOvh,
}) => {
  const [loading, setLoading] = useState(false);
  const [partnerId, setPartnerId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const imgUrl = useSelector(select);
  const dispatch = useDispatch();

  useEffect(() => {
    if (imgUrl) {
      setLoading(false);
      onChange(imgUrl);
    }
  }, [imgUrl]);

  useEffect(() => {
    if (value && value !== "false") {
      setRefresh(!refresh);
    }
  }, [value]);

  useEffect(() => {
    return () => {
      if (cleanImageRequest) cleanImageRequest();
    };
  }, []);

  useEffect(() => {
    if (partner && partner.id) {
      setPartnerId(partner.id);
    }
  }, [partner]);

  const beforeUpload = (file) => {
    let load = true;
    const isGoodType = allowTypes.includes(file.type);
    if (!isGoodType) {
      showError(errorMessageType);
      load = false;
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      showError("wrong_image_size");
      load = false;
    }
    setLoading(load);
    let fileName = !type
      ? `${partnerId}_logo.png`
      : `${moment().unix()}_${type}.pdf`;
    if (load) {
      onChange("false");
      if (onOvh) createImageRequestOnOvh(file, fileName);
      else createImageRequest(file, fileName);
    }
  };

  const clearImage = () => {
    onChange("");
  };

  const Image64 = ({ uri }) => (
    <div>
      <CloseOutlined
        style={{ position: "absolute" }}
        onClick={(e) => {
          e.stopPropagation();
          clearImage();
        }}
      />
      <img
        src={`${uri}`}
        alt="avatar"
        style={{ width: "100%", borderRadius: "8px" }}
      />
    </div>
  );

  const handleViewPdf = (e) => {
    e.stopPropagation();
    dispatch(
      FileActions.fetchSignedURL({
        location: value,
        dataKey: "cgu",
        callbacks: {
          success: (url) => window.open(url, "_blank"),
        },
      })
    );
  };

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={() => 1}
        beforeUpload={beforeUpload}
        maxCount={1}
        data={{
          url: `${
            value && value.indexOf("https") > 0
              ? value
              : window.env.REACT_APP_STORAGE_URL
          }/${value}`,
        }}
        onPreview={() => {}}
        accept={accept}
        id={`upload-${type}`}
      >
        {value && value !== "false" ? (
          value.includes("pdf") ? (
            <div>
              <Tooltip title={t("preview")}>
                <Button
                  shape={"circle"}
                  className={"btn-preview-icon"}
                  onClick={handleViewPdf}
                  icon={<EyeOutlined className={"show-pdf-icon"} />}
                />
              </Tooltip>
              <Tooltip title={t("update")}>
                <Button
                  shape={"circle"}
                  className={"btn-update-icon"}
                  icon={<EditOutlined className={"show-pdf-icon"} />}
                />
              </Tooltip>
            </div>
          ) : (
            <Image64 uri={value} />
          )
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
    </>
  );
};
