import { gql } from "@apollo/client";

export default {
  adminFile: () => gql`
    query getAdminFile {
      admin_file(order_by: { created_at: desc }) {
        id
        created_at
        cgu
        privacy_policy
      }
    }
  `,
};
