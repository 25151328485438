import {createAction} from '../../utils';

// Types
export const types = {
  COUNTRIES_REQUEST: 'COUNTRIES_REQUEST',
  COUNTRIES_REQUEST_SUCCESS: 'COUNTRIES_REQUEST_SUCCESS',
};

// Actions
export default {
  countriesRequest: () => createAction(types.COUNTRIES_REQUEST),
  countriesRequestSuccess: (data) => createAction(types.COUNTRIES_REQUEST_SUCCESS, {data}),
};
