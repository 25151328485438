import React from "react";
import { Select as AntDSelect } from "antd";
import { useSelector } from "react-redux";

const { Option } = AntDSelect;

export const Select = ({
  selector,
  schema = { value: "id", name: "code" },
  id,
  ...props
}) => {
  const options = useSelector(selector);

  return (
    <AntDSelect {...props} id={id}>
      {options.map((option) => (
        <Option value={option[schema.value]} key={option["id" || schema.value]}>
          {option[schema.name]}
        </Option>
      ))}
    </AntDSelect>
  );
};
