import React from "react";
import { Select } from "antd";

const { Option } = Select;

export default ({ array, value, onChange, placeholder, id, placement = "bottomLeft"}) => {
  return (
    <Select
      id={id}
      mode="multiple"
      allowClear
      value={value}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={onChange}
      placement={placement}
      placeholder={placeholder}
      showSearch
    >
      {array.map(({ label, value }, index) => (
        <Option value={value} key={`${label}-${index}`}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
