import { gql } from "@apollo/client";

export default {
  remitterArticles: () => gql`
    query getRemitterArticle($code: String) {
      articles_not_archived: articles_new(
        where: { remitter_code: { _eq: $code }, archived: { _eq: false } }
        order_by: { id: asc }
      ) {
        ean13
        is_pictogram
        stackability
        code
        collection
        compound_nomencal_code
        internal_code
        label
        main_supplier
        manufacturer_label
        manufacturer_reference
        master_carton_type
        nomenclature
        remitter_code
        temperature
        barrel_diameter
        height_palet
        homogeneous_raw_pallet_height_cm
        homogeneous_raw_pallet_weight_kg
        layer_height
        master_carton_height
        master_carton_length
        master_carton_length_cm
        master_carton_m3
        master_carton_packaging_weight_raw
        master_carton_weight
        master_carton_weight_net
        master_carton_weight_raw
        master_carton_width
        raw_weight_palet
        total_no_palet_height
        uvc_cylinder_diameter
        uvc_height
        uvc_length
        uvc_m3
        uvc_packaging_weight_raw
        uvc_weight_net
        uvc_weight_raw
        uvc_width
        adr
        component
        component_code
        conversion_handling_unit_um
        desired_paletisation
        uvc_by_palet
        desired_palletization
        handling_unit
        id
        id_carton_weight_unity
        id_missing_packaging
        id_pictogram
        lost_packaging {
          id
          code
        }
        paletisation {
          id
          label
        }
        conversion_unit {
          id
          label
        }
        packaging_type {
          id
          type
        }
        range {
          id
          label
        }
        itf14
        nb_cartons_by_palet
        nb_layer_by_palet
        nb_package_by_layer
        pcb
        storage_class
        uvc_by_palet
        created_at
        date_maj_pcb_cdt
        sealogis_created_at
        updated_at
        files(
          where: { archived: { _eq: false } }
          order_by: { created_at: desc }
        ) {
          id
          name
          u_name
          created_at
        }
      }
      articles_archived: articles_new(
        where: { remitter_code: { _eq: $code }, archived: { _eq: true } }
      ) {
        id
        code
      }
    }
  `,

  archived: gql`
    query getArchivedArticles($ids: [Int!]!) {
      articles_new(where: { id: { _in: $ids } }) {
        ean13
        is_pictogram
        stackability
        code
        collection
        compound_nomencal_code
        internal_code
        label
        main_supplier
        manufacturer_label
        manufacturer_reference
        master_carton_type
        nomenclature
        remitter_code
        temperature
        barrel_diameter
        height_palet
        homogeneous_raw_pallet_height_cm
        homogeneous_raw_pallet_weight_kg
        layer_height
        master_carton_height
        master_carton_length
        master_carton_length_cm
        master_carton_m3
        master_carton_packaging_weight_raw
        master_carton_weight
        master_carton_weight_net
        master_carton_weight_raw
        master_carton_width
        raw_weight_palet
        total_no_palet_height
        uvc_cylinder_diameter
        uvc_height
        uvc_length
        uvc_m3
        uvc_packaging_weight_raw
        uvc_weight_net
        uvc_weight_raw
        uvc_width
        adr
        component
        component_code
        conversion_handling_unit_um
        desired_paletisation
        uvc_by_palet
        desired_palletization
        handling_unit
        id
        id_carton_weight_unity
        id_missing_packaging
        id_pictogram
        lost_packaging {
          id
          code
        }
        paletisation {
          id
          label
        }
        conversion_unit {
          id
          label
        }
        range {
          id
          label
        }
        itf14
        nb_cartons_by_palet
        nb_layer_by_palet
        nb_package_by_layer
        pcb
        storage_class
        uvc_by_palet
        created_at
        date_maj_pcb_cdt
        sealogis_created_at
        updated_at
      }
    }
  `,
  palletizing: gql`
    query getPalletizing {
      paletisations {
        id
        label
      }
    }
  `,
  range: gql`
    query getRanges {
      ranges {
        id
        label
      }
    }
  `,

  conversionUnits: gql`
    query getConversionUnits {
      conversion_units {
        id
        label
      }
    }
  `,
  packagingType: gql`
    query getPackagingType {
      packaging_type {
        id
        type
      }
    }
  `
};
