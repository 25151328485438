import React, { useEffect } from "react";
import { Card, Form } from "antd";
import { Upload } from "../../components";
import { requiredRules } from "../../../utils/form-helper";
import { selectCGU, selectPrivacyPolicy } from "../../../redux/files";
import { t } from "assets";

export default ({ onValidate, values, form }) => {
  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  const onFinish = (values) => {
    if (onValidate) onValidate(values);
  };

  const components = {
    upload: (item) => (
      <Upload
        select={item.select}
        type={item.key}
        item={item}
        allowTypes={["application/pdf"]}
        errorMessageType={"wrong_file_format"}
        accept={".pdf"}
      />
    ),
  };

  const fields = [
    {
      key: "cgu",
      component: "upload",
      select: selectCGU,
      validateTrigger: "onSubmit",
      validator: (item) => {
        return item !== "false";
      },
    },
    {
      key: "privacy_policy",
      component: "upload",
      select: selectPrivacyPolicy,
      validateTrigger: "onSubmit",
      validator: (item) => {
        return item !== "false";
      },
    },
  ];

  return (
    <Card>
      <Form
        initialValues={values}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {fields.map((item) => (
          <div id={`${item.key}-form`}>
            <Form.Item
              validateTrigger={item.validateTrigger || "onChange"}
              name={item.key}
              label={t(item.key)}
              rules={[
                requiredRules(item),
                () => ({
                  validator(rule, value) {
                    if (item.validator && !item.validator(value))
                      return Promise.reject(
                        item.error || `${t(item.key)} incorrect`
                      );
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {components[item.component || "input"](item)}
            </Form.Item>
          </div>
        ))}
      </Form>
    </Card>
  );
};
