import { push } from "connected-react-router";
import { connect } from "react-redux";

import { AuthActions } from "../../../redux/auth";
import AuthLayout from "./AuthLayout";

const mapStateToProps = ({ locale }) => ({});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
  logout: () => dispatch(AuthActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
