import React, { useEffect } from "react";
import { Button, Card, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ArticlesActions } from "../../../../redux/articles";
import { selectArchivedArticle } from "../../../../redux/articles/selector";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { t } from "assets";

export const ArchivedModal = ({ article, onClose }) => {
  const dispatch = useDispatch();
  const archived = useSelector(selectArchivedArticle);

  useEffect(() => {
    if (dispatch && article?.id > 0) {
      dispatch(ArticlesActions.getArchivedArticles(article));
    }
  }, [article?.id]);

  const columns = [
    {
      title: t("remitter_article.data_index"),
      dataIndex: "key",
    },
    {
      title: t("remitter_article.old_data"),
      dataIndex: "data",
    },
  ];

  const expandable = {
    defaultExpandAllRows: true,
    expandRowByClick: false,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <Button
          type={"primary"}
          ghost
          size={"small"}
          icon={<MinusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ) : (
        <Button
          size={"small"}
          type={"primary"}
          icon={<PlusOutlined />}
          onClick={(e) => onExpand(record, e)}
        />
      ),
    expandedRowRender: (record) => (
      <Table
        bordered
        scr
        dataSource={record.errorKeys.map((key) => ({
          key: t(`remitter_article.${key}`),
          data: record[key],
        }))}
        columns={columns}
        pagination={false}
      />
    ),
  };

  return (
    <Modal open={!!article} onOk={onClose} onCancel={onClose} cancelText={null}>
      <Card bordered={false} style={{ overflow: "auto", height: "60vh" }}>
        <Table
          tableLayout="fixed"
          size={"small"}
          /*scroll={{ y: dimensions.height - 400, x: "auto" }}*/
          expandable={expandable}
          rowClassName={"global-row"}
          dataSource={archived || []}
          rowKey={(record) => record.id}
          showSorterTooltip={false}
          showHeader={false}
          pagination={false}
          columns={[
            {
              sorter: (a, b) => a?.localeCompare(b.id),
              render: (article) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#0480FC" }}>
                      {article?.archived_date}
                    </span>
                  </div>
                );
              },
            },
          ]}
        />
      </Card>
    </Modal>
  );
};
