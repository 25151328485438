import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
} from "./middleware";
import articlesMutations from "./GraphQLRequest/articlesMutations";
import articlesQueries from "./GraphQLRequest/articlesQueries";
import { methods } from "../utils";

const { POST, DELETE } = methods;

const routes = {
  uploadExcel: `${window.env.REACT_APP_BASE_API_URL}/bo/import_articles_file`,
  upsertArticle: `${window.env.REACT_APP_BASE_API_URL}/bo/article`,
};

export default {
  remitterArticles: (code) =>
    authenticatedQuery(articlesQueries.remitterArticles(), {}, { code: code }),
  remitterArticleDelete: (ids) =>
    authenticatedService(DELETE, routes.upsertArticle, { ids }),
  remitterArticleCreate: (payload) =>
    authenticatedQuery(
      articlesMutations.createRemitterArticle(),
      {},
      { object: payload }
    ),
  remitterArticleUpsertRequest: (data) =>
    authenticatedService(POST, routes.upsertArticle, data),
  createArticleFile: (articleFilePayload) =>
    authenticatedMutation(
      articlesMutations.createArticleFile(),
      {},
      { articleFilePayload }
    ),
  deleteArticleFile: (id) =>
    authenticatedMutation(articlesMutations.deleteArticleFile(), {}, { id }),
  getArchivedArticles: (ids) =>
    authenticatedQuery(articlesQueries.archived, {}, { ids }),

  palletizingRequest: () => authenticatedQuery(articlesQueries.palletizing),
  rangeRequest: () => authenticatedQuery(articlesQueries.range),
  packagingTypeRequest: () =>
      authenticatedQuery(articlesQueries.packagingType),
  conversionUnitsRequest: () =>
    authenticatedQuery(articlesQueries.conversionUnits),
  uploadExcel: (file, remitterId) =>
    authenticatedService(POST, routes.uploadExcel, file, { id: remitterId }),
};
