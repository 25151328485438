import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import { types } from "./actions";
import { LoaderActions } from "../loader";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { FluxActions } from "./index";
import { FluxService } from "../../services";
import { get, isEqual } from "lodash";
import { push } from "connected-react-router";

const reformatFluxForInsert = (flux) => {
  const { id, steps, ...rest } = flux;
  return {
    ...rest,
    flux_steps: {
      data: steps.map(({ steps_partners, ...step }, orderStep) => ({
        required: step.required,
        flux_step_partners: {
          data: steps_partners.map(
            ({ partner, delay, required, open_days }, order) => ({
              partner_id: partner.id,
              delay,
              order,
              open_days,
              required,
            })
          ),
        },
        position: orderStep,
      })),
    },
  };
};

function* fluxsRequest({ where }) {
  yield put(LoaderActions.loading());
  const formattedWhere = { archived: { _eq: false } };
  if (where?.client_code && where?.client_code?.length > 0) {
    formattedWhere.client_code = { _eq: where?.client_code };
  }
  if (where?.name && where?.name?.length > 0) {
    formattedWhere.name = { _like: `%${where?.name}%` };
  }
  const [error, response] = yield call(FluxService.fluxs, {
    where: formattedWhere,
  });

  if (error) showError("fluxs_failed");
  else if (response) yield put(FluxActions.fluxsSuccess(response));
  yield put(LoaderActions.loaded());
}

function* fluxsDeleteRequest({ fluxIds }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    FluxService.fluxsDelete,
    fluxIds.map((f) => f.id)
  );
  if (error) {
    const err = get(error, ["graphQLErrors", 0, "extensions", "code"], "");
    showError(
      err === "constraint-violation" ? "flux_used" : "flux_delete_failed"
    );
  } else {
    showSuccess("fluxs_delete_success");
    yield put(FluxActions.fluxsDeleteSuccess(fluxIds));
    yield put(push("/flux"));
  }
  yield put(LoaderActions.loaded());

  /*for (const index in fluxIds) {
    const data = fluxIds[index]
    const [errorGet, responseGet] = yield call(FluxService.getHistoryFlux, {name: data.name, client: data.client})
    if (responseGet?.flux) {
      for (const indexFlux in responseGet.flux) {
        const data = responseGet.flux[indexFlux]
        const [error, response] = yield call(FluxService.fluxsDelete, [data.id])
        if (error) {
          if (indexFlux === "0") {
            const err = get(error, ["graphQLErrors", 0, "extensions", "code"], "")
            showError(err === "constraint-violation" ? "flux_used" : "flux_delete_failed", strings);
            yield put(LoaderActions.loaded());
            return
          }
          showSuccess("fluxs_delete_success", strings);
          yield put(FluxActions.fluxsDeleteSuccess(idsDeleted));
          yield put(FluxActions.addFluxSuccess({...data, count: get(data, ["steps_aggregate", "aggregate", "count"], 0)}));
          yield put(push("/flux"));
          yield put(LoaderActions.loaded());
          return
        } else {
          idsDeleted = [...idsDeleted, ...response.delete_flux.returning]
        }
      }
    }
  }*/
}

function* fluxRequest({ id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(FluxService.flux, id);
  if (error) showError("fluxs_failed");
  else if (response) yield put(FluxActions.fluxSuccess(response));
  yield put(LoaderActions.loaded());
}

function* fluxCreateRequest({ flux }) {
  yield put(LoaderActions.loading());

  const { steps, ...rest } = flux;
  const data = {
    ...rest,
    flux_steps: {
      data: steps.map(({ steps_partners, ...step }, orderStep) => ({
        required: step.required,
        flux_step_partners: {
          data: steps_partners.map(
            ({ partner, delay, required, open_days }, order) => ({
              partner_id: partner.id,
              delay,
              order,
              open_days,
              required,
            })
          ),
        },
        position: orderStep,
      })),
    },
  };

  const [error, response] = yield call(FluxService.fluxCreate, {
    fluxPayload: data,
  });
  if (error) showError("flux_create_failed");
  else if (response) {
    showSuccess("flux_create_success");
    yield put(push("/flux"));
  }
  yield put(LoaderActions.loaded());
}

function* fluxUpdateRequest({ flux }) {
  yield put(LoaderActions.loading());
  const [currentFlux] = yield select((state) => [state.flux.current]);
  const { id, steps } = flux;

  const dbSteps = currentFlux.steps || [];
  if (dbSteps.length) {
    if (!flux.free_notes?.length) flux.free_notes = null;
    const resetFlux =
      flux.name !== currentFlux.name ||
      flux.client_code !== currentFlux.client_code ||
      flux.free_notes !== currentFlux.free_notes ||
      dbSteps.length !== steps.length;
    const resetFluxSteps =
      resetFlux ||
      steps.some((step, stepIndex) => {
        const dbStep = dbSteps.find((st) => st.id === step.id);
        if (!dbStep) {
          return true;
        } else {
          const isDataEqual = isEqual(step, dbStep);
          const needUpdate =
            step.required !== dbStep.required || stepIndex !== dbStep.position;
          if (needUpdate) {
            return true;
          }
          const dbPartners = dbStep.steps_partners || [];
          const partners = step.steps_partners || [];
          if (dbPartners.length !== partners.length) return true;
          return partners.some((partner) => {
            const formatted = {
              partner_id: partner.id,
              delay: partner.delay,
              order: partner.order,
              open_days: partner.open_days,
              required: partner.required,
            };
            const dbPartner = dbPartners.find(({ id }) => id === partner.id);
            const dbFormatted = {
              partner_id: dbPartner.id,
              delay: dbPartner.delay,
              order: dbPartner.order,
              open_days: dbPartner.open_days,
              required: dbPartner.required,
            };
            return !isEqual(formatted, dbFormatted);
          });
        }
      });
    if (resetFluxSteps) {
      const data = reformatFluxForInsert(flux);
      const [error, response] = yield call(FluxService.fluxUpdate, {
        id: id,
        fluxPayload: data,
      });
      if (error) showError("flux_update_failed");
      else if (response) {
        showSuccess("flux_update_success");
        yield put(push("/flux"));
      }
    } else {
      showSuccess("flux_update_success");
      yield put(push("/flux"));
    }
  }
  yield put(LoaderActions.loaded());
}

function* clientRequest() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(FluxService.clients);
  if (error) showError("client_failed");
  else if (response)
    yield put(FluxActions.clientSuccess(response.sealogis_remitters));
  yield put(LoaderActions.loaded());
}

function* checkFluxRequest({ client, name }) {
  yield put(FluxActions.checkFluxLoading(true));
  const [error, response] = yield call(FluxService.checkFluxRequest, {
    client,
    name,
  });
  if (response) {
    yield put(FluxActions.checkFluxLoading(false));
    yield put(FluxActions.checkFluxSuccess(response.data.available));
  }
}

export default [
  takeLatest(types.FLUXS_REQUEST, fluxsRequest),
  takeLatest(types.FLUX_DELETE, fluxsDeleteRequest),
  takeLatest(types.FLUX_REQUEST, fluxRequest),
  takeLatest(types.FLUX_CREATE, fluxCreateRequest),
  takeLatest(types.FLUX_UPDATE, fluxUpdateRequest),
  takeLatest(types.CLIENT_REQUEST, clientRequest),
  debounce(500, types.CHECK_FLUX_REQUEST, checkFluxRequest),
];
