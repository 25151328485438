import PartnersCreate from "./PartnersCreate";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { PartnersActions } from "../../../redux/partners";
import { resolveId } from "../../../utils";
import { CountriesActions } from "../../../redux/countries";

const mapStateToProps = ({ router, locale, partners }) => ({
  id: resolveId(router),
  types: partners.types,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path, param) => dispatch(push(path, { param })),
  PartnerRequest: (id) => dispatch(PartnersActions.partnerRequest(id)),
  PartnersCreateRequest: (data) =>
    dispatch(PartnersActions.partnerCreateRequest(data)),
  PartnersUpdateRequest: (data) =>
    dispatch(PartnersActions.partnerUpdateRequest(data)),
  cleanCurrent: () => dispatch(PartnersActions.cleanCurrent()),
  typesRequest: () => dispatch(PartnersActions.typesRequest()),
  loadCountries: () => 
    dispatch(CountriesActions.countriesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersCreate);
